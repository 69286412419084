import * as React from "react";
import styles from "./Spinner.module.css";
import cx from "classnames";

interface Props {
  on?: boolean;
  color?: "white" | "blue";
  style?: React.CSSProperties;
  className?: string;
  text?: string;
  size?: "small" | "big";
  position?: "absolute" | "default";
  overrides?: { boxSpinner?: { className: string } };
}

const sizeStyles = {
  small: "",
  big: styles.big,
};

const colorStyles = {
  white: "",
  blue: styles.blue,
};

export const Spinner = ({
  on = true,
  color = "white",
  size = "small",
  style,
  className,
  text,
  position = "default",
  overrides = {},
}: Props) => {
  if (on) {
    return (
      <div
        style={style}
        className={cx(className, { [styles.positionAbsolute]: position === "absolute" })}
      >
        <div className={cx(styles.boxSpinner, overrides.boxSpinner?.className)}>
          <div className={cx(colorStyles[color], sizeStyles[size], styles.spinner)} />
          <span className={styles.text}>{text}</span>
        </div>
      </div>
    );
  }
  return null;
};
