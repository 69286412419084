import { useCallback, useEffect, useMemo, useState } from "react";
import arrowImg from "assets/images/171.svg";
import pinImg from "assets/images/32g.svg";
import styles from "./RouteList.module.css";
import cx from "classnames";
import { useRedux, useSelector } from "hooks";
import { User } from "api/users/models";
import { SuggestionSelect } from "components/common/suggestionSelect/SuggestionSelect";
import { RouteListItem } from "api/routes/models";
import { useRoutes } from "hooks/apiPrimitives";
import { useHistory, useParams } from "react-router";
import { useFilters } from "hooks/useFilters";
import { queryString } from "utilities";
import { Button } from "components/common";

export const RouteList = () => {
  const isOpen = useSelector(state => state.ui.isRouteListOnCreatorOpen);
  const [dispatch, { ui }] = useRedux();
  const toggle = useCallback(() => dispatch(ui.toggleRouteListOnCreator()), [dispatch, ui]);

  const [allRoutes, setAllRoutes] = useState<RouteListItem[]>([]);

  const { filters, setFilter, setFilters } = useFilters<{ createdBy: number | null; page: string }>(
    {
      createdBy: null,
      page: "1",
    },
  );

  const search = useMemo(
    () =>
      queryString.stringify({
        isSettled: false,
        page: filters.page,
        createdBy: filters.createdBy || "",
      }),
    [filters],
  );

  const { data: routeList, pagination, isLoading: inProgress } = useRoutes(search);

  useEffect(() => {
    if (filters.page !== "1") {
      setAllRoutes(prev => [...prev, ...routeList]);
    } else {
      setAllRoutes(routeList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeList]);

  const fetchMore = () => {
    if (pagination.next) {
      setFilter("page", String(pagination.next));
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={cx("d-flex align-items-center", styles.toggleWrapper)}>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <span className={cx("mr-1 text-color-white", styles.number)}>{pagination.count}</span>
            <span className={styles.text}>
              <img src={pinImg} className={styles.img} alt="" />
            </span>
          </div>
        </div>
        <div className={styles.border}></div>
        <div className={styles.toggleIcon} onClick={toggle}>
          <div className={styles.iconContainer}>
            <div className={styles.first}></div>
            <div className={styles.middle}></div>
            <div className={styles.last}></div>
          </div>
          <span className={cx(styles.text, "ml-2 fs-14")}>
            {isOpen ? "Ukryj listę tras" : "Pokaż listę tras"}
          </span>
        </div>
      </div>
      {isOpen && (
        <div>
          <UserSelect
            onChange={createdBy => setFilters({ createdBy, page: "1" })}
            selectedId={filters.createdBy}
          />
          <div className={styles.routeListWrapper}>
            {allRoutes.map(el => (
              <Group route={el} key={el.id} />
            ))}
            <div className="d-flex mt-4 mb-3">
              {pagination.next && (
                <Button disabled={inProgress} kind="primary" onClick={fetchMore}>
                  Wczytaj więcej
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

/**
 * UTILS
 */

function getUserName(
  user: { name: string; id: number },
  myself: Pick<User, "firstName" | "lastName" | "id">,
) {
  const selectedUserIsMyself = user.id === myself.id;
  if (selectedUserIsMyself) {
    return `${myself.firstName} ${myself.lastName}`;
  }
  return user.name;
}

/**
 * COMPONENT
 */

interface Props {
  onChange?: (user: number | null) => void;
  selectedId: number | null;
}

const UserSelect = ({ onChange, selectedId }: Props) => {
  const { users, me } = useSelector(state => {
    const me = state.auth.user!;
    return {
      users: state.partials.users.filter(el => el.id !== me.id && el.isDeleted === false),
      me,
    };
  });

  const mappedUsers = useMemo(() => {
    return [
      { name: "Wszystkie trasy", id: 0, avatar: me.avatar, firstName: "", lastName: "" },
      { name: "Tylko moje trasy", id: me.id, avatar: me.avatar, firstName: "", lastName: "" },
    ].concat(
      users.map(user => ({
        id: user.id,
        avatar: user.avatar || "",
        name: `${user.firstName} ${user.lastName}`,
        firstName: user.firstName,
        lastName: user.lastName,
      })),
    );
  }, [users, me]);

  return (
    <SuggestionSelect
      items={mappedUsers}
      theme="dark"
      onChange={user => {
        if (user?.id === 0) {
          onChange?.(null);
        }
        onChange?.(user?.id || null);
      }}
      selectedItem={selectedId}
      width={300}
      itemToDisplay={(item, selected) => (
        <div
          className="d-flex align-items-center py-2"
          style={selected ? { color: "#ccc" } : undefined}
        >
          <div>{item.name}</div>
        </div>
      )}
      itemToDisplaySelected={item => (
        <div className="d-flex align-items-center">
          <span className={styles.userName}>
            {item ? getUserName(item, me) : "Wszystkie trasy"}
          </span>
        </div>
      )}
      overrides={{
        toggleButton: {
          style: { height: 44, width: "93.5%", margin: "5px 0", border: "1px solid #ccc" },
        },
      }}
    />
  );
};

function Group({ route }: { route: RouteListItem }) {
  const history = useHistory();
  const params = useParams<{ routeId: string }>();

  return (
    <div
      className={cx(styles.routeWrapper, {
        [styles.currentRoute]: route.id === Number(params.routeId),
      })}
      onClick={() => {
        history.push(`/routes/creator/${route.id}`);
      }}
    >
      <div className={styles.routeLabel} style={{ background: route.color }}>
        <div className="fs-14">{route.signature}</div>
        <div className={cx("fs-10 wrap", styles.routeName)}>{route.name}</div>
      </div>
      <img src={arrowImg} className={styles.img} alt="" />
    </div>
  );
}
