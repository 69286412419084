import React, { useRef, useState } from "react";
import { PopoverMenu } from "../../utils/Popover";
import styles from "./SuggestionSelect.module.css";
import { useCombobox, UseComboboxStateChangeOptions, DownshiftState } from "downshift";
import cx from "classnames";
import { assertIsDefined } from "../../../utilities/assertIsDefined";
import { noop } from "../../../utilities/noop";
import { ReactNode } from "react";
import { Checkbox } from "components/utils";

interface Props<T, U extends keyof T, M> {
  placeholder?: string;
  inputPlaceholder?: string;
  items: T[];
  defaultSuggestedItems?: T[];
  onChange: (item: T | null, value: string | null) => void;
  width?: number;
  minWidth?: number;
  itemToDisplay?: (item: T, isSelected: boolean) => React.ReactNode;
  itemToDisplaySelected?: (item: T | null) => React.ReactNode;
  selectBy?: U;
  searchEnabled?: boolean;
  theme?: "light" | "dark";
  multiple?: M;
  overrides?: {
    wrapper?: { className?: string };
    list?: { className?: string };
    toggleButton?: { style?: React.CSSProperties };
  };
  editable?: boolean;
  badgeToDisplay?: (item: T) => ReactNode;
  disabled?: boolean;
}
type PropsSingle<T, U extends keyof T, M> = Props<T, U, M> & {
  selectedItem: T[U] | null;
  selectedItems?: undefined;
};
type PropsMultiple<T, U extends keyof T, M> = Props<T, U, M> & {
  selectedItems: T[U][];
  selectedItem?: undefined;
};

const createStateReducer = (multiple: boolean) =>
  function stateReducer(
    state: DownshiftState<string>,
    actionAndChanges: UseComboboxStateChangeOptions<string>,
  ) {
    const { type, changes } = actionAndChanges;
    // this prevents the menu from being closed when the user selects an item with 'Enter' or mouse
    switch (type) {
      // avoid clear after ESC click
      case useCombobox.stateChangeTypes.InputKeyDownEscape:
        return {
          ...changes,
          selectedItem: state.selectedItem,
          inputValue: state.inputValue || "",
        };
      // clear input value on change
      case useCombobox.stateChangeTypes.InputKeyDownEnter:
      case useCombobox.stateChangeTypes.ItemClick:
        return {
          ...changes, // default Downshift new state changes on item selection.
          inputValue: multiple ? state.inputValue || "" : "",
          isOpen: multiple ? state.isOpen : changes.isOpen,
        };
      case useCombobox.stateChangeTypes.ToggleButtonClick:
        return {
          ...changes,
          inputValue: "",
          highlightedIndex: undefined,
        };
      case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
        return {
          ...changes,
          inputValue: multiple ? state.inputValue || "" : "",
          isOpen: multiple ? state.isOpen : changes.isOpen,
        };

      default:
        return changes; // otherwise business as usual.
    }
  };

export function SuggestionSelect<
  T extends { id: number | string; name: string },
  U extends keyof T,
  M extends boolean = false
>({
  placeholder = "Choose an element",
  inputPlaceholder = "Szukaj...",
  onChange,
  multiple = false,
  items,
  width: fixedWidth,
  minWidth = 0,
  // initialItem: outerInitialItem,
  selectedItem: outerSelectedItem,
  selectedItems,
  selectBy,
  itemToDisplay,
  itemToDisplaySelected,
  searchEnabled = true,
  theme = "light",
  badgeToDisplay,
  editable = true,
  overrides = {},
  disabled = false,
  defaultSuggestedItems = [],
}: M extends true ? PropsMultiple<T, U, M> : PropsSingle<T, U, M>) {
  const [suggestedItems, setSuggestedItems] = useState<T[]>(defaultSuggestedItems);
  const {
    isOpen,
    inputValue,
    setInputValue,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    isOpen: disabled ? false : undefined,
    stateReducer: createStateReducer(multiple),
    selectedItem: items.find(el => el[selectBy || "id"] === outerSelectedItem)?.name || null,
    // initialSelectedItem: outerInitialItem,
    initialInputValue: "",
    items: items.map(el => el.name),
    onInputValueChange: ({ inputValue }) => {
      if (!inputValue) {
        setSuggestedItems(defaultSuggestedItems);
      } else {
        setSuggestedItems(
          items.filter(item => item.name.toLowerCase().startsWith(inputValue!.toLowerCase())),
        );
      }
    },
    onSelectedItemChange: downshift => {
      const item = items.find(el => el.name === downshift.selectedItem);
      onChange(item || null, downshift.selectedItem || null);
    },
  });

  const ref = useRef<HTMLDivElement | null>(null);
  const listWidth = fixedWidth || ref.current?.offsetWidth;

  function findItemByName(name: string) {
    const item = items.find(item => item.name === name);
    assertIsDefined(item, "item in select");
    return item;
  }

  function handleItemToDisplay(item: string) {
    const fullItem = findItemByName(item);
    assertIsDefined(fullItem, "fullItem in select");
    assertIsDefined(itemToDisplay, "itemToDisplay in select");
    if (multiple && selectedItems) {
      return itemToDisplay(
        fullItem,
        selectedItems.some(el => el === fullItem[selectBy || "id"]),
      );
    } else {
      return itemToDisplay(fullItem, selectedItem === fullItem[selectBy || "id"]);
    }
  }
  return (
    <div
      style={{ width: fixedWidth }}
      {...getComboboxProps({
        className: cx(styles[theme], overrides.wrapper?.className, { [styles.disabled]: disabled }),
        ref: ref,
      })}
    >
      {/* Hacky workaround to avoid console warnings */}
      <div className={styles.fakeInputContainer}>
        <input
          {...getInputProps({
            className: styles.fakeInput,
            autoFocus: false,
            onBlurCapture: e => {
              e.stopPropagation();
              e.preventDefault();
            },
          })}
        />
      </div>
      {/* Hacky workaround to avoid console warnings */}
      <div ref={getMenuProps().ref} />
      <div className="position-relative">
        <PopoverMenu
          isOpen={isOpen}
          placement="bottom-start"
          overflowContainer
          triggerOffset={0}
          target={btnProps => (
            <button
              type="button"
              {...getToggleButtonProps({
                ...btnProps,
                style: {
                  minWidth,
                  ...overrides.toggleButton?.style,
                },
              })}
              aria-label={"toggle menu"}
              className={cx(
                styles.btn,
                { [styles.disabled]: disabled },
                { [styles.btnEditable]: editable },
              )}
            >
              <span>
                {itemToDisplaySelected ? (
                  itemToDisplaySelected(selectedItem ? findItemByName(selectedItem) : null)
                ) : (
                  <div>
                    {selectedItem || <span className={styles.placeholder}>{placeholder}</span>}
                  </div>
                )}
              </span>
              {/* <img src={caretImg} alt="" className={styles.caret} /> */}
            </button>
          )}
        >
          <ul
            {...getMenuProps({
              className: cx(
                styles.list,
                {
                  [styles.unsearchableList]: searchEnabled === false,
                  [styles.open]: isOpen,
                },
                overrides.list?.className,
              ),
              style: {
                width: listWidth ? (listWidth < minWidth ? minWidth : listWidth) : undefined,
              },
            })}
          >
            {isOpen && (
              <>
                {searchEnabled && (
                  <li className={styles.searchLi}>
                    <input
                      value={inputValue}
                      className={styles.input}
                      onChange={e => setInputValue(e.target.value)}
                      placeholder={inputPlaceholder}
                      autoFocus
                    />
                  </li>
                )}
                {Boolean(suggestedItems.length) && (
                  <li className="fs-12 text-color-grey p-2">Sugestie</li>
                )}
                {suggestedItems.map((item, index) => {
                  const realIndex = items.findIndex(el => el.id === item.id);
                  return (
                    <li
                      style={highlightedIndex === realIndex ? { backgroundColor: "#efeeec" } : {}}
                      key={`${item}-${index}`}
                      {...getItemProps({
                        item: item.name,
                        index: realIndex,
                      })}
                    >
                      {itemToDisplay ? (
                        handleItemToDisplay(item.name)
                      ) : multiple && selectedItems ? (
                        <div className="d-flex">
                          <Checkbox
                            className="pe-none"
                            checked={selectedItems.some(el => el === item[selectBy || "id"])}
                            onChange={noop}
                            label=""
                            name=""
                          />
                          {item.name}
                        </div>
                      ) : (
                        item.name
                      )}
                    </li>
                  );
                })}
                {Boolean(suggestedItems.length) && (
                  <li className="fs-12 text-color-grey p-2">Wszyscy inni</li>
                )}
                {items
                  .filter(el => !suggestedItems.some(suggestedItem => el.id === suggestedItem.id))
                  .map((item, index) => {
                    const realIndex = items.findIndex(el => el.id === item.id);
                    return (
                      <li
                        style={highlightedIndex === realIndex ? { backgroundColor: "#efeeec" } : {}}
                        key={`${item}-${index}`}
                        {...getItemProps({
                          item: item.name,
                          index: realIndex,
                        })}
                      >
                        {itemToDisplay ? (
                          handleItemToDisplay(item.name)
                        ) : multiple && selectedItems ? (
                          <div className="d-flex">
                            <Checkbox
                              className="pe-none"
                              checked={selectedItems.some(el => el === item[selectBy || "id"])}
                              onChange={noop}
                              label=""
                              name=""
                            />
                            {item.name}
                          </div>
                        ) : (
                          item.name
                        )}
                      </li>
                    );
                  })}
              </>
            )}
          </ul>
        </PopoverMenu>
      </div>
    </div>
  );
}
