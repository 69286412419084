import { Label } from "components/utils";
import { dateFns } from "utilities";
import cx from "classnames";
import styles from "./BoardCard.module.css";
import { OpacityCover } from "../disabledCover";
import { getLabelColor } from "./utils";
import { Assign } from "utility-types";
import { ListOrder, Order } from "../../../api/orders/models";
import calendarImg from "assets/images/4.svg";
import addressImg from "assets/images/42g.svg";
import commentImg from "assets/images/45g.svg";
import optionsImg from "assets/images/41.svg";
import editImg from "assets/images/1.svg";
import { Button } from "components/common";
import { UserAvatar } from "components/utils/userAvatar";

interface RowProps {
  className: string;
  onClick: (arg: any) => void;
}

const CheckMark = ({ checked }: { checked: boolean }) => (
  <div className="d-flex align-items-center">
    <label className={styles.readyMark}>
      <input type="checkbox" disabled checked={checked} />
      <span className={styles.readyMarkCheckmark} />
    </label>
  </div>
);

const Avatar = ({ avatar, id }: { avatar: string | undefined; id?: number }) => (
  <div className={styles.assignee}>
    <UserAvatar id={id} avatarSrc={avatar} />
  </div>
);

const CellLabel = ({
  color,
}: {
  color:
    | "blue"
    | "orange"
    | "yellow-orange"
    | "red-orange"
    | "yellow"
    | "pink"
    | "grey"
    | "red"
    | "dark-red"
    | "purple"
    | "blueviolet"
    | "green"
    | "darkblue"
    | "darkcyan"
    | "midblue"
    | "cadetblue"
    | "burlywood"
    | "transparent"
    | "custom";
}) => (
  <div className={styles.timeCounter}>
    <Label className={styles.leftDaysLabel} color={color} />
  </div>
);

const LeftDaysLabel = ({ leftDays }: { leftDays: number }) => (
  <CellLabel color={getLabelColor(leftDays)} />
);

const Address = ({
  firstName,
  lastName,
  street,
  postCode,
  city,
}: {
  firstName: string;
  lastName: string;
  street: string;
  postCode: string;
  city: string;
}) => (
  <div className="d-flex align-items-start flex-wrap">
    <div className="mr-2">
      <img src={addressImg} alt="" />
    </div>
    <div className={cx("d-flex align-items-start flex-wrap", styles.orderDelivery)}>
      <div className={cx("mr-2", styles.client)}>
        {firstName} {lastName}
      </div>
      <div className={styles.address}>
        ul. {street}, {postCode} {city}
      </div>
    </div>
  </div>
);

const PrimaryHeading = ({
  order,
}: {
  order: { signature: string; salesAccount: Order["salesAccount"] };
}) => (
  <div className={cx("d-flex", styles.header)}>
    <div className="d-flex align-items-center">
      <div className={cx("mr-2", styles.signature)}>{order.signature}</div>
      <div
        className={cx(styles.label)}
        style={{
          backgroundColor: order.salesAccount.color,
        }}
      >
        <span style={{ color: order.salesAccount.textColor }}>{order.salesAccount.name}</span>
      </div>
    </div>
  </div>
);

export const BoardCard = ({
  onClick,
  className,
  order,
  isCovered,
}: Assign<RowProps, { isCovered: (id: number) => boolean; order: ListOrder }>) => {
  return (
    <div onClick={onClick} className={cx(className, styles.card)}>
      <div className={styles.orderDetails}>
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <CheckMark checked={order.isConfirmed} />
              <PrimaryHeading order={order} />
            </div>
            <div className={cx("d-flex align-items-center", styles.iconsHeader)}>
              <div className="d-flex align-items-center mr-2">
                <span className="mr-1">1</span>
                <img src={commentImg} alt="" />
              </div>
              <div>
                <img src={editImg} alt="" />
              </div>
            </div>
          </div>
          <Address
            firstName={order.client.firstName}
            lastName={order.client.lastName}
            city={order.deliveryAddress.city}
            postCode={order.deliveryAddress.postCode}
            street={order.deliveryAddress.street}
          />
          <div className="d-flex align-items-center position-relative">
            <LeftDaysLabel leftDays={order.leftDays} />
            <div className="mr-2">
              <img src={calendarImg} alt="" />
            </div>
            <div className={styles.orderDate}>
              {dateFns.format(new Date(order.created), "d MMM yyyy")}
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-between">
        <Avatar avatar={order.assignedTo?.avatar} id={order.assignedTo?.id} />

        {/* user select */}
        <div>
          <Button kind="secondary" size="round-s">
            <img src={optionsImg} alt="" />
          </Button>
          {/* option list */}
        </div>
      </div>
      <OpacityCover active={isCovered(order.id)} />
    </div>
  );
};
