import styles from "./DownloadFeedbackToastr.module.css";
import cx from "classnames";
import { ToastSubscription } from "./DownloadFeedbackController";
import pdfImg from "assets/images/101.svg";
import closeIcon from "assets/images/11.svg";
import { Button } from "components/common";

interface Props {
  type: ToastSubscription["type"];
  progress: ToastSubscription["progress"];
  clearHideTimeout: () => void;
  lazyUnsubscribe: () => void;
  close: () => void;
  className?: string;
  calculateProgress: boolean;
}

export const DownloadFeedbackToastr = ({
  type,
  progress,
  className = "",
  lazyUnsubscribe,
  clearHideTimeout,
  close,
  calculateProgress,
}: Props) => {
  const img = {
    pdf: pdfImg,
    xml: pdfImg,
  }[type];

  return (
    <div
      className={cx(styles.snackbar, styles.toast, styles.snackbarOpened, className)}
      onMouseEnter={clearHideTimeout}
      onMouseLeave={lazyUnsubscribe}
    >
      <div className="d-flex justify-content-end mt-2 mr-2">
        <Button kind="secondary" size="round-s" onClick={close} className={styles.resetBtn}>
          <img src={closeIcon} alt="reset" />
        </Button>
      </div>
      <div className="d-flex align-items-center mb-3 pl-2">
        <div className={styles.eventIcon}>
          <img src={img} alt="" />
        </div>
        {calculateProgress ? (
          <div className={styles.snackbarContent}>
            <div className={styles.progressBar}>
              <div className={styles.progressBarInner} style={{ width: `${progress}%` }} />
            </div>
            <div className={cx(styles.progressBarText, "w-100")}>
              <div className="mt-2">Pobieranie pliku: {progress}%</div>
            </div>
          </div>
        ) : (
          <div className={styles.snackbarContent}>Pobieranie pliku</div>
        )}
      </div>
    </div>
  );
};
