import { Button } from "components/common";
import { StateProxy } from "../StateProxy";
import styles from "./SearchField.module.css";
import closeIcon from "assets/images/11.svg";

interface Props {
  value: string;
  debounce?: number;
  onUpdate: (value: string) => void;
}

export function SearchField({ value, onUpdate, debounce = 200 }: Props) {
  return (
    <div className={styles.searchBoxField}>
      <StateProxy state={value} onChange={onUpdate} debounce={debounce}>
        {({ state, setState, setBlur, setFocus }) => (
          <>
            <input
              type="search"
              className="search-field"
              placeholder="Szukaj"
              value={state}
              onFocus={setFocus}
              onBlur={setBlur}
              onChange={e => setState(e.target.value)}
            />
            {state && (
              <Button
                kind="secondary"
                size="round-s"
                onClick={() => setState("")}
                className={styles.resetBtn}
              >
                <img src={closeIcon} alt="reset" />
              </Button>
            )}
          </>
        )}
      </StateProxy>
    </div>
  );
}
