import { FLAVOR } from "CONSTANTS";

// type PathFilters = { search?: string; query?: Record<string, string | number> };

// function formatFilters(filters: PathFilters = {}) {
//   const safeFilters = { search: filters.search || "", query: filters.query || {} };
//   const search = queryString.merge([safeFilters.search, queryString.stringify(safeFilters.query)]);
//   return search;
// }

export const routePaths = {
  orderList: (tab = "active", salesAccount: number | string = "all") => {
    return {
      main: `/orders/list/${tab}/${salesAccount}`,
      b2b: `/client/orders/list/${tab}/${salesAccount}`,
      manufacturing: `/orders/list/${tab}/${salesAccount}`,
    }[FLAVOR];
  },
  orderHistory: (uuid: string) => {
    return {
      main: `/orders/${uuid}/history`,
      b2b: `/client/orders/${uuid}/history`,
      manufacturing: `/orders/${uuid}/history`,
    }[FLAVOR];
  },
  driverRoutes: (driverId: number | string) => `/drivers/routes/${driverId}`,
  routeList: ({ tab = "active" }: { tab: string }) => {
    return `/routes/list/${tab}`;
  },
};
