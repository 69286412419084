import { useQuery, useRedux, useSelector } from "hooks";
import { AvailableViewKeys } from "ducks/ui";

interface Props {
  children: (args: {
    togglePanel: (id: number | string) => void;
    active: string;
    isActive: (id: number | string) => boolean;
    isHighlighted: (id: number | string) => boolean;
    close: () => void;
  }) => JSX.Element;
  /**
   * name is used to store last clicked element in redux store
   */
  name: AvailableViewKeys;
}

export const RightPanelHandler = ({ children, name }: Props) => {
  const [dispatch, { ui }] = useRedux();
  const highlighted = useSelector(store => store.ui.highlights[name]);
  const { highlight } = ui;
  const { query, updateQuery } = useQuery();
  const { panelId } = query;
  const isActive = (id: number | string) => String(id) === String(panelId);
  const isHighlighted = (id: number | string) => String(id) === String(highlighted);
  const togglePanel = (id: number | string) => {
    if (panelId) {
      if (String(id) === panelId) {
        updateQuery({ panelId: "" });
      } else {
        dispatch(highlight({ name, id: Number(id) }));
        updateQuery({ panelId: String(id) });
      }
    } else {
      dispatch(highlight({ name, id: Number(id) }));
      updateQuery({ panelId: String(id) });
    }
  };
  const close = () => updateQuery({ panelId: "" });
  return children({ togglePanel, active: panelId, isActive, isHighlighted, close });
};
