import cx from "classnames";
import localStyles from "./ManufacturingItem.module.css";

export const EmptyManufacturingItem = () => {
  return (
    <div className={cx(localStyles.item, localStyles.itemPlaceholder)}>
      <div className="d-flex align-items-center">
        <div className={cx(localStyles.itemSlot, localStyles.itemSlotSmall)} />
        <div>Przeciągnij i upuść indeks tutaj</div>
      </div>
      <div className="d-flex align-items-center">
        <div className={cx(localStyles.itemSlot, localStyles.itemSlotSmall)} />
        <div className={cx(localStyles.itemSlot, localStyles.itemSlotSmall)} />
        <div className={cx(localStyles.itemSlot, localStyles.itemSlotMedium)} />
      </div>
    </div>
  );
};
