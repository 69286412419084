import { Button } from "components/common";
import menuIcon from "assets/images/menu.svg";
import completeIcon from "assets/images/7.svg";
import completeIconWhite from "assets/images/7w.svg";
import downloadPdfIcon from "assets/images/downloadPdf.svg";
import settingsIcon from "assets/images/settings.svg";
import localStyles from "./Header.module.css";
import styles from "../../ProductionPlanDetails.module.css";
import cx from "classnames";
import { Link } from "react-router-dom";
import { ProductionPlan } from "api/new-production-plans/models";

interface Props {
  productionPlan: ProductionPlan;
}

export const Header = ({ productionPlan }: Props) => {
  return (
    <div className={localStyles.header}>
      <div>
        <Button
          kind="secondary"
          size="round-s"
          onClick={() => {}}
          className={cx(styles.button, styles.buttonRound, styles.buttonBackgroundTransparentWhite)}
        >
          <img alt="" src={menuIcon} />
        </Button>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <Link
          to="/new-production-plans/list"
          className={cx(localStyles.link, "Link text-color-grey mr-2")}
        >
          <span>Plany produkcji / </span>
        </Link>
        <div className="mr-2">
          <span> {productionPlan.signature}</span>
        </div>
        <div className="text-color-purple mr-2">
          <span> Tapicerowanie</span>
        </div>
        <Button
          kind="secondary-grey"
          className={cx(styles.button, styles.buttonBackgroundTransparentWhite, "ml-2")}
        >
          <span>Dodaj nazwę planu</span>
        </Button>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <Button
          kind="secondary"
          size="round-s"
          onClick={() => {}}
          className={cx(
            styles.button,
            styles.buttonRound,
            styles.buttonBackgroundTransparentWhite,
            "mr-2",
          )}
        >
          <img alt="" src={settingsIcon} />
        </Button>
        <Button
          kind="secondary"
          size="round-s"
          onClick={() => {}}
          className={cx(
            styles.button,
            styles.buttonRound,
            styles.buttonBackgroundTransparentWhite,
            "mr-2",
          )}
        >
          <img alt="" src={downloadPdfIcon} />
        </Button>
        {productionPlan.isConfirmed ? (
          <Button kind="secondary" className={cx(styles.button, localStyles.btnReady, "ml-2")}>
            <img alt="" src={completeIconWhite} />
            <span>Gotowy</span>
          </Button>
        ) : (
          <Button
            kind="secondary-grey"
            className={cx(styles.button, styles.buttonBackgroundTransparentWhite, "ml-2")}
          >
            <img alt="" src={completeIcon} />
            <span>Oznacz jako gotowy</span>
          </Button>
        )}
      </div>
    </div>
  );
};
