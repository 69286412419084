import { createContext, useState, useCallback, useMemo, useContext } from "react";
import * as React from "react";
import { Modal } from "components/utils";
import { Button } from "../buttonLegacy";

export const context = createContext<any>({});

export interface Options {
  callback: (...args: any) => void;
  text?: React.ReactNode;
  confirmText?: string;
}

type State = {
  isOpen: boolean;
  options: Options | null;
};

export const ConfirmModalController = ({ children }: any) => {
  const [state, setState] = useState<State>({
    isOpen: false,
    options: null,
  });
  const close = useCallback(() => setState(s => ({ isOpen: false, options: s.options })), []);
  const open = useCallback((options: Options) => setState({ options, isOpen: true }), []);
  const value = useMemo(() => ({ ...state, close, open }), [state, open, close]);
  return (
    <context.Provider value={value}>
      {children}
      <Modal isOpen={state.isOpen} close={close} size="auto">
        {state.options ? (
          <div>
            {state.options.text || "Czy na pewno?"}
            <div className="d-flex pt-2">
              <Button
                kind="primary"
                className="mr-1 ml-auto"
                onClick={() => {
                  state.options!.callback();
                  close();
                }}
              >
                {state.options.confirmText || "Potwierdź"}
              </Button>
              <Button kind="secondary-stroke" onClick={close}>
                Zamknij
              </Button>
            </div>
          </div>
        ) : (
          <div />
        )}
      </Modal>
    </context.Provider>
  );
};

export const useConfirmModal = () => {
  const modal = useContext(context);
  return {
    open: ({ text, callback, confirmText }: Options) => {
      modal.open({ callback, text, confirmText });
    },
    close: modal.close,
  };
};
