import cx from "classnames";
import { Button as AddComment } from "components/common/button";
import { Button } from "components/common";
import { StatusHandler } from "components/utils";
import { useUnassignOrder } from "../hooks/useUnassignOrder";
import { Route, RouteOrdersComments } from "api/routes/models";
import { RouteCommentForm } from "pages/routes/routeCommentForm/RouteCommentForm";
import { useToggle } from "hooks/hooks";
import { Comments } from "./OrderCommentsDrawer";
import plusIcon from "assets/images/20p.svg";
import { useRouteViewState } from "../routeCreatorState";

interface Props {
  order: RouteOrdersComments["orders"][number];
  route: Route;
  isLoading: boolean;
}

export const OrderComment = ({ order, route, isLoading }: Props) => {
  const { unassignOrder } = useUnassignOrder(route);
  const { isOpen, open, close } = useToggle(false);
  const actions = useRouteViewState("slave", store => store.actions);

  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <div className="fs-14">
            {order.delivery.street}, {order.delivery.city}
          </div>
          {(!order.customer || order.customer.hasDropShipping) && (
            <div className="fs-14">
              {order.delivery.firstName}, {order.delivery.lastName}
            </div>
          )}
          {order.customer && (
            <div
              className="label mr-1"
              style={{
                backgroundColor: order.customer.color,
              }}
            >
              <span
                style={{
                  color: order.customer.textColor,
                }}
              >
                {order.customer.name}
              </span>
            </div>
          )}
          <div className="fs-14 mb-3">{order.signature}</div>
          <div className="fs-12">Uwagi od klienta</div>
          <div
            className={cx(
              "fs-16 mb-3",
              order.messageToSeller ? "text-color-coral" : "text-color-grey",
            )}
          >
            {order.messageToSeller || "Brak uwag"}
          </div>
          <Comments comments={order.comments} orderId={order.id} routeId={route.id} />
        </div>
        <div>
          <StatusHandler>
            {helpers => (
              <Button
                kind="secondary-stroke"
                size="small"
                disabled={helpers.isFetching || isLoading}
                onClick={e => {
                  e.stopPropagation();
                  actions.openLoader("Trwa odpinanie punktu");
                  unassignOrder(order.id, "order", helpers);
                }}
              >
                Odepnij
              </Button>
            )}
          </StatusHandler>
        </div>
      </div>
      {isOpen ? (
        <div className="d-flex justify-content-between">
          <div
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <RouteCommentForm orderId={order.id} routeId={route.id} close={close} />
          </div>
        </div>
      ) : (
        <div className="mb-1 mt-1">
          <AddComment
            kind="secondary-grey"
            onClick={e => {
              e.stopPropagation();
              open();
            }}
          >
            <img src={plusIcon} alt="" className="mr-1 mb-1" />
            Dodaj nowy
          </AddComment>
        </div>
      )}
    </>
  );
};
