import { ProductionPlan, ShelfItem } from "api/new-production-plans/models";
import styles from "../../../ProductionPlanDetails.module.css";
import localStyles from "./ShelfContent.module.css";
import cx from "classnames";
import printPdfIcon from "assets/images/162.svg";
import { Button } from "components/common";
import { ErrorType } from "hooks/createApiQuery";
import { CommonError, Spinner } from "components/utils";
import { ShelfItemDetails } from "./subcomponents/shelfItemDetails/ShelfItemDetails";

interface Props {
  error: ErrorType | null;
  isLoading: boolean;
  productionPlan: ProductionPlan;
  search: string;
  shelfHeight: number;
  shelfItems: ShelfItem[];
}

export const ShelfContent = ({
  error,
  isLoading,
  productionPlan,
  search,
  shelfHeight,
  shelfItems,
}: Props) => {
  if (error) {
    return <CommonError status={error._httpStatus_} />;
  }

  if (isLoading) {
    return (
      <div className={localStyles.shelfContent} style={{ height: `${shelfHeight}px` }}>
        <div className="d-flex align-items-center justify-content-center mt-5">
          <Spinner color="blue" size="big" on={isLoading} text="trwa wczytywanie" />
        </div>
      </div>
    );
  }

  return (
    <div className={localStyles.shelfContent} style={{ height: `${shelfHeight}px` }}>
      <div
        className={cx(styles.scrollXWrapper, "px-3 pb-0", {
          [localStyles.contentHeight]:
            Object.keys(productionPlan.shelfFilters).length !== 0 || shelfItems,
        })}
      >
        <div className="d-flex flex-nowrap">
          {shelfItems.map((shelfItem, index) => (
            <div key={index} className={cx(localStyles.shelfItem, "pr-2 py-2 mr-2")}>
              <div className={cx(localStyles.shelfItemHeader, "justify-content-between")}>
                <div>
                  <img alt="Pobierz PDF" src={printPdfIcon} />
                  <div>{shelfItem.header}</div>
                </div>
                <Button kind="secondary-grey">
                  <span>Rozłóż dzień automatycznie</span>
                </Button>
              </div>
              <ShelfItemDetails
                filterValue={shelfItem.filterValue}
                header={shelfItem.header}
                origin={shelfItem.origin}
                productionPlan={productionPlan}
                search={search}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
