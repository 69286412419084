import { useState, useCallback, useMemo, useRef, useEffect } from "react";

export interface StatusHandlerHelpers {
  isFetching: boolean;
  setFetching: (arg: boolean) => void;
  clear: () => void;
  setErrors: (arg: { [key: string]: string }) => void;
  errors: { [key: string]: string };
  startFetching: () => void;
  stopFetching: (err?: Record<string, string> | null) => void;
  handle: (arg: Promise<Record<string, string> | void>) => Promise<any>;
}
interface State {
  errors: Record<string, string>;
  fetching: boolean;
}

export function useStatusHandler(
  resetKey: string | number | null | undefined,
  type: "common" | "queryFetch" = "common",
) {
  const isMounted = useRef(true);
  const initialMount = useRef(true);
  const [state, setState] = useState<State>({ errors: {}, fetching: false });
  const clear = () => {
    if (!isMounted.current) return;
    setErrors({});
  };
  const setErrors = useCallback(errors => setState(s => ({ ...s, errors })), []);
  const setFetching = useCallback(status => setState(s => ({ ...s, fetching: status })), []);

  function startFetching() {
    if (!isMounted.current) return;
    setState(s => ({ ...s, fetching: true, errors: {} }));
  }
  const stopFetching: StatusHandlerHelpers["stopFetching"] = err => {
    if (!isMounted.current) return;
    setState(s => ({
      ...s,
      fetching: false,
      errors: err ?? {},
    }));
  };
  const memoStartFetching = useMemo(() => startFetching, []);
  const memoStopFetching = useMemo(() => stopFetching, []);
  const memoClear = useCallback(clear, [setErrors]);

  const handleStatus: StatusHandlerHelpers["handle"] = useCallback(
    async arg => {
      startFetching();
      if (type === "queryFetch") {
        try {
          await arg;
        } catch (err) {
          stopFetching(err || undefined);
        }
      } else {
        const error = await arg;
        stopFetching(error || undefined);
      }
    },
    [type],
  );

  useEffect(() => {
    if (initialMount.current) return;
    memoStopFetching();
  }, [resetKey, memoStopFetching]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    initialMount.current = false;
  }, []);

  return useMemo(
    () => ({
      isFetching: state.fetching,
      setFetching,
      clear: memoClear,
      setErrors,
      errors: state.errors,
      startFetching: memoStartFetching,
      stopFetching: memoStopFetching,
      handle: handleStatus,
    }),
    [
      memoClear,
      memoStartFetching,
      memoStopFetching,
      setErrors,
      setFetching,
      state.errors,
      state.fetching,
      handleStatus,
    ],
  );
}

export const StatusHandler = ({
  children,
  resetKey,
  type = "common",
}: {
  children: (arg: StatusHandlerHelpers) => any;
  resetKey?: number | string | null;
  type?: "common" | "queryFetch";
}) => {
  const statusBag = useStatusHandler(resetKey, type);

  return children(statusBag);
};
