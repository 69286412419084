import {
  getOrderGroup,
  getOrderGroupQuery,
  getOrderGroups,
  getOrderGroupsQuery,
  getSettledOrderGroup,
  getSettledOrderGroupQuery,
} from "api/order-groups/calls";
import { createApiQuery } from "hooks/createApiQuery";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { createPrimitiveHook, createPrimitivePaginatedHook } from "hooks/createPrimitiveHook";

export const useOrderGroup = createPrimitiveHook(getOrderGroup);
export const useOrderGroups = createPrimitivePaginatedHook(getOrderGroups);
export const useSettledOrderGroup = createPrimitiveHook(getSettledOrderGroup);

export const useOrderGroupsQuery = createPaginatedApiQuery(getOrderGroupsQuery);
export const useOrderGroupQuery = createApiQuery(getOrderGroupQuery);
export const useSettledOrderGroupQuery = createApiQuery(getSettledOrderGroupQuery);
