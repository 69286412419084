import { OrderForRoute } from "api/orders/models";
import { Route } from "api/routes/models";
import crossImg from "assets/images/11.svg";
import sendSmsIcon from "assets/images/174.svg";
import deliveryConfirmedIcon from "assets/images/175.svg";
import availabilityStatusIcon from "assets/images/176.svg";
import carImg from "assets/images/24.svg";
import pinImg from "assets/images/32g.svg";
import furnitureImg from "assets/images/33.svg";
import pin2Img from "assets/images/42.svg";
import commentImg from "assets/images/45.svg";
import timeImg from "assets/images/46.svg";
import checkImg from "assets/images/7c.svg";
import cx from "classnames";
import { Button } from "components/common";
import { AvailabilityDetailsModal } from "components/common/availabilityDetailsModal";
import { getLabelColor } from "components/common/listRow/utils";
import { CommonError, Label, Spinner, StatusHandler } from "components/utils";
import { useSelector, useStateModal } from "hooks";
import { useOrderForRoute } from "hooks/apiPrimitives";
import { Link } from "react-router-dom";
import { dateFns, getAnyErrorKey, pluralize } from "utilities";
import { getMarkerIcon } from "utilities/getMarkerIcon";
import { getOrderRedirectUrl } from "utilities/getOrderRedirectUrl";
import { useAssignItem } from "../../hooks/useAssignItem";
import { useUnassignOrder } from "../../hooks/useUnassignOrder";
import { useRouteViewState } from "../../routeCreatorState";
import styles from "./InfoWindowContent.module.css";
import { getAwaitingLabelColor } from "./utils";

interface Props {
  id: string;
  route: Route;
}

export const InfoWindowContent = ({ id, route }: Props) => {
  const { data: order, isLoading: inProgress, error } = useOrderForRoute(id);
  const hasSmsService = useSelector(store => store.partials.configuration.hasSmsService);
  const isPinned = route?.orders.some(el => el.id === Number(id));
  const modal = useStateModal();
  const actions = useRouteViewState("slave", state => state.actions);
  const isLoading = useRouteViewState("slave", state => state.isLoading);
  const { unassignOrder } = useUnassignOrder(route);
  const { assignOrder } = useAssignItem(route);

  if (error) {
    return (
      <div className={styles["map-point-cloud"]}>
        <div className="d-flex align-items-center justify-content-center flex-1 h-100">
          <CommonError text={getAnyErrorKey(error)} />
        </div>
      </div>
    );
  }

  if (inProgress) {
    return (
      <div className={styles["map-point-cloud"]}>
        <div className="d-flex align-items-center justify-content-center flex-1 h-100 pt-5">
          <Spinner color="blue" size="big" />
        </div>
      </div>
    );
  }

  if (!order) return null;

  return (
    <div className={styles["map-point-cloud"]}>
      <div className="mb-3">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <div className={styles["point-image"]}>
              <img src={getMarkerIcon(order)} alt="" />
            </div>
            <div>
              {isAwaitingForWarehouseDelivery(order) &&
              order.warehouseDeliveryDetails.daysLeftToDelivery !== null ? (
                <Label
                  color={getAwaitingLabelColor(order.warehouseDeliveryDetails.daysLeftToDelivery)}
                  className="mr-3 ml-2"
                >
                  {order.warehouseDeliveryDetails.daysLeftToDelivery === 0 ? (
                    <span>dzisiaj</span>
                  ) : (
                    <span>
                      {order.warehouseDeliveryDetails.daysLeftToDelivery}{" "}
                      {pluralize.pl(order.warehouseDeliveryDetails.daysLeftToDelivery, {
                        singular: "dzień",
                        plural: "dni",
                        other: "dni",
                      })}
                    </span>
                  )}
                </Label>
              ) : (
                <Label
                  color={getLabelColor(
                    order.type === "STANDARD" ? order.leftDays : order.numberOfDaysFromCreatedDate,
                    order.type,
                  )}
                  className="mr-3 ml-2"
                >
                  <span>
                    {order.type === "STANDARD" ? order.leftDays : order.numberOfDaysFromCreatedDate}{" "}
                    {pluralize.pl(
                      order.type === "STANDARD"
                        ? order.leftDays
                        : order.numberOfDaysFromCreatedDate,
                      {
                        singular: "dzień",
                        plural: "dni",
                        other: "dni",
                      },
                    )}
                  </span>
                </Label>
              )}
            </div>
          </div>
          <small className={styles.createdDate}>
            zam. dn.: {dateFns.format(new Date(order.created), "dd.MM.yyyy")}
          </small>
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              {isPinned ? (
                <StatusHandler>
                  {helpers => (
                    <Button
                      kind="secondary"
                      size="small"
                      className="pl-2 pr-2 pt-1 pb-1 ml-2"
                      disabled={!route || helpers.isFetching || isLoading}
                      onClick={() => {
                        actions.openLoader("Trwa odpinanie punktu");
                        unassignOrder(order.id, "order", helpers);
                      }}
                    >
                      <img className="mr-2" src={pinImg} alt="" />
                      <span>Odepnij</span>
                    </Button>
                  )}
                </StatusHandler>
              ) : (
                <StatusHandler>
                  {helpers => (
                    <Button
                      kind="secondary"
                      size="small"
                      className="pl-2 pr-2 pt-1 pb-1 ml-2"
                      disabled={!route || helpers.isFetching || isLoading}
                      onClick={() => {
                        actions.openLoader("Trwa przypinanie punktu");
                        assignOrder(
                          {
                            id: order.id,
                            point: order.delivery.point,
                            type: "order",
                            address: null,
                            warehouseDeliveryDetails: order.warehouseDeliveryDetails,
                          },
                          helpers,
                        );
                      }}
                    >
                      <img className="mr-2" src={pinImg} alt="" />
                      <span>Przypnij</span>
                    </Button>
                  )}
                </StatusHandler>
              )}
            </div>
            {hasSmsService && (
              <div className="d-flex align-items-center justify-content-end ml-2">
                <span
                  className={cx(
                    styles.smsNotification,
                    "mr-1",
                    order.hasSmsSent ? "bgPurple" : "bgGrey",
                  )}
                >
                  <img alt="" src={sendSmsIcon} />
                </span>
                <span
                  className={cx(
                    styles.smsNotification,
                    order.isDeliveryDateConfirm ? "bgBlue" : "bgGrey",
                  )}
                >
                  <img alt="" src={deliveryConfirmedIcon} />
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <hr />
      <div className="d-flex flex-wrap m-0 mt-2 row">
        <div className="col-6 pl-0">
          <div className={cx(styles["map-cloud_data-group"], "mb-3")}>
            <div className={cx(styles["map-cloud_group-label"])}>
              <img src={pin2Img} alt="" />
              <span>
                {!order.customer
                  ? `${order.delivery.firstName} ${order.delivery.lastName}`
                  : order.customer.hasDropShipping
                  ? `${order.delivery.firstName} ${order.delivery.lastName} (${order.customer.name})`
                  : order.customer.name}
              </span>
            </div>
            <div className={cx(styles["map-cloud_group-data"])}>
              {order.delivery.street} <br /> {order.delivery.city} {order.delivery.postCode}
            </div>
            <div className={cx(styles["map-cloud_group-data"])}>tel.: {order.delivery.phone}</div>
          </div>
        </div>
        <div className="col-6 pr-0">
          <div className={cx(styles["map-cloud_data-group"], "mb-3")}>
            <div className={cx(styles["map-cloud_group-label"])}>
              <img src={commentImg} alt="" />
              <span>Komentarze</span>
            </div>
            <div className={cx(styles["map-cloud_group-data"])}>
              {order.comments.length ? (
                order.comments.map(comment => (
                  <div key={comment.id}>
                    <div className={styles.hasComment}>{comment.body}</div>
                    <div className={styles.usersComment}>
                      <strong className="mr-2">
                        {comment.user.firstName} {comment.user.lastName}
                      </strong>
                      <span className="mr-2">
                        {dateFns.format(new Date(comment.created), "d MMM yyyy")}
                      </span>
                      <span>{dateFns.format(new Date(comment.created), "H:mm")}</span>
                    </div>
                  </div>
                ))
              ) : (
                <span className={styles.noComment}>Brak komentarzy</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="d-flex flex-wrap m-0 row">
        <div className={cx(styles["map-cloud_data-group"], "my-2 col-6 pl-0")}>
          <div className={cx(styles["map-cloud_group-label"])}>
            <img src={carImg} alt="" />
            <span>Przewidywany czas dostawy</span>
          </div>
          <div className={cx(styles["map-cloud_group-data"])}>
            {order.collectionHours ? (
              <>
                <span>{order.collectionHours.minimumCollectionHour || "--:--"}</span>
                <span className="mx-2">-</span>
                <span>{order.collectionHours.maximumCollectionHour || "--:--"}</span>
              </>
            ) : (
              <>
                <span className={styles.noComment}>--:--</span>
                <span className="mx-2">-</span>
                <span className={styles.noComment}>--:--</span>
              </>
            )}
          </div>
        </div>
        <div className={cx(styles["map-cloud_data-group"], "my-2 col-6 pr-0")}>
          <div className={cx(styles["map-cloud_group-label"])}>
            <img src={timeImg} alt="" />
            <span>Preferowane godziny</span>
          </div>
          <div className={cx(styles["map-cloud_group-data"])}>
            {order.delivery.minimumDeliveryTime ? (
              <span>{order.delivery.minimumDeliveryTime}</span>
            ) : (
              <span className={styles.noComment}>--:--</span>
            )}
            <span className="mx-2">-</span>
            {order.delivery.maximumDeliveryTime ? (
              <span>{order.delivery.maximumDeliveryTime}</span>
            ) : (
              <span className={styles.noComment}>--:--</span>
            )}
          </div>
        </div>
      </div>
      <hr />
      <div className="d-flex flex-wrap mt-2">
        <div
          className={cx(styles["map-cloud_data-group"], "map-cloud_data-group--product-list mb-3")}
        >
          {order.hasProducedItems ? (
            <div className={styles.hasProducedItems}>
              <img src={checkImg} alt="" className="mr-2" />
              <span>Wszystkie meble są przygotowane</span>
            </div>
          ) : null}
          <div className={cx(styles["map-cloud_group-label"])}>
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="d-flex align-items-center">
                <img src={furnitureImg} alt="" />
                <span>Dostarczyć zamówienie</span>
              </div>
              {order.hasReservations ? (
                <Label color="blue">zarezerwowano</Label>
              ) : (
                <Label color="pink">Nie zarezerwowano</Label>
              )}
            </div>
          </div>
          <div className={cx(styles["map-cloud_group-data"])}>
            {order.externalId ? (
              <div>
                <Link to={getOrderRedirectUrl(order)} className={cx(styles.signature, "mb-1 mr-1")}>
                  {order.signature}
                </Link>
                <span className="fs-12 text-color-grey">({order.externalId})</span>
              </div>
            ) : (
              <Link to={getOrderRedirectUrl(order)} className={cx(styles.signature, "mb-1")}>
                {order.signature}
              </Link>
            )}
            {order.items.map(item => (
              <div className="product d-flex align-items-center mb-2" key={item.id}>
                <div>
                  <div className="d-flex product-name mr-3">
                    {item.product ? item.product.name : "???"}
                    <div className="d-flex align-items-center ml-1">
                      <img className={styles.xIcon} src={crossImg} alt="" />
                      <span className="ml-1">{item.quantity}</span>
                    </div>
                    <Button
                      type="button"
                      kind="secondary"
                      size="round-s"
                      className="ml-1"
                      onClick={() => modal.open(item.index)}
                      title="Sprawdź dostępność"
                    >
                      <img alt="" src={availabilityStatusIcon} />
                    </Button>
                  </div>
                  {item.attributes.map(
                    ({ attribute, value }: { attribute: string; value: string }, index: number) => (
                      <div className={styles.attribute} key={index}>
                        <span>{attribute}: </span>
                        <strong>{value}</strong>
                      </div>
                    ),
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {modal.isOpen && <AvailabilityDetailsModal stateModal={modal} />}
    </div>
  );
};

const isAwaitingForWarehouseDelivery = (order: OrderForRoute): boolean => {
  return (
    order.warehouseDeliveryDetails &&
    !order.warehouseDeliveryDetails.isInWarehouse &&
    order.warehouseDeliveryDetails.date !== null
  );
};
