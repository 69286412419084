import styles from "./FilterDropdown.module.css";
import cx from "classnames";
import { useQuery, useToggle } from "hooks";
import { ClickOutsideHandler } from "components/utils";
import { clickOutsideIgnoreClass } from "../constants";

export const FilterDropdown = ({
  options,
  name,
  label,
  defaultValue,
  keepTextFormatting = false,
}: {
  label: string;
  name: string;
  options: { value: string; label: string }[];
  defaultValue: string;
  keepTextFormatting?: boolean;
}) => {
  const { isOpen, close, toggle } = useToggle(false);
  const { query, updateQuery } = useQuery();
  const selected =
    options.find(option => option.value === query[name]) ||
    options.find(option => option.value === defaultValue);
  return (
    <ClickOutsideHandler onClickOutside={close} outsideClickIgnoreClass={clickOutsideIgnoreClass}>
      <div className={styles.filter}>
        <button
          type="button"
          className="d-flex align-items-center px-1"
          aria-haspopup="menu"
          onClick={toggle}
        >
          <strong className={cx("mr-1", styles.filterLabel)}>{label}:</strong>
          <strong className={styles.selected}>{selected?.label}</strong>
        </button>
        <div
          className={styles.optionsList}
          id="test"
          role="menu"
          style={{ display: isOpen ? "block" : "none" }}
        >
          {options.map(option => (
            <div
              key={option.label}
              className={cx(styles.optionsItem, { [styles.optionFormatting]: !keepTextFormatting })}
              role="menuitem"
              onClick={() => {
                updateQuery({ [name]: option.value, page: 1 });
                close();
              }}
            >
              <strong>{option.label}</strong>
            </div>
          ))}
        </div>
      </div>
    </ClickOutsideHandler>
  );
};

type Option = { value: string; label: string };
export const FilterDropdownManual = ({
  options,
  label,
  value,
  onClick,
}: {
  label: string;
  options: Option[];
  value: Option["value"];
  onClick: (option: Option["value"]) => void;
}) => {
  const { isOpen, close, toggle } = useToggle(false);
  const selected = options.find(el => el.value === value);

  return (
    <ClickOutsideHandler onClickOutside={close} outsideClickIgnoreClass={clickOutsideIgnoreClass}>
      <div className={styles.filter}>
        <button
          type="button"
          className="d-flex align-items-center px-1"
          aria-haspopup="menu"
          onClick={toggle}
        >
          <strong className={cx("mr-1", styles.filterLabel)}>{label}:</strong>
          <strong className={styles.selected}>{selected?.label}</strong>
        </button>
        <div
          className={styles.optionsList}
          role="menu"
          style={{ display: isOpen ? "block" : "none" }}
        >
          {options.map(option => (
            <div
              key={option.value}
              className={styles.optionsItem}
              role="menuitem"
              onClick={() => {
                onClick(option.value);
                close();
              }}
            >
              <strong>{option.label}</strong>
            </div>
          ))}
        </div>
      </div>
    </ClickOutsideHandler>
  );
};
