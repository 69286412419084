import priceListsIcon from "assets/images/102g.svg";
import gearIcon from "assets/images/13.svg";
import smsIcon from "assets/images/174.svg";
import manufacturerOrdersIcon from "assets/images/183.svg";
import employeeIcon from "assets/images/188.svg";
import productionOrdersIcon from "assets/images/189g.svg";
import driverIcon from "assets/images/190g.svg";
import productionPlanIcon from "assets/images/219.svg";
import warehouseLocationIcon from "assets/images/172g.svg";
import driversSchedule from "assets/images/221.svg";
import indexesPopularityIcon from "assets/images/224.svg";
import carsIcon from "assets/images/24.svg";
import warehouseIcon from "assets/images/275.svg";
import reservationIcon from "assets/images/279.svg";
import productSetsIcon from "assets/images/281.svg";
import inventoryCheckIcon from "assets/images/309.svg";
import usersIcon from "assets/images/30g.svg";
import ordersIcon from "assets/images/31g.svg";
import routesIcon from "assets/images/32g.svg";
import productsIcon from "assets/images/33g.svg";
import clientsIcon from "assets/images/34g.svg";
import helpdeskIcon from "assets/images/35g.svg";
import upholsteryIcon from "assets/images/83g.svg";
import templatesIcon from "assets/images/866.svg";
import statusesIcon from "assets/images/102g.svg";
import bankAccountsIcon from "assets/images/bank.svg";
import miloOffersIcon from "assets/images/transactions.svg";
import labelsIcon from "assets/images/143.svg";
import barcodeIcon from "assets/images/barcode.svg";
import cx from "classnames";
import { DisabledOpacity } from "components/utils";
import { useSelector } from "hooks";
import { Link } from "react-router-dom";
import styles from "../Navbar.module.css";

export const MoreLinksNavigation = ({
  shouldBeOpen,
  shortCutsOpen,
}: {
  shouldBeOpen: boolean;
  shortCutsOpen: boolean;
}) => {
  const isWarehouseInUse = useSelector(state => state.partials.configuration.isWarehouseInUse);

  return (
    <div
      className={styles.appsMenu}
      style={
        shortCutsOpen ? { left: shouldBeOpen ? 220 : 70 } : { left: shouldBeOpen ? -220 : -270 }
      }
    >
      <div className={styles.appList}>
        <Link className={styles.appListItem} to="/orders">
          <div className={styles.appListItemIcon}>
            <img src={ordersIcon} alt="" />
          </div>
          <div className={styles.appListItemTitle}>Zamówienia</div>
        </Link>
        <Link className={styles.appListItem} to="/upholstery-production-orders">
          <div className={styles.appListItemIcon}>
            <img src={upholsteryIcon} alt="" />
          </div>
          <div className={styles.appListItemTitle}>Produkcja</div>
        </Link>
        <Link className={styles.appListItem} to="/routes">
          <div className={styles.appListItemIcon}>
            <img src={routesIcon} alt="" />
          </div>
          <div className={styles.appListItemTitle}>Trasy</div>
        </Link>
        <Link className={styles.appListItem} to="/products">
          <div className={styles.appListItemIcon}>
            <img src={productsIcon} alt="" />
          </div>
          <div className={styles.appListItemTitle}>Produkty</div>
        </Link>
        <Link className={styles.appListItem} to="/customers">
          <div className={styles.appListItemIcon}>
            <img src={clientsIcon} alt="" />
          </div>
          <div className={styles.appListItemTitle}>Kontrahenci</div>
        </Link>
        <Link className={styles.appListItem} to="/users">
          <div className={styles.appListItemIcon}>
            <img src={usersIcon} alt="" />
          </div>
          <div className={styles.appListItemTitle}>Użytkownicy</div>
        </Link>
        <Link className={styles.appListItem} to="/cars">
          <div className={styles.appListItemIcon}>
            <img src={carsIcon} alt="" />
          </div>
          <div className={styles.appListItemTitle}>Samochody</div>
        </Link>
        <Link className={styles.appListItem} to="/manufacturer-orders">
          <div className={styles.appListItemIcon}>
            <img src={manufacturerOrdersIcon} alt="" />
          </div>
          <div
            className={cx(
              styles.appListItemTitle,
              "d-flex flex-column justify-content-center align-items-center",
            )}
          >
            <div>Zlecenia do</div>
            <div>dostawców</div>
          </div>
        </Link>
        <Link className={styles.appListItem} to="/sms-messages/list">
          <div className={styles.appListItemIcon}>
            <img src={smsIcon} alt="" />
          </div>
          <div className={styles.appListItemTitle}>
            <div>SMS</div>
          </div>
        </Link>
        <Link className={styles.appListItem} to="/upholstery/employees">
          <div className={styles.appListItemIcon}>
            <img src={employeeIcon} alt="" />
          </div>
          <div className={styles.appListItemTitle}>
            <div>Pracownicy</div>
          </div>
        </Link>
        <Link className={styles.appListItem} to="/self-production-orders">
          <div className={styles.appListItemIcon}>
            <img src={productionOrdersIcon} alt="" />
          </div>
          <div
            className={cx(
              styles.appListItemTitle,
              "d-flex flex-column justify-content-center align-items-center",
            )}
          >
            <div>Zlecenia</div>
            <div>produkcyjne</div>
          </div>
        </Link>
        <Link className={styles.appListItem} to="/drivers">
          <div className={styles.appListItemIcon}>
            <img src={driverIcon} alt="" />
          </div>
          <div className={styles.appListItemTitle}>
            <div>Kierowcy</div>
          </div>
        </Link>
        <Link className={styles.appListItem} to="/production-plans">
          <div className={styles.appListItemIcon}>
            <img src={productionPlanIcon} alt="" />
          </div>
          <div
            className={cx(
              styles.appListItemTitle,
              "d-flex flex-column justify-content-center align-items-center",
            )}
          >
            <div>Plany</div>
            <div>produkcyjne</div>
          </div>
        </Link>
        <Link className={styles.appListItem} to="/routes/drivers-schedule">
          <div className={styles.appListItemIcon}>
            <img src={driversSchedule} alt="" />
          </div>
          <div
            className={cx(
              styles.appListItemTitle,
              "d-flex flex-column justify-content-center align-items-center",
            )}
          >
            <div>Plany</div>
            <div>wyjazdów</div>
            <div>kierowców</div>
          </div>
        </Link>
        <Link className={styles.appListItem} to="/orders/index-popularity">
          <div className={styles.appListItemIcon}>
            <img src={indexesPopularityIcon} alt="" />
          </div>
          <div
            className={cx(
              styles.appListItemTitle,
              "d-flex flex-column justify-content-center align-items-center",
            )}
          >
            <div>Popularność</div>
            <div>produktów</div>
          </div>
        </Link>
        {isWarehouseInUse && (
          <>
            <Link className={styles.appListItem} to="/warehouse/documents">
              <div className={styles.appListItemIcon}>
                <img src={warehouseIcon} alt="" />
              </div>
              <div
                className={cx(
                  styles.appListItemTitle,
                  "d-flex flex-column justify-content-center align-items-center",
                )}
              >
                <div>Dokumenty</div>
                <div>magazynowe</div>
              </div>
            </Link>
            <Link className={styles.appListItem} to="/reservations">
              <div className={styles.appListItemIcon}>
                <img src={reservationIcon} alt="" />
              </div>
              <div
                className={cx(
                  styles.appListItemTitle,
                  "d-flex flex-column justify-content-center align-items-center",
                )}
              >
                <div>Rezerwacje</div>
              </div>
            </Link>
            <Link className={styles.appListItem} to="/warehouse-locations">
              <div className={styles.appListItemIcon}>
                <img src={warehouseLocationIcon} alt="" />
              </div>
              <div
                className={cx(
                  styles.appListItemTitle,
                  "d-flex flex-column justify-content-center align-items-center",
                )}
              >
                <div>Lokalizacje</div>
                <div>magazynowe</div>
              </div>
            </Link>
          </>
        )}
        <Link className={styles.appListItem} to="/product-sets">
          <div className={styles.appListItemIcon}>
            <img src={productSetsIcon} alt="" />
          </div>
          <div
            className={cx(
              styles.appListItemTitle,
              "d-flex flex-column justify-content-center align-items-center",
            )}
          >
            <div>Asortymenty</div>
          </div>
        </Link>
        <Link className={styles.appListItem} to="/price-lists">
          <div className={styles.appListItemIcon}>
            <img src={priceListsIcon} alt="" />
          </div>
          <div
            className={cx(
              styles.appListItemTitle,
              "d-flex flex-column justify-content-center align-items-center",
            )}
          >
            <div>Cenniki</div>
          </div>
        </Link>
        <Link className={styles.appListItem} to="/milo-settings">
          <div className={styles.appListItemIcon}>
            <img src={gearIcon} alt="" />
          </div>
          <div className={styles.appListItemTitle}>Ustawienia</div>
        </Link>
        <Link className={styles.appListItem} to="/milo-offers">
          <div className={styles.appListItemIcon}>
            <img src={miloOffersIcon} alt="" />
          </div>
          <div
            className={cx(
              styles.appListItemTitle,
              "d-flex flex-column justify-content-center align-items-center",
            )}
          >
            <div>Oferty Milo</div>
          </div>
        </Link>
        <Link className={styles.appListItem} to="/bank-accounts">
          <div className={styles.appListItemIcon}>
            <img src={bankAccountsIcon} alt="" />
          </div>
          <div
            className={cx(
              styles.appListItemTitle,
              "d-flex flex-column justify-content-center align-items-center",
            )}
          >
            <div>Konta bankowe</div>
          </div>
        </Link>
        {isWarehouseInUse && (
          <Link className={styles.appListItem} to="/inventory-check">
            <div className={styles.appListItemIcon}>
              <img src={inventoryCheckIcon} alt="" />
            </div>
            <div
              className={cx(
                styles.appListItemTitle,
                "d-flex flex-column justify-content-center align-items-center",
              )}
            >
              <div>Inwentaryzacja</div>
            </div>
          </Link>
        )}
        <Link className={styles.appListItem} to="/email-templates">
          <div className={styles.appListItemIcon}>
            <img alt="" src={templatesIcon} />
          </div>
          <div
            className={cx(
              styles.appListItemTitle,
              "d-flex flex-column justify-content-center align-items-center",
            )}
          >
            <div>Szablony</div>
          </div>
        </Link>
        <Link className={styles.appListItem} to="/new-production-plans">
          <div className={styles.appListItemIcon}>
            <img alt="" src={productionPlanIcon} />
          </div>
          <div
            className={cx(
              styles.appListItemTitle,
              "d-flex flex-column justify-content-center align-items-center",
            )}
          >
            <div>Plany</div>
            <div>produkcyjne</div>
          </div>
        </Link>
        <Link className={styles.appListItem} to="/statuses">
          <div className={styles.appListItemIcon}>
            <img alt="Ikona statusów" src={statusesIcon} />
          </div>
          <div
            className={cx(
              styles.appListItemTitle,
              "d-flex flex-column justify-content-center align-items-center",
            )}
          >
            <div>Statusy</div>
          </div>
        </Link>
        <Link className={styles.appListItem} to="/labels">
          <div className={styles.appListItemIcon}>
            <img alt="Ikona etykiet" src={labelsIcon} />
          </div>
          <div
            className={cx(
              styles.appListItemTitle,
              "d-flex flex-column justify-content-center align-items-center",
            )}
          >
            <div>Etykiety</div>
          </div>
        </Link>
        <Link className={styles.appListItem} to="/fiscal-printers">
          <div className={styles.appListItemIcon}>
            <img alt="Ikona drukarek fiskalnych" src={barcodeIcon} style={{ color: "#ddd" }} />
          </div>
          <div
            className={cx(
              styles.appListItemTitle,
              "d-flex flex-column justify-content-center align-items-center",
            )}
          >
            <div>Drukarki fiskalne</div>
          </div>
        </Link>
      </div>
      <hr />
      <div className={styles.appList}>
        <DisabledOpacity disabled>
          <a className={styles.appListItem} href="/">
            <div className={styles.appListItemIcon}>
              <img src={helpdeskIcon} alt="" />
            </div>
            <div className={styles.appListItemTitle}>Helpdesk</div>
          </a>
        </DisabledOpacity>
      </div>
    </div>
  );
};
