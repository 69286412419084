import { createPaginatedKey } from "api/keys";

export const productionPlansKeys = {
  productionPlans: createPaginatedKey("productionPlans"),
  productionPlan: (id: string) => ["productionPlan", id],
  manufacturingSchemaStages: createPaginatedKey("manufacturingSchemaStages"),
  schemaStages: createPaginatedKey("schemaStages"),

  shelfItems: (id: string) => ["shelfItems", id],
  shelfItemDetails: createPaginatedKey("shelfItemDetails"),
};
