import { Partials } from "./models";
import apiFetch, { tokenRefreshMiddleware } from "../../apiConnectors/fetchConnector";
import { utilsKeys } from "api/keys";
import { ApiFetcher } from "hooks/createApiQuery";
import { queryFetch } from "apiConnectors/queryFetch";

const api = tokenRefreshMiddleware(apiFetch);

export const getPartials = (search = "") =>
  api<Partials>({
    method: "GET",
    url: `/utils/partials${search}`,
  });

export const getCustomerPartials = (search = "") =>
  api<Partials>({
    method: "GET",
    url: `/utils/partials/customer-details${search}`,
  });

export const patchConfiguration = (
  data: Partial<Omit<Partials["configuration"], "id">>,
  configId: number | string,
) =>
  api<Partials["configuration"]>({
    method: "PATCH",
    url: `/owners/configurations/${configId}`,
    data,
  });

export const getRegisterSalesAccount = (host: string, salesAccountId: number) =>
  api<{ verificationUri: string; interval: number; deviceCode: string }>({
    method: "GET",
    url: `/${host}/${salesAccountId}/register-app`,
  });

export const getModeDetails = (): ApiFetcher<{
  mode: string;
  instance: string;
  debug: boolean;
}> => ({
  key: utilsKeys.modeDetails(),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/utils/mode/details",
    }),
});

export const getModeConfiguration = (): ApiFetcher<Record<string, any>> => ({
  key: utilsKeys.modeConfiguration(),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/utils/mode/configuration",
    }),
});
