import { forwardRef } from "react";
import * as React from "react";
import styles from "./Button.module.css";
import cx from "classnames";

type Button = PrefixRefForwardingComponent<"button", ButtonProps>;

const kindToStyleDict: Record<NonNullable<ButtonProps["kind"]>, string> = {
  "secondary-grey": styles["secondary-grey"],
  none: "",
};

const sizeToStyleDict: Record<NonNullable<ButtonProps["size"]>, string> = {
  large: styles["button-l"],
  medium: styles["button-m"],
  small: styles["button-s"],
};

const ButtonInner = (
  {
    children,
    type = "button",
    kind = "none",
    size = "medium",
    className = "",
    as,
    disabled,
    onClick,
    round = false,
    ...rest
  }: ButtonProps,
  ref: unknown,
) => {
  const kindStyle = kindToStyleDict[kind];
  const sizeStyle = sizeToStyleDict[size];

  const Component = as || "button";

  return (
    <Component
      ref={ref}
      {...rest}
      onClick={onClick}
      type={type}
      className={cx(styles.btn, kindStyle, sizeStyle, className, { [styles.round]: round })}
      disabled={disabled}
    >
      {children}
    </Component>
  );
};

export const Button = forwardRef(ButtonInner) as Button;

export interface PrefixAndClassNameOnlyProps {
  bsPrefix?: string;
  className?: string;
}

export interface PrefixProps<As extends React.ElementType = React.ElementType> {
  as?: As;
}

export type PrefixPropsWithChildren<
  As extends React.ElementType = React.ElementType
> = React.PropsWithChildren<PrefixProps<As>>;

export type ButtonType = "button" | "reset" | "submit" | string;

export interface ButtonProps extends React.HTMLAttributes<HTMLElement>, PrefixPropsWithChildren {
  size?: "large" | "medium" | "small";
  disabled?: boolean;
  target?: any;
  type?: ButtonType;
  kind?: "secondary-grey" | "none";
  round?: boolean;
}

type Omit<T, U> = Pick<T, Exclude<keyof T, keyof U>>;

export type ReplaceProps<Inner extends React.ElementType, P> = Omit<
  React.ComponentPropsWithRef<Inner>,
  P
> &
  P;

export interface PrefixRefForwardingComponent<TInitial extends React.ElementType, P = unknown> {
  <As extends React.ElementType = TInitial>(
    props: React.PropsWithChildren<ReplaceProps<As, PrefixProps<As> & P>>,
    context?: any,
  ): React.ReactElement | null;
  propTypes?: any;
  contextTypes?: any;
  defaultProps?: Partial<P>;
  displayName?: string;
}
