import * as React from "react";
import styles from "./Drawer.module.css";
import cx from "classnames";

interface DrawerProps {
  className?: string;
  children?: React.ReactNode;
}

export const DrawerRight = ({ children }: DrawerProps) => {
  return (
    <div className={styles.drawerRight}>
      <div className={styles.drawerInner}>{children}</div>
    </div>
  );
};

export const DrawerContent = ({ children, className }: DrawerProps) => {
  return <div className={cx(styles.drawerContent, className)}>{children}</div>;
};

export const DrawerHeader = ({ children }: DrawerProps) => {
  return <div className={styles.drawerHeader}>{children}</div>;
};

export const DrawerSections = ({ children, className }: DrawerProps) => {
  return <div className={cx(styles.drawerSections, className)}>{children}</div>;
};

export const DrawerSection = ({
  children,
  title = "",
  className = "",
}: DrawerProps & { title?: React.ReactNode }) => {
  return (
    <div className={cx(styles.drawerSection, className)}>
      {title ? <div className={styles.drawerSectionTitle}>{title}</div> : null}
      {children}
    </div>
  );
};

export const DrawerBottom = ({ children }: DrawerProps) => {
  return <div className={styles.drawerBottom}>{children}</div>;
};

export const ListAndDrawerWrapper = ({ children, className = "" }: DrawerProps) => {
  return <div className={cx(className, styles.listWrapper)}>{children}</div>;
};

export const DrawerHr = () => {
  return <div className={styles.drawerHr} />;
};
