import * as React from "react";
import styles from "./Header.module.css";
import cx from "classnames";
import { useQuery, useSelector, useRedux, useStateModal } from "hooks";
import listIcon from "assets/images/68.svg";
import boardIcon from "assets/images/69.svg";
import dailyProductionIcon from "assets/images/204.svg";
import helpDeskIcon from "assets/images/35.svg";
import { useParams, Link, useLocation } from "react-router-dom";
import { dateFns, queryString } from "utilities";
import { AvailableViewKeys } from "ducks/ui";
import { ToasterDropdown } from "./toasterDropdown";
import { Button } from "../buttonLegacy";
import { DailyStatistics } from "./dailyStatistics";
import { FLAVOR } from "CONSTANTS";
import { DevModeBar } from "components/utils/devModeBar";
import { PopoverMenu } from "components/utils/Popover";
import { HeaderUserDropdown } from "../headerUserDropdown/HeaderUserDropdown";
import { DisabledOpacity } from "components/utils";

interface Props {
  title: string | undefined | ((arg: { styles: { title: string } }) => React.ReactNode);
  subtitle?: React.ReactNode;
  viewSwitch?: boolean;
  tabs?: { label: string; name: string; path?: string; exactPath?: boolean }[];
  routeRoot?:
    | "orders"
    | "client/orders"
    | "products"
    | "routes"
    | "shipment"
    | "order-groups"
    | "users"
    | "complaints"
    | "manufacturer-orders"
    | "self-production-orders"
    | "warehouse"
    | "settings"
    | "upholsteries"
    | "reservations"
    | "upholstery-production-orders"
    | "productSets"
    | "indexes"
    | "statuses"
    | "labels"
    | "fiscalPrinters"
    | "manufacturing-order-groups"
    | "email-templates"
    | "new-production-plans"
    | "warehouse-locations"
    | "bank-accounts";
  viewType?: AvailableViewKeys;
  urlSpan?: string;
  customColumn?: React.ReactNode;
  customFooter?: React.ReactNode;
  showDailyStatistics?: boolean;
}

export const Header = ({
  title,
  subtitle,
  viewSwitch = false,
  tabs,
  routeRoot,
  viewType,
  customColumn,
  customFooter,
  showDailyStatistics = false,
  urlSpan = "list",
}: Props) => {
  const params = useParams<{ tab: string; navbarTab: string }>();
  const { query } = useQuery();
  const location = useLocation();
  const modal = useStateModal();
  const activeViewType = useSelector(state => viewType && state.ui.viewTypes[viewType]);
  const [dispatch, { ui }] = useRedux();
  const showTabs = activeViewType === undefined || activeViewType === "list";
  const hasDailyProductionService = useSelector(
    state => state.partials.configuration.hasDailyProductionService,
  );

  function renderTitle() {
    if (!title) return null;
    if (typeof title === "string") {
      return <h1 className={styles.title}>{title}</h1>;
    }
    if (typeof title === "function") {
      return title({ styles: { title: styles.title } });
    }
    return title;
  }

  function getPath(tab: NonNullable<Props["tabs"]>[number]) {
    const search = queryString.stringify({ ...query, page: "" });
    if (tab.exactPath) {
      return String(tab.path);
    }
    if (tab.path) {
      return `${tab.path}/${tab.name}${search}`;
    }
    return `/${routeRoot}/${urlSpan}/${tab.name}${
      params.navbarTab ? "/" + params.navbarTab : ""
    }${search}`;
  }

  return (
    <header>
      <div className={styles.header}>
        <div className="d-flex">
          <div className={cx("d-flex flex-column", styles.headerLeft)}>
            <div className="d-flex align-items-center">
              <div className="mt-2">{renderTitle()}</div>
              {viewSwitch && activeViewType && (
                <div className={styles.viewTabs}>
                  <button
                    className={cx("mr-2", { [styles.viewTabActive]: activeViewType === "list" })}
                    onClick={() => dispatch(ui.toggleViewType({ type: "orders", value: "list" }))}
                  >
                    <img src={listIcon} alt="Lista" />
                  </button>
                  <button
                    className={cx({ [styles.viewTabActive]: activeViewType === "board" })}
                    onClick={() => dispatch(ui.toggleViewType({ type: "orders", value: "board" }))}
                  >
                    <img src={boardIcon} alt="Tablica" />
                  </button>
                </div>
              )}
            </div>
            {subtitle && <div className={styles["subtitle"]}>{subtitle}</div>}
            <div className={cx("d-flex", styles.navTabs)}>
              {tabs &&
                showTabs &&
                tabs.map(tab => (
                  <Link
                    key={tab.name}
                    to={getPath(tab)}
                    className={cx(styles.navTab, {
                      [styles.navTabActive]: params.tab === tab.name,
                    })}
                  >
                    {tab.label}
                  </Link>
                ))}
            </div>
            {customFooter}
          </div>
          {customColumn}
        </div>
        <div className="d-flex justify-content-end align-items-center">
          {FLAVOR !== "b2b" && showDailyStatistics && hasDailyProductionService && (
            <div className="border-right pr-2 mr-2 d-flex align-items-center justify-content-center">
              <Button
                kind="secondary-stroke"
                size="small"
                onClick={() => {
                  const currentDate = dateFns.format(new Date(), "yyyy-MM-dd");
                  modal.open(currentDate);
                }}
              >
                <img src={dailyProductionIcon} alt="Helpdesk" className="mr-2" />
                Dzienna produkcja
              </Button>
              <DailyStatistics stateModal={modal} />
            </div>
          )}
          <DevModeBar />

          <PopoverMenu
            target={btnProps => (
              <DisabledOpacity disabled>
                <div className={cx("mr-1", styles.btnCircular)} role="button" {...btnProps}>
                  <img src={helpDeskIcon} alt="Helpdesk" />
                </div>
              </DisabledOpacity>
            )}
          >
            {() => (
              <div className={styles.popoverMenu}>
                <div className={styles.popoverMenuTop}>
                  <div className="text-center mt-4">
                    <img
                      src={helpDeskIcon}
                      alt=""
                      style={{ width: 40 }}
                      className="d-inline-block mb-3"
                    />
                    <div className="color-black-35 mb-5">CENTRUM POMOCY</div>
                  </div>
                  <span className="d-block fs-36 text-center mb-3">Jak możemy pomóc?</span>
                  <span className="d-block text-color-silver-chalice mb-5 text-center">
                    Masz trudności z użytkowaniem aplikacji? Chcesz o coś zapytać?
                  </span>
                  <div className="d-flex justify-content-center">
                    <Button
                      kind="secondary"
                      size="medium"
                      className="mb-2 bg-white"
                      as={Link}
                      to={`/helpdesk/new-issue/${encodeURIComponent(
                        location.pathname + location.search,
                      )}`}
                    >
                      Wypełnij formularz zgłoszeniowy
                    </Button>
                  </div>
                </div>
                <div className={cx(styles.popoverMenuBottom)}>
                  <span className="d-block fs-20 mb-1 text-center">
                    Masz pomysł jak ulepszyć aplikację?
                  </span>
                  <span className="d-block text-color-silver-chalice mb-4 text-center">
                    Daj nam znać co możemy dla Ciebie zrobić.
                  </span>
                  <div className="d-flex justify-content-center">
                    <Button
                      kind="secondary-stroke"
                      size="small"
                      className={styles.helpdeskBtn}
                      disabled
                    >
                      Napisz do nas
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </PopoverMenu>

          <ToasterDropdown />

          <HeaderUserDropdown />
        </div>
      </div>
    </header>
  );
};
