import * as React from "react";
import { useField } from "formik";
import styles from "./FormTextarea.module.css";
import cx from "classnames";

export const FormTextarea: React.FC<{
  label?: string;
  name: string;
  textareaStyle?: React.CSSProperties;
  className?: string;
  placeholder?: string;
  overrides?: { textarea?: { className?: string } };
}> = ({ label, children, className, textareaStyle, placeholder, overrides = {}, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className={cx(styles.textareaWrapper, className)}>
      <label style={{ width: "100%" }}>
        <textarea
          placeholder={placeholder}
          className={cx(styles.textarea, overrides.textarea?.className)}
          {...field}
          {...props}
          style={{ width: "100%", ...textareaStyle }}
        />
        <span className={styles.label}>{label}</span>
      </label>
      {meta.touched && meta.error ? <div className="invalid-feedback">{meta.error}</div> : null}
    </div>
  );
};
