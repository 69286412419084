import { useState } from "react";
import { useAsyncModal } from "hooks";
import { Modal } from "components/utils";
import { Button } from "components/common";
import styles from "./ToolbarDateAsyncModal.module.css";
import { DatePicker } from "components/utils/datePicker";
import { dateFns } from "utilities";

export const ToolbarDateAsyncModal = ({ id }: { id: Symbol }) => {
  const asyncModal = useAsyncModal();
  const isOpen = asyncModal.opened === id;
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  return (
    <Modal isOpen={isOpen} close={asyncModal.close} size={{ all: { width: "300px" } }}>
      <div>
        <div>
          <h5 className="pb-3">Wskaż zakres dat</h5>
        </div>
        <div className="position-relative mb-3">
          <span className={styles.label}>Od:</span>
          <DatePicker
            className={styles.date}
            value={start}
            onChange={date => {
              if (!date) {
                setStart("");
              } else {
                setStart(dateFns.format(new Date(date), "yyyy-MM-dd"));
              }
            }}
          />
        </div>
        <div className="position-relative ">
          <span className={styles.label}>Do:</span>
          <DatePicker
            className={styles.date}
            value={end}
            onChange={date => {
              if (!date) {
                setEnd("");
              } else {
                setEnd(dateFns.format(new Date(date), "yyyy-MM-dd"));
              }
            }}
          />
        </div>
        <div className="d-flex align-items-center justify-content-end pt-4">
          <Button
            kind="primary"
            onClick={() => {
              asyncModal.resolve([start, end]);
            }}
          >
            Szukaj
          </Button>
        </div>
      </div>
    </Modal>
  );
};
