import { OwnerConfig } from "./types";
import { config as defaultConfig } from "./default";
import { configMerger } from "./utils";

export const config: OwnerConfig = {
  main: configMerger(defaultConfig.main, {
    confirmCollectionDate: { showPlannedCollectionHours: false, showOfficeHours: true },
    deliveryTypes: [
      {
        name: "transport producenta",
        id: "self-shipped",
      },
      {
        name: "transport zewnętrzny",
        id: "external-shipment",
      },
      {
        name: "odbiór osobisty",
        id: "personal-collection",
      },
    ],
    orderPanel: {
      manufacturerOrderSection: { _show: false },
    },
  }),
  manufacturing: configMerger(defaultConfig.manufacturing, {
    confirmCollectionDate: { showPlannedCollectionHours: false, showOfficeHours: true },
    deliveryTypes: [
      {
        name: "transport producenta",
        id: "self-shipped",
      },
      {
        name: "transport zewnętrzny",
        id: "external-shipment",
      },
      {
        name: "odbiór osobisty",
        id: "personal-collection",
      },
    ],
    orderPanel: {
      manufacturerOrderSection: { _show: false },
    },
  }),
  b2b: configMerger(defaultConfig.b2b, {
    orderPanel: {
      leftDaysEditable: false,
      nameEditable: true,
    },
  }),
  transport: {
    ...defaultConfig.transport,
  },
};
