import { useState, useRef, useEffect, useCallback } from "react";
import { ApiMiddlewareResult } from "apiConnectors/fetchConnector";
import { PromiseThen } from "typeUtilities";
import { throttle as throttleFunc } from "throttle-debounce";
import { usePrevious } from "hooks";
import { StatusHandlerHelpers, useStatusHandler } from "./statusHandler";

export interface StateProxyHelpers {
  setError: React.Dispatch<React.SetStateAction<string>>;
  setFetching: (status: boolean) => void;
}

type SpreadOverwrites = {
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (utils: { event: React.ChangeEvent<any>; isFocused: boolean }) => void;
};

interface StateProxyProps<State> {
  inputProxy?: (state: State) => State;
  outputProxy?: (state: State, previousState: State) => State;
  state: State;
  debounce?: number;
  throttle?: number;
  children: ({
    state,
    setState,
    triggerChange,
    asyncResult,
  }: {
    spreadProps: (
      overwrites?: SpreadOverwrites,
    ) => {
      onFocus: () => void;
      onBlur: () => void;
      value: State;
      onChange: (e: React.ChangeEvent<any>) => void;
    };
    setFocus: () => void;
    setBlur: () => void;
    state: State;
    inProgress: boolean;
    setState: React.Dispatch<React.SetStateAction<State>>;
    triggerChange: () => void;
    asyncResult: PromiseThen<ApiMiddlewareResult<any>>;
    error: string;
    helpers: StatusHandlerHelpers;
  }) => any;
  onChange: (
    arg: State,
    helpers: StatusHandlerHelpers,
  ) => void | Promise<void> | ApiMiddlewareResult<any> | Promise<{ fallback: boolean }>;
  setError?: (arg: string) => void;
  validate?: (state: State) => string;
  setBusy?: () => void;
  resetKey?: string | number;
}

function noopProxy(arg: any) {
  return arg;
}
function noopValidate(arg: any) {
  return "";
}

// to fix, it doesn't work properly
// eslint-disable-next-line
// function useUpdateSentinel<State>(
//   state: State,
//   localState: State,
//   changeType: "outer" | "inner" | null,
// ) {
//   const timeout = useRef<any>(0);
//   const [counter, setCounter] = useState(0);
//   const initialRender = useRef(true);
//   const prevCounter = usePrevious(counter);

//   useEffect(() => {
//     if (initialRender.current) return;
//     if (counter === prevCounter) return;
//     if (state !== localState && changeType === "inner") {
//       console.warn(
//         `Hey, there is something wrong. You updated the value, probably sent a request to the server, but input state wasn't updated for over 5 seconds. StateProxy requires outer state to handle updates properly. If you didn't do that, please do. If you do - you did it wrong, please fix it, because it may cause a mismatch between real and displayed value. If it's a problem with your internet connection - ignore this message.`,
//       );
//     }
//   }, [counter, changeType, localState, state, prevCounter]);

//   useEffect(() => {
//     if (initialRender.current) return;
//     if (process.env.NODE_ENV === "development") {
//       clearTimeout(timeout.current);
//       timeout.current = setTimeout(() => {
//         setCounter(num => num + 1);
//       }, 5000);
//     }
//   }, [localState, timeout]);

//   useEffect(() => {
//     initialRender.current = false;
//   }, []);
// }

/**
 * Component used for debounce or throttle state updates.
 * There's an option to handle it's behavior by returning proper Promise from "onChange"
 * function (look: Props["onChange"] type ).
 */
export function StateProxy<State extends any>({
  state,
  debounce,
  throttle = 0,
  onChange = () => {},
  inputProxy = noopProxy,
  outputProxy = noopProxy,
  children,
  setError: externalSetError,
  validate = noopValidate,
  setBusy,
  resetKey,
}: StateProxyProps<State>) {
  const [localState, setState] = useState<State>(inputProxy(state));
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState("");
  const [asyncResult, setAsyncResult] = useState<PromiseThen<ApiMiddlewareResult<any>>>([
    null,
    null,
    { status: 0, isCanceled: false },
  ]);
  const initialRender = useRef(true);
  const isMounted = useRef(true);
  const prevResetKey = usePrevious(resetKey);
  /**
   * this property is required to avoid running "onChange"
   * when state is updated from outside
   * */
  const changeType = useRef<null | "inner" | "outer">(null);
  const timeout = useRef<any>(0);

  const statusBag = useStatusHandler(resetKey);

  // to fix, it doesn't work properly
  // useUpdateSentinel<State>(state, localState, changeType.current);

  const handleChange = useCallback(
    async (arg: State) => {
      if (externalSetError) {
        const error = validate(arg);
        externalSetError(error);
        if (error !== "") {
          return;
        }
      }
      const promise = onChange(arg, statusBag);
      if (promise && promise.then) {
        setInProgress(true);
        const result = await promise;
        // return if not mounted to avoid memory leak
        if (!isMounted.current) return;
        setInProgress(false);
        if (Array.isArray(result)) {
          setAsyncResult(result);
        } else {
          if (result && result.fallback) {
            setState(inputProxy(state));
          }
        }
      }
    },
    [onChange, state, inputProxy, validate, externalSetError, statusBag],
  );

  const throttled = useRef<any>(throttleFunc(throttle, handleChange));

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    if (state === localState && changeType.current === "outer") {
      clearTimeout(timeout.current);
      timeout.current = null;
      if (error) {
        setError("");
      }
      if (externalSetError) {
        externalSetError("");
      }

      return;
    }

    if (changeType.current === "outer") {
      return;
    }
    clearTimeout(timeout.current);
    timeout.current = null;
    if (setBusy) {
      setBusy();
    }
    if (externalSetError) {
      const error = validate(localState);
      externalSetError(error);
      if (error !== "") {
        return;
      }
    }
    if (debounce === Infinity) return;
    if (debounce) {
      timeout.current = setTimeout(() => {
        handleChange(localState);
        timeout.current = null;
      }, debounce);
    } else if (throttle) {
      throttled.current(localState);
    } else {
      handleChange(localState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState]);

  const focus = useRef(false);

  useEffect(() => {
    if (focus.current === false) {
      changeType.current = "outer";
      setState(inputProxy(state));
    }
  }, [state, focus, inputProxy]);

  useEffect(() => {
    if (resetKey !== prevResetKey) {
      changeType.current = "outer";
      setState(inputProxy(state));
    }
  }, [prevResetKey, resetKey, inputProxy, state]);

  const set = useCallback((val: React.SetStateAction<State>) => {
    changeType.current = "inner";
    setState(val);
  }, []);

  const triggerChange = useCallback(() => {
    if (debounce && debounce !== Infinity && !timeout.current) return;
    clearTimeout(timeout.current);
    if (localState !== state) {
      handleChange(localState);
    }
    return;
  }, [handleChange, localState, state, debounce]);

  const setFocus = useCallback(() => (focus.current = true), []);
  const setBlur = useCallback(() => (focus.current = false), []);

  const onBlur = useCallback(() => {
    setBlur();
    triggerChange();
  }, [setBlur, triggerChange]);

  const spreadOnChange = useCallback(
    (e: React.ChangeEvent<any>) => {
      set(outputProxy(e.target.value as any, localState));
    },
    [set, outputProxy, localState],
  );

  return children({
    spreadProps(overwrites: SpreadOverwrites = {}) {
      return {
        value: localState,
        onFocus: () => {
          setFocus();
          overwrites.onFocus?.();
        },
        onBlur: () => {
          onBlur();
          overwrites.onBlur?.();
        },
        onChange: (e: React.ChangeEvent<any>) => {
          spreadOnChange(e);
          overwrites.onChange?.({ event: e, isFocused: focus.current });
        },
      };
    },
    state: localState,
    setState: set,
    triggerChange,
    asyncResult,
    inProgress,
    setFocus,
    setBlur,
    error,
    helpers: statusBag,
  });
}
