import styles from "../../../ProductionPlanDetails.module.css";
import shelfStyles from "../../Shelf.module.css";
import localStyles from "./SourcesBar.module.css";
import hideDownIcon from "assets/images/hideDown.svg";
import cx from "classnames";
import { ProductionPlan } from "api/new-production-plans/models";
import { Button } from "components/common";

interface Props {
  slotsSummary: ProductionPlan["slotsSummary"];
  sourcesSummary: ProductionPlan["sourcesSummary"];
  toggleShelf: () => void;
}

const maxSourcesLength = 14;

export const SourcesBar = ({ slotsSummary, sourcesSummary, toggleShelf }: Props) => {
  return (
    <div className={cx(shelfStyles.shelfHeader, "px-3 py-1")}>
      <div className={localStyles.slotsCounter}>
        <span className={localStyles.slotsCounterLabel}>Wypełnione sloty</span>
        <div>
          <strong>{slotsSummary.used}</strong>
          <span>/{slotsSummary.total}</span>
        </div>
      </div>
      <span className={shelfStyles.separator} />
      <div className={cx(localStyles.shelfSources, styles.gap1, "width-available")}>
        {sourcesSummary.slice(0, maxSourcesLength).map(source => (
          <div
            key={source.sourceId}
            className={cx(localStyles.sourceGroup, localStyles.sourceGroupL)}
          >
            <span className={localStyles.color} style={{ background: source.sourceColor }} />
            {source.sourceSignature}
          </div>
        ))}
        {sourcesSummary.length > maxSourcesLength && (
          <div className={styles.moreDataCounter}>+{sourcesSummary.length - maxSourcesLength}</div>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-end">
        <span className={shelfStyles.separator} />
        <Button kind="secondary" size="round-s" onClick={toggleShelf}>
          <img alt="Zamknij panel" src={hideDownIcon} />
        </Button>
      </div>
    </div>
  );
};
