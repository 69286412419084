import localStyles from "./EmployeeHeader.module.css";
import styles from "../../../../ProductionPlanDetails.module.css";
import showPdfIcon from "assets/images/161.svg";
import binIcon from "assets/images/81.svg";
import cx from "classnames";
import { Employee } from "api/new-production-plans/models";
import { AvatarOrInitials } from "components/utils/userAvatar/UserAvatar";
import { Button } from "components/common";

interface Props {
  employee: Employee;
}

const employeeKind = {
  UPHOLSTERER: "Tapicer",
  CUTTER: "Krajacz",
  SEAMSTRESS: "Krawiec",
};

export const EmployeeHeader = ({ employee }: Props) => {
  return (
    <div className={cx(localStyles.employeeHeader, "d-flex align-items-center")}>
      {employee.employeeWorkingDays.map((employeeWorkingDay, index) => {
        if (index === 0) {
          return (
            <div
              key={employeeWorkingDay.id}
              className={cx(
                localStyles.employeeHeaderInner,
                localStyles.employeeHeaderInnerFirst,
                "d-flex align-items-center",
              )}
            >
              <div>
                <AvatarOrInitials
                  avatarSrc={employee.avatar}
                  firstName={employee.firstName}
                  lastName={employee.lastName}
                  className={cx(localStyles.avatar, localStyles.avatarSmall)}
                />
              </div>
              <div className="mx-2">
                <span>
                  {employeeKind[employee.kind]} {employee.firstName} {employee.lastName}
                </span>
              </div>
              <Button
                kind="secondary"
                size="round-s"
                onClick={() => {}}
                className={cx(
                  styles.button,
                  styles.buttonRound,
                  styles.buttonBackgroundTransparent,
                )}
              >
                <img alt="" src={showPdfIcon} />
              </Button>
              <Button
                kind="secondary"
                size="round-s"
                onClick={() => {}}
                className={cx(
                  styles.button,
                  styles.buttonRound,
                  styles.buttonBackgroundTransparent,
                )}
              >
                <img alt="" src={binIcon} />
              </Button>
              {!employeeWorkingDay.isAvailable && (
                <div className={localStyles.dayOffLabel}>Urlop</div>
              )}
            </div>
          );
        }

        return (
          <div
            key={employeeWorkingDay.id}
            className={cx(localStyles.employeeHeaderInner, "d-flex align-items-center")}
          >
            {!employeeWorkingDay.isAvailable && (
              <div className={cx(localStyles.dayOffLabel, "w-100")}>Urlop</div>
            )}
          </div>
        );
      })}
    </div>
  );
};
