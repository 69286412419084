import styles from "./PopupMenu.module.css";
import optionsImg from "assets/images/41.svg";
import { Button } from "components/common";
import ReactTooltip from "react-tooltip";

export const PopupMenu: React.FC<{ dataFor: string }> = ({ children, dataFor }) => (
  <>
    <Button
      kind="secondary"
      size="round-s"
      className={styles.moreOptions}
      data-for={dataFor}
      data-event="click"
      data-event-off="mouseleave"
      data-tip="2"
    >
      <img src={optionsImg} alt="" />
    </Button>
    <ReactTooltip
      className={styles.tooltip}
      id={dataFor}
      type="light"
      delayHide={400}
      effect="solid"
      place="bottom"
    >
      <div className={styles.tooltipContainer}>{children}</div>
    </ReactTooltip>
  </>
);
