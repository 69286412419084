import { forwardRef } from "react";

/**
 *
 * @param {Object} param0
 * @param {boolean=} param0.isOpen
 * @param {any} param0.children
 */
const BaseMenu = forwardRef(({ isOpen, children, ...rest }, ref) => {
  const styles = {};
  if (isOpen) {
    styles.boxShadow = "0px 5px 5px 2px rgba(0, 0, 0, 0.1), 0px 8px 7px 9px rgba(0, 0, 0, 0.05)";
  } else {
    styles.display = "none";
  }
  return (
    <div ref={ref} style={styles} {...rest}>
      {children}
    </div>
  );
});

/**
 *
 * @param {Object} param0
 * @param {boolean=} param0.isOpen
 */
export const Menu = forwardRef(({ isOpen, ...props }, ref) => {
  return <BaseMenu ref={ref} isOpen={isOpen} {...props} />;
});

Menu.defaultProps = {
  isOpen: false,
};

/**
 *
 * @param {Object} param0
 * @param {boolean=} param0.isActive
 * @param {any} param0.children
 * @param {string} param0.className
 */
export const Item = ({ isActive, isSelected, isDisabled, children, ...rest }) => {
  const styles = {};
  if (isActive) {
    styles.color = "rgba(0,0,0,.95)";
    styles.background = "rgba(0,0,0,.03)";
  }
  if (isSelected) {
    styles.color = "rgba(0,0,0,.95)";
    styles.fontWeight = "700";
  }
  if (isDisabled) {
    styles.color = "#666";
    styles.opacity = 0.6;
    styles.cursor = "default";
    styles.pointerEvents = "none";
  }
  return (
    <div style={styles} {...rest}>
      {children}
    </div>
  );
};
