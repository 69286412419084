import { getPriceListProductIndexes } from "api/price-lists/calls";
import { getProductSetProductIndexes } from "api/product-sets/calls";

export function createPaginatedKey(name: string) {
  return (params?: { [x: string]: string } | string) =>
    params ? [name, JSON.stringify(params)] : [name];
}

export const priceListsKeys = {
  priceLists: createPaginatedKey("priceLists"),
  priceList: (id: string) => ["priceList", id],
  priceListElements: createPaginatedKey("priceListElements"),
  priceListIndexes: (params?: Parameters<typeof getPriceListProductIndexes>[0]) =>
    params ? ["priceListIndexes", JSON.stringify(params)] : ["priceListIndexes"],
};

export const productSetsKeys = {
  productSets: createPaginatedKey("productSets"),
  productSet: (id: string) => ["productSet", id],
  productSetElements: createPaginatedKey("productSetsElements"),
  productSetIndexes: (params?: Parameters<typeof getProductSetProductIndexes>[0]) =>
    params ? ["productSetIndexes", JSON.stringify(params)] : ["productSetIndexes"],
};

export const productsKeys = {
  products: (params?: string) => (params ? ["products", params] : ["products"]),
  productAttributes: (id: string) => ["product", "attributes", id],
  productAttribute: createPaginatedKey("productAttribute"),
  productAttributesFilter: createPaginatedKey("productAttributesFilter"),
};

export const ordersKeys = {
  orders: createPaginatedKey("orders"),
  customerOrders: createPaginatedKey("customerOrders"),
  order: (id: string) => ["order", id],
  additionalData: (uuid: string) => ["orderAdditionalData", uuid],
  joinedOrder: (uuid: string) => ["joinedOrder", uuid],
  complaintParent: (uuid: string) => ["complaintParent", uuid],
  orderForRoute: (id: string) => ["order", "for", "route", id],
  orderMessages: (id: string) => ["orderMessages", id],
  orderTransactions: (id: string) => ["orderTransactions", id],
  orderDeliveryInfo: (id: string) => ["orderDeliveryInfo", id],
  compareOrder: (orderId: string) => ["compareOrder", orderId],
  loadingStatus: (orderId: string) => ["loadingStatus", orderId],
};

export const routeKeys = {
  route: (id: string) => ["route", id],
  routes: (params?: { [x: string]: string }) => (params ? ["routes", params] : ["routes"]),
  routeCreatorPoints: (search?: string) => (search ? ["creatorPoints", search] : ["creatorPoints"]),
  routeCreatorOrdersComments: (id: string) => ["routeComments", id],
  attributes: (params?: { [x: string]: string }) =>
    params ? ["attributes", params] : ["attributes"],
  routeCustomers: (id: string) => ["routeCustomers", id],
  driverLocations: (routeId: string) => ["driverLocations", routeId],
};

export const attributeKeys = {
  attributes: createPaginatedKey("attributes"),
  attribute: (id: string) => ["attribute", id],
};

export const utilsKeys = {
  modeDetails: () => ["modeDetails"],
  modeConfiguration: () => ["modeConfiguration"],
};

export const driverKeys = {
  driver: (id: string) => ["driver", id],
};
