import { Employee, WorkingDay } from "api/new-production-plans/models";
import localStyles from "./EmployeeRow.module.css";
import { EmployeeHeader } from "./subcomponents/employeeHeader/EmployeeHeader";
import { EmployeeWorkingDay } from "./subcomponents/employeeWorkingDay/EmployeeWorkingDay";

interface Props {
  days: WorkingDay[];
  employee: Employee;
}

export const EmployeeRow = ({ days, employee }: Props) => {
  return (
    <div className={localStyles.employeeWrapper}>
      <EmployeeHeader employee={employee} />
      <div className={localStyles.dayCardWrapper}>
        {employee.employeeWorkingDays.map(employeeWorkingDay => (
          <EmployeeWorkingDay
            key={employeeWorkingDay.id}
            days={days}
            employeeWorkingDay={employeeWorkingDay}
          />
        ))}
      </div>
    </div>
  );
};
