import apiFetch, { tokenRefreshMiddleware } from "apiConnectors/fetchConnector";
import { Pagination } from "../types";
import { Log } from "./models";

const api = tokenRefreshMiddleware(apiFetch);

export const getLogs = (search: string) =>
  api<Pagination<Log>>({
    method: "GET",
    url: "/logbook/items" + search,
  });
