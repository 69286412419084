import menuToggleIcon from "assets/images/16.svg";
import logoIcon from "assets/images/logo.svg";
import cx from "classnames";
import { ClickOutsideHandler } from "components/utils";
import cuid from "cuid";
import { usePrevious, useRedux, useSelector, useToggle } from "hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { CreateButtonsAside } from "./createButtonsAside";
import { Footer } from "./footer/Footer";
import { FullNavigation } from "./fullNavigation/FullNavigation";
import { MoreLinksNavigation } from "./moreLinksNavigation/MoreLinksNavigation";
import styles from "./Navbar.module.css";
import { CollapseNavigation } from "./collapseNavigation";

const useThresholdToggle = () => {
  const isNavbarOpen = useSelector(state => state.ui.isNavbarOpened);
  const [dispatch, { ui }] = useRedux();
  const [shouldBeOpen, setShouldBeOpen] = useState(isNavbarOpen);
  const toggleMenu = useCallback(() => dispatch(ui.toggleNavbar()), [dispatch, ui]);
  const toggleOpen = () => setShouldBeOpen(s => !s);
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    toggleMenu();
  }, [shouldBeOpen, toggleMenu]);
  return { isOpen: isNavbarOpen, shouldBeOpen, toggleOpen };
};

export const clickOutsideShortcutsIgnoreClass = "ignore-outside-shortcuts-click-" + cuid();
export const clickOutsideIgnoreClass = "ignore-outside-click-" + cuid();

export const Navbar = () => {
  const { shouldBeOpen, isOpen, toggleOpen } = useThresholdToggle();
  const shortcuts = useToggle();
  const createNavbarControls = useToggle();
  const location = useLocation();
  const previousLocation = usePrevious(location);

  useEffect(() => {
    if (shortcuts.isOpen) {
      createNavbarControls.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortcuts.isOpen]);
  useEffect(() => {
    if (createNavbarControls.isOpen) {
      shortcuts.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createNavbarControls.isOpen]);

  useEffect(() => {
    if (previousLocation !== location) {
      createNavbarControls.close();
      shortcuts.close();
    }
  }, [location, previousLocation, createNavbarControls, shortcuts]);

  return (
    <>
      <nav
        className={cx(styles.navLeft, { [styles.closed]: !shouldBeOpen })}
        role="navigation"
        data-testid="navigation"
      >
        <div className={cx("d-flex align-items-center p-3", styles.navLeftHeader)}>
          {isOpen && (
            <Link className={styles.logo} to="/">
              <img src={logoIcon} alt="Milo" width="78px" />
            </Link>
          )}
          {shouldBeOpen && !isOpen && <div />}
          <button className={styles.menuToggleBtn} onClick={toggleOpen}>
            <img src={menuToggleIcon} alt="Otwiera i zamyka menu" />
          </button>
        </div>

        <FullNavigation isOpen={isOpen} />

        <CollapseNavigation isOpen={!shouldBeOpen} />

        <Footer
          createNavbarControlsToggle={createNavbarControls.toggle}
          shortcutsToggle={shortcuts.toggle}
        />
      </nav>

      <ClickOutsideHandler
        onClickOutside={shortcuts.close}
        outsideClickIgnoreClass={clickOutsideShortcutsIgnoreClass}
      >
        <MoreLinksNavigation shortCutsOpen={shortcuts.isOpen} shouldBeOpen={shouldBeOpen} />
      </ClickOutsideHandler>

      <ClickOutsideHandler
        onClickOutside={createNavbarControls.close}
        outsideClickIgnoreClass={clickOutsideIgnoreClass}
      >
        <CreateButtonsAside controls={createNavbarControls} mainMenuOpened={shouldBeOpen} />
      </ClickOutsideHandler>
    </>
  );
};
