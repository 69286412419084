import { GoogleMap, InfoWindow, Polyline } from "@react-google-maps/api";
import fullMarker from "assets/images/mapMarkers/p29f.png";
import passpointMarker from "assets/images/mapMarkers/p55.png";
import pinnedAwaitingMarker from "assets/images/mapMarkers/awaiting/pinnedAwaitingIcon.svg";
import { useMemo, useRef, useState } from "react";
import * as React from "react";
import { useRouteViewState } from "../routeCreatorState";
import { useInfoWindow } from "../hooks/useInfoWindow";
import { DragPolygon, PolygonController } from "./DragPolygon";
import { InfoWindowContent } from "./infoWindowContent/InfoWindowContent";
import styles from "./Map.module.css";
import { Marker } from "./Marker";
import { MarkerCluster } from "./MarkerCluster";
import { StartingPointMarker } from "./startingPointMarker";
import { OrderPoint } from "api/orders/models";
import { Route } from "api/routes/models";
import { MapMode } from "../Creator";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useAssignItem } from "../hooks/useAssignItem";
import { getMarkerIcon } from "utilities/getMarkerIcon";

interface Props {
  polygonController: PolygonController;
  mapMode: MapMode;
  route: Route;
  routePoints: OrderPoint[];
}

export interface RoutePoint {
  id: string | number;
  type: "order" | "passpoint";
  point: { lat: number; lng: number };
  icon: {
    url: string;
    anchor: google.maps.Point;
  };
  label:
    | {
        text: string;
        color: string;
        fontSize: string;
        fontWeight: string;
      }
    | undefined;
  isPinned: boolean;
  warehouseDeliveryDetails: any;
}

export const Map = ({ polygonController, mapMode, route, routePoints }: Props) => {
  const zoom = useRef(7);
  const showOnlyRoutePoints = useRouteViewState("slave", store => store.showOnlyRoutePoints);
  const isGraphhopperPolylineVisible = useRouteViewState(
    "slave",
    store => store.isGraphhopperPolylineVisible,
  );
  const isLoading = useRouteViewState("slave", store => store.isLoading);
  const actions = useRouteViewState("slave", store => store.actions);
  const { openedInfoWindowData, closeInfoWindow } = useInfoWindow();
  const graphhopperPolyline = useRouteViewState("slave", store => store.graphhopperPolyline);
  const isPointPolylineVisible = useRouteViewState("slave", store => store.isPointPolylineVisible);
  const editingPasspointId = useRouteViewState("slave", store => store.editingPasspointId);
  const [span, setSpan] = useState({ south: 0, north: 0, west: 0, east: 0 });
  const spanLoaded = useRef(false);
  const map = useRef<google.maps.Map>();
  const { assignOrder } = useAssignItem(route);

  const allRoutePoints: RoutePoint[][] = useMemo(() => {
    const latLngToPointsDict: Record<string, RoutePoint[]> = {};

    if (!showOnlyRoutePoints) {
      routePoints.forEach(el => {
        const key = el.point.lat + "_" + el.point.lng;
        const currentLatLng = latLngToPointsDict[key] || [];
        if (!currentLatLng.find(order => order.id === el.id)) {
          const toAdd: RoutePoint = {
            id: el.id,
            isPinned: false,
            point: el.point,
            type: "order",
            label: undefined,
            icon: {
              url: getMarkerIcon(el),
              anchor: { x: 9, y: 9 } as google.maps.Point,
            },
            warehouseDeliveryDetails: el.warehouseDeliveryDetails,
          };
          latLngToPointsDict[key] = [...currentLatLng, toAdd];
        }
      });
    }

    let currentOrderNum = 0;
    route.ordersPositions
      .filter(el => el.meta.point)
      .forEach(el => {
        const key = el.meta.point!.lat + "_" + el.meta.point!.lng;
        const alreadyAddedOrder = latLngToPointsDict[key]?.find(
          routePoint => String(routePoint.id) === el.id,
        );

        const pointToAddIcon = (): string => {
          if (el.type === "order") {
            if (
              el.warehouseDeliveryDetails &&
              !el.warehouseDeliveryDetails.isInWarehouse &&
              el.warehouseDeliveryDetails.date !== null
            ) {
              return pinnedAwaitingMarker;
            } else {
              return fullMarker;
            }
          } else {
            return passpointMarker;
          }
        };

        if (alreadyAddedOrder) {
          alreadyAddedOrder.isPinned = true;
          return;
        }
        if (el.type === "order") {
          currentOrderNum = currentOrderNum + 1;
        }
        const toAdd: RoutePoint = {
          id: el.id,
          isPinned: true,
          type: el.type === "passpoint" ? "passpoint" : "order",
          point: el.meta.point!,
          label:
            el.type === "order"
              ? {
                  text: String(currentOrderNum),
                  color: "#fff",
                  fontSize: "15px",
                  fontWeight: "600",
                }
              : undefined,
          icon: {
            // url: el.type === "order" ? fullMarker : passpointMarker,
            url: pointToAddIcon(),
            anchor:
              el.type === "order"
                ? ({ x: 9, y: 9 } as google.maps.Point)
                : ({ x: 12, y: 12 } as google.maps.Point),
          },
          // possible error (null)?
          warehouseDeliveryDetails: el.type === "order" ? el.warehouseDeliveryDetails : null,
        };

        const currentLatLng = latLngToPointsDict[key] || [];

        latLngToPointsDict[key] = [...currentLatLng, toAdd];
      });

    return Object.values(latLngToPointsDict);
  }, [route.ordersPositions, routePoints, showOnlyRoutePoints]);

  function handleZoom() {
    if (!map.current) return;
    const zoomLevel = map.current.getZoom();
    zoom.current = zoomLevel;
    const mapSpan = map.current.getBounds();
    if (mapSpan) {
      setSpan(mapSpan.toJSON());
    }
  }

  function onLoad(instance: google.maps.Map<Element>) {
    map.current = instance;
    google.maps.event.addListener(instance, "tilesloaded", () => {
      if (spanLoaded.current === false) {
        const mapSpan = instance.getBounds();
        if (mapSpan) {
          setSpan(mapSpan.toJSON());
        }
        spanLoaded.current = true;
      }
    });
  }

  const newPolylineData = React.useMemo(() => {
    const points = route.ordersPositions.filter(el => el.meta.point).map(loc => loc.meta.point!);
    return [route.startingPoint.point].concat(points || []);
  }, [route.ordersPositions, route.startingPoint]);

  if (!route.startingPoint) {
    return (
      <div className={styles.panel}>
        <h1>Brak punktu startowego</h1>
        <span>Dodaj punkt startowy, by stworzyć trasę</span>
      </div>
    );
  }

  const handleAddPoint = (e: google.maps.MouseEvent) => {
    const point = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    assertIsDefined(editingPasspointId);
    var geocoder = new google.maps.Geocoder();

    geocoder.geocode(
      {
        //@ts-ignore
        latLng: e.latLng,
      },
      function(results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            assignOrder({
              point,
              id: editingPasspointId,
              type: "passpoint",
              address: results[0].formatted_address,
              warehouseDeliveryDetails: null,
            });
            actions.setState({ editingPasspointId: null });
          }
        }
      },
    );
  };

  const onClick = (e: google.maps.MouseEvent) => {
    if (editingPasspointId) {
      return handleAddPoint(e);
    }
    if (mapMode === "polygon") {
      return polygonController.handleAddPoint(e);
    }
    if (mapMode === "map") {
      return closeInfoWindow();
    }
  };

  return (
    <GoogleMap
      onLoad={onLoad}
      mapContainerStyle={{
        height: "100vh",
      }}
      zoom={7}
      onZoomChanged={handleZoom}
      options={{
        disableDefaultUI: true,
      }}
      center={route.startingPoint.point}
      onClick={onClick}
    >
      {isPointPolylineVisible && (
        <Polyline
          path={newPolylineData}
          options={{
            strokeColor: "#765de3",
            strokeWeight: 4,
          }}
        />
      )}

      {isGraphhopperPolylineVisible && (
        <Polyline
          path={graphhopperPolyline}
          options={{
            strokeColor: "#ff5961",
            strokeWeight: 4,
          }}
        />
      )}

      <StartingPointMarker startingPoint={route.startingPoint} />

      {allRoutePoints.map(locations => {
        if (locations.length === 1) {
          const routePoint = locations[0];
          return (
            <Marker
              key={routePoint.id}
              route={route}
              routePoint={routePoint}
              isLoading={isLoading}
            />
          );
        } else {
          return (
            <MarkerCluster
              key={locations[0].point.lat + "_" + locations[0].point.lng}
              cluster={locations}
              isLoading={isLoading}
              route={route}
              span={span}
              zoom={zoom.current}
            />
          );
        }
      })}

      {openedInfoWindowData && (
        <InfoWindow
          key={openedInfoWindowData.id}
          position={openedInfoWindowData.point}
          onCloseClick={closeInfoWindow}
        >
          <InfoWindowContent id={String(openedInfoWindowData.id)} route={route} />
        </InfoWindow>
      )}

      <DragPolygon
        path={polygonController.path}
        onDrag={polygonController.onDrag}
        onDoubleClick={polygonController.deletePoint}
        mode={mapMode}
      />
    </GoogleMap>
  );
};
