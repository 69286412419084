import { OwnerConfig } from "./types";
import { config as defaultConfig } from "./default";
import { configMerger } from "./utils";

export const config: OwnerConfig = {
  main: configMerger(defaultConfig.main, {
    orderPanel: {
      filters: {
        drawerFilters: { isConfirmedInExternalService: true },
      },
    },
  }),
  manufacturing: {
    ...defaultConfig.manufacturing,
  },
  b2b: configMerger(defaultConfig.b2b, {}),
  transport: {
    ...defaultConfig.transport,
  },
};
