import {
  ManufacturingItem as ManufacturingItemModel,
  ShelfManufacturingItem,
} from "api/new-production-plans/models";
import cx from "classnames";
import { Button } from "components/common";
import styles from "../../../../../../ProductionPlanDetails.module.css";
import localStyles from "./ManufacturingItem.module.css";
import optionsIcon from "assets/images/options.svg";
import awaitingIcon from "assets/images/awaits.svg";
import doneIcon from "assets/images/done.svg";
import inProgressIcon from "assets/images/inprogress.svg";

interface Props {
  manufacturingItem: ManufacturingItemModel | ShelfManufacturingItem;
}

const stageStatus = {
  NOT_STARTED: "nierozpoczęty",
  IN_PROGRESS: "w toku",
  FINISHED: "zakończony",
};

export const ManufacturingItem = ({ manufacturingItem }: Props) => {
  const attributesList = formatAttributes(manufacturingItem.index.attributesValues);

  const categoryCode = () => {
    if (manufacturingItem.index.category.code) {
      return <>{manufacturingItem.index.category.code}</>;
    } else if (!manufacturingItem.index.category.code && manufacturingItem.index.category.name) {
      return <>{manufacturingItem.index.category.name.slice(0, 2).toUpperCase()}</>;
    }
    return <>BK</>;
  };

  return (
    <div
      className={cx(localStyles.item, {
        [localStyles.itemStatusInProgress]: manufacturingItem.status === "IN_PROGRESS",
        [localStyles.itemStatusDone]: manufacturingItem.status === "DONE",
      })}
    >
      <div className={localStyles.itemInnerBox}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center w-100 mr-2">
            <div
              className={cx(localStyles.itemSlot, "mr-1")}
              style={{
                background: manufacturingItem.index.category.color
                  ? manufacturingItem.index.category.color
                  : "#000",
                color: "#fff",
              }}
            >
              {categoryCode()}
            </div>
            <div className={localStyles.itemNameBox}>
              <div className={localStyles.itemName}>{manufacturingItem.index.name}</div>
              <div className={localStyles.hintbox}>{manufacturingItem.index.name}</div>
            </div>
          </div>
          <div className={cx(styles.gap1, "d-flex align-items-center")}>
            {manufacturingItem.visiblePreviousStages.slice(0, 5).map(stage => {
              return (
                <div
                  className={cx(localStyles.stageStatusLabel)}
                  style={{
                    background:
                      stage.status !== "NOT_STARTED" ? stage.backgroundColor : "transparent",
                    color: stage.textColor,
                    border: stage.status === "NOT_STARTED" ? `1px solid ${stage.textColor}` : "",
                  }}
                >
                  {stage.code ? (
                    <span>{stage.code}</span>
                  ) : (
                    <span>{stage.name.slice(0, 2).toUpperCase()}</span>
                  )}
                  {stage.status === "NOT_STARTED" && <img alt="nierozpoczęty" src={awaitingIcon} />}
                  {stage.status === "IN_PROGRESS" && <img alt="w toku" src={inProgressIcon} />}
                  {stage.status === "FINISHED" && <img alt="zakończony" src={doneIcon} />}
                  <div className={localStyles.hintbox}>
                    {stage.name}, {stageStatus[stage.status]}
                  </div>
                </div>
              );
            })}
            {manufacturingItem.visiblePreviousStages.length > 5 && (
              <div className={localStyles.moreStagesCounter}>
                +{manufacturingItem.visiblePreviousStages.length - 5}
              </div>
            )}
            <div className={localStyles.deliveryGroup}>
              {manufacturingItem.source.backgroundColor ? (
                <span
                  className={cx(localStyles.color)}
                  style={{ background: `${manufacturingItem.source.backgroundColor}` }}
                ></span>
              ) : (
                <span className={cx(localStyles.color, localStyles.bgViolet)}></span>
              )}
              <div className={localStyles.sourceName}>{manufacturingItem.source.signature}</div>
              <div className={localStyles.hintbox}>{manufacturingItem.source.signature}</div>
            </div>
            <div
              className={cx(localStyles.priorityPlanLabel, {
                [localStyles.priorityPlanA]: manufacturingItem.priority === "A",
                [localStyles.priorityPlanB]: manufacturingItem.priority === "B",
                [localStyles.priorityPlanC]: manufacturingItem.priority === "C",
              })}
            >
              {manufacturingItem.priority}
            </div>
          </div>
        </div>
        <div
          className={cx(localStyles.attributesContainer, styles.gap1, "d-flex align-items-center")}
        >
          {attributesList.map(attribute => (
            <div key={attribute.id} className={localStyles.attribute}>
              <span>{attribute.name}: </span>
              <strong>{attribute.value}</strong>
            </div>
          ))}
          {attributesList.length > 4 && (
            <div className={styles.moreDataCounter}>+{attributesList.length - 4}</div>
          )}
        </div>
      </div>
      <Button
        kind="secondary"
        size="round-s"
        onClick={() => {}}
        className={cx(styles.button, styles.buttonRound, styles.buttonBackgroundTransparent)}
      >
        <img alt="" src={optionsIcon} />
      </Button>
    </div>
  );
};

function formatAttributes(
  attributes: string,
): { name: string; value: string; id: number | string }[] {
  const stringAttributes = attributes.split(";");
  return stringAttributes.map(el => {
    const [name, value] = el.split(":");
    return { name, value, id: name + value };
  });
}
