import { Sales } from "api/milo-settings/models";
import { DeliveryMethodType, Order, OrderPaymentType } from "api/orders/models";

export const PRODUCT_TYPES = [
  { name: "skrzyniowe", id: "box" },
  { name: "tapicerowane", id: "upholstery" },
  { name: "inne", id: "other" },
  { name: "zestawy", id: "set" },
];

export type Channels =
  | "allegro"
  | "amazon"
  | "arena"
  | "eBay"
  | "magento"
  | "presta"
  | "b2b"
  | "shoper"
  | "online-store"
  | "contractor"
  | "virtuemart"
  | "wayfair"
  | "baselinker"
  | "kaufland"
  | "erli"
  | "idosell"
  | "shopify"
  | "hood"
  | "inne";

export const availablePaymentTypesDict: Record<OrderPaymentType, string> = {
  ONLINE: "Przelew",
  CASH_ON_DELIVERY: "Gotówka",
  DEBIT_CARD: "Karta",
  INSTALMENT: "Raty",
};

export const paymentStatusDict: Record<Order["payment"]["status"], string> = {
  PAID: "Opłacono",
  OVERPAID: "Przepłacono",
  PARTIALLY_PAID: "Opłacono częściowo",
  UNPAID: "Nie opłacono",
};

export type StatusKind = "ORDER" | "ORDER_GROUP" | "ROUTE";

export type StatusType =
  | "CANCELED"
  | "CONFIRMED"
  | "DELIVERED"
  | "DEPARTED"
  | "FINISHED"
  | "GROUP_READY"
  | "NEW"
  | "ON_ROUTE"
  | "PROCESSING"
  | "RECEIVED"
  | "RETURNED"
  | "ROUTE_READY"
  | "SETTLED";

export const statusTypeDict: Record<StatusType, string> = {
  CANCELED: "Anulowane",
  CONFIRMED: "Potwierdzone",
  DELIVERED: "Odebrane",
  DEPARTED: "W drodze",
  FINISHED: "Zakończone",
  GROUP_READY: "Grupa gotowa",
  NEW: "Nowe",
  ON_ROUTE: "Na trasie",
  PROCESSING: "W trakcie przetwarzania",
  RECEIVED: "Otrzymane",
  RETURNED: "Zwrot do nadawcy",
  ROUTE_READY: "Gotowe do wysyłki",
  SETTLED: "Rozliczone",
};

export const statusKindDict: Record<StatusKind, string> = {
  ORDER: "Zamówienie",
  ORDER_GROUP: "Grupa zamówień",
  ROUTE: "Trasa",
};

export const CHANNELS_B2B = [{ id: "baselinker", name: "BaseLinker" }];

export const CHANNELS = [
  { id: "allegro", name: "Allegro" },
  { id: "amazon", name: "Amazon" },
  { id: "arena", name: "Arena" },
  { id: "eBay", name: "eBay" },
  { id: "magento", name: "Magento" },
  { id: "presta", name: "Presta" },
  { id: "b2b", name: "B2B" },
  { id: "shoper", name: "Shoper" },
  { id: "shopify", name: "Shopify" },
  { id: "kaufland", name: "Kaufland" },
  { id: "hood", name: "Hood" },
  { id: "erli", name: "Erli" },
  { id: "wayfair", name: "Wayfair" },
  { id: "inne", name: "Inne" },
];

export type ColorPaletteBackground = keyof typeof colorPalette;
export type ColorPaletteTextColor = typeof colorPalette[ColorPaletteBackground];

export const colorPalette = {
  "#fdb4c0": "#333",
  "#ffc0e5": "#333",
  "#fdf1bb": "#333",
  "#f9fdbb": "#333",
  "#f3fdbb": "#333",
  "#defdbb": "#333",
  "#d0fdbb": "#333",
  "#c3fdbb": "#333",
  "#bbfdc9": "#333",
  "#bbfdde": "#333",
  "#bbfdf4": "#333",
  "#bbfdfd": "#333",
  "#bbeefd": "#333",
  "#bbdffd": "#333",
  "#bbd8fd": "#333",
  "#bbd0fd": "#333",
  "#c5bbfd": "#333",
  "#d4bbfd": "#333",
  "#e8bbfd": "#333",
  "#ffcf9d": "#333",
  "#ff867d": "#fff",
  "#e37c8c": "#333",
  "#d97ab6": "#333",
  "#cfba5e": "#333",
  "#dfd458": "#333",
  "#c4d149": "#333",
  "#b0d776": "#333",
  "#8fd16b": "#333",
  "#8be382": "#333",
  "#6de5ad": "#333",
  "#7ecfaf": "#333",
  "#65d7cc": "#333",
  "#5cc2c2": "#333",
  "#60c4df": "#333",
  "#75a9d7": "#333",
  "#4d80c4": "#fff",
  "#5a3fb5": "#fff",
  "#8577d1": "#fff",
  "#aa4cc8": "#fff",
  "#c36bc4": "#fff",
  "#e59b5c": "#333",
  "#e5615c": "#333",
  "#be364b": "#fff",
  "#cf3798": "#fff",
  "#dfc13c": "#333",
  "#f1df13": "#333",
  "#d1e328": "#333",
  "#9ee337": "#333",
  "#71dd36": "#333",
  "#40d331": "#333",
  "#22c278": "#333",
  "#28ddcc": "#333",
  "#0ec8c0": "#fff",
  "#1c9f9f": "#fff",
  "#33879d": "#fff",
  "#2b8fe9": "#fff",
  "#187bff": "#fff",
  "#455fc0": "#fff",
  "#de6ff1": "#333",
  "#ff00e0": "#333",
  "#e95d88": "#fff",
  "#f1862d": "#333",
  "#e76640": "#333",
  "#98aeba": "#333",
  "#eee": "#333",
  "#999": "#333",
  "#666": "#fff",
  "#333": "#fff",
  "#111": "#fff",
  "#2bc4e0": "#333",
} as const;

export const FLAVOR = (() => {
  const flavor = process.env.REACT_APP_FLAVOR;
  if (flavor !== "main" && flavor !== "b2b" && flavor !== "manufacturing") {
    throw new Error("Provided flavor is not valid! It should be one of [main, b2b, manufacturing]");
  }
  return flavor as "main" | "b2b" | "manufacturing";
})();

export const smsMessageRouteTags = [
  { name: "{nr kierowcy}", value: "numer telefonu do kierowcy" },
  { name: "{data dostawy}", value: "przewidywany czas dostawy" },
  { name: "{min godz dostawy}", value: "minimalna godzina dostawy" },
  { name: "{max godz dostawy}", value: "maksymalna godzina dostawy" },
  { name: "{link}", value: "link do śledzenia zamówienia" },
  { name: "{nazwa kontrahenta}", value: "nazwa kontrahenta" },
  { name: "{imię klienta}", value: "imię klienta" },
  { name: "{kwota pobrania}", value: "kwota pobrania" },
  { name: "{relatywny limit potwierdzenia}", value: "relatywny limit potwierdzenia" },
  { name: "{absolutny limit potwierdzenia}", value: "absolutny limit potwierdzenia" },
];

export const upholsteriesEmployeesKind = {
  UPHOLSTERER: {
    name: "Tapicer",
    color: "rgb(254, 158, 118)",
  },
  CUTTER: {
    name: "Krojczy",
    color: "#D4CD03",
  },

  SEAMSTRESS: {
    name: "Krawiec",
    color: "#01c9d5",
  },
};

export const salesAccountChannels = {
  allegro: {
    name: "Allegro",
    color: "#ff5a00",
  },
  amazon: {
    name: "Amazon",
    color: "#f08804",
  },
  arena: {
    name: "Arena",
    color: "#1A171C",
  },
  b2b: {
    name: "b2b",
    color: "#ff5a00",
  },
  eBay: {
    name: "eBay",
    color: "#3665f3",
  },
  erli: {
    name: "Erli",
    color: "#3997bb",
  },
  hood: {
    name: "Hood",
    color: "#11a623",
  },
  kaufland: {
    name: "Kaufland",
    color: "#e10915",
  },
  magento: {
    name: "Magento",
    color: "#b3d4fc",
  },
  moebel24: {
    name: "Moebel24",
    color: "#d14500",
  },
  presta: {
    name: "Presta",
    color: "#881b54",
  },
  shoper: {
    name: "Shoper",
    color: "#007aff",
  },
  shopify: {
    name: "Shopify",
    color: "#95bf47",
  },
  wayfair: {
    name: "Wayfair",
    color: "#7f187f",
  },
};

export const COUNTRIES = [
  { name: "Polska" },
  { name: "Niemcy" },
  { name: "Holandia" },
  { name: "Francja" },
  { name: "Belgia" },
  { name: "Rosja" },
  { name: "Białoruś" },
  { name: "Litwa" },
  { name: "Ukraina" },
  { name: "Węgry" },
  { name: "Słowacja" },
  { name: "Czechy" },
  { name: "Austria" },
  { name: "Rumunia" },
  { name: "Wielka Brytania" },
  { name: "Hiszpania" },
  { name: "Irlandia" },
  { name: "Łotwa" },
];
export const CURRENCY_DICT = {
  PLN: "zł",
  USD: "$",
  EUR: "€",
};

export type CountryCodes = "PL" | "DE" | "EN" | "CZ" | "SK" | "FR" | "RO";

export const countryCodes: CountryCodes[] = ["DE", "PL", "EN", "CZ", "SK", "FR", "RO"];

export type CURRENCY_TYPE = "PLN" | "EUR" | "GBP" | "HUF" | "DKK" | "CZK";

export const ORDER_WARRANTY_STATUS = [
  { id: "WITH_WARRANTY", name: "Na gwarancji" },
  { id: "WITHOUT_WARRANTY", name: "Po gwarancji" },
];

export const ORDER_KIND = [
  { id: "REPAIR", name: "Naprawa" },
  { id: "SUPPLEMENT", name: "Uzupełnienie" },
  { id: "REPLACEMENT", name: "Wymiana" },
  { id: "RESIGNATION", name: "Rezygnacja" },
];
export const CURRENCY = [
  { id: "PLN", name: "PLN" },
  { id: "EUR", name: "EUR" },
  { id: "GBP", name: "GBP" },
  { id: "HUF", name: "HUF" },
  { id: "DKK", name: "DKK" },
  { id: "CZK", name: "CZK" },
];

export const SHIPMENT_LABEL_TYPES = (() => {
  enum ShipmentLabels {
    waybill = "waybill",
    blpLabel = "blp_label",
    blpLabelInPdfFormat = "blp_label_in_pdf_format",
    blpLabelInZebraFormat = "blp_label_in_zebra_format",
  }
  return [
    { id: ShipmentLabels.waybill, name: "List przewozowy" },
    { id: ShipmentLabels.blpLabel, name: "Etykieta BLP" },
    { id: ShipmentLabels.blpLabelInPdfFormat, name: "Etykieta BLP w formacie PDF" },
    {
      id: ShipmentLabels.blpLabelInZebraFormat,
      name: "Etykieta BLP w formacie Zebra",
    },
  ];
})();

export const SHIPMENT_PACKAGE_TYPES = (() => {
  enum Names {
    package = "package",
    envelope = "envelope",
    pallet = "pallet",
  }
  return {
    list: [
      { id: Names.package, name: "paczka" },
      { id: Names.envelope, name: "koperta" },
      { id: Names.pallet, name: "paleta" },
    ],
    enum: { [Names.package]: "paczka", [Names.envelope]: "koperta", [Names.pallet]: "paleta" },
  };
})();

export const SHIPMENT_PAYMENT_TYPES = (() => {
  enum Names {
    receiver = "receiver",
    shipper = "shipper",
  }
  return {
    list: [
      { id: Names.receiver, name: "odbiorca" },
      { id: Names.shipper, name: "nadawca" },
    ],
    enum: { [Names.receiver]: "odbiorca", [Names.shipper]: "nadawca" },
  };
})();

export const GOOGLE_MAPS_API_KEY =
  process.env.NODE_ENV === "development"
    ? "AIzaSyBONyztKzJ-UVICkvo8BtaoW-eAqnhnSQA"
    : "AIzaSyC3U3geO4uRnaiSNgvFZvlIstURkm8Myew";

export const GOOGLE_MAPS_STATIC_KEY =
  process.env.NODE_ENV === "development"
    ? "AIzaSyBONyztKzJ-UVICkvo8BtaoW-eAqnhnSQA"
    : "AIzaSyC3U3geO4uRnaiSNgvFZvlIstURkm8Myew";

export const GOOGLE_MAPS_GEOCODE_KEY =
  process.env.NODE_ENV === "development"
    ? "AIzaSyBONyztKzJ-UVICkvo8BtaoW-eAqnhnSQA"
    : "AIzaSyC3U3geO4uRnaiSNgvFZvlIstURkm8Myew";

export const GRAPHHOPPER_KEY = "4fd347ab-686f-45b7-b367-4440d49a37a2";

export const POLAND_CENTER = {
  lat: 52.2249801,
  lng: 19.0526493,
};

// this number is based on response time, if we add more timeout might occur
export const MAX_NUMBER_OF_ORDERS_TO_MULTIPLE_ADD = 50;

export const deliveryMethodDict: Record<string, { id: DeliveryMethodType; name: string }> = {
  external: { id: "external-shipment", name: "transport zewnętrzny" },
  own: {
    id: "self-shipped",
    name: FLAVOR === "main" ? "transport własny" : "transport producenta",
  },
  personalCollection: { id: "personal-collection", name: "odbiór osobisty" },
};

export const paymentTypeDict: Record<keyof Sales["paymentType"], OrderPaymentType> = {
  cashOnDelivery: "CASH_ON_DELIVERY",
  debitCard: "DEBIT_CARD",
  installment: "INSTALMENT",
  online: "ONLINE",
};

export const deliveryMethodEnum = {
  "self-shipped": "Własny",
  "external-shipment": "Zewnętrzny",
  "personal-collection": "Odbiór osobisty",
};

/**
 * Color naming tool: https://www.color-name.com/
 */
export const COLORS = (() => {
  const doc = getComputedStyle(document.documentElement);
  return {
    brightTurquoise35: doc.getPropertyValue("--color-bright-turquoise-35"),
    black20: doc.getPropertyValue("--color-black-20"),
    black35: doc.getPropertyValue("--color-black-35"),
    violetsAreBlue: doc.getPropertyValue("--color-violets-are-blue"),
    slateBlue: doc.getPropertyValue("--color-slate-blue"),
    spanishGrey: doc.getPropertyValue("--color-spanish-grey"),
    silverChalice: doc.getPropertyValue("--color-silver-chalice"),
  };
})();
