import { createRef, useState, useCallback, useRef, useMemo } from "react";
import { CommonError } from "components/utils";
import { GoogleGeocodeResult, useGeocodedAddress } from "hooks";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { POLAND_CENTER } from "CONSTANTS";
import { Button } from "components/common/buttonLegacy";
import { Delivery } from "api/orders/models";
import routeMarkerImg from "assets/images/mapMarkers/p29.png";
import styles from "./OrderConfirmationMap.module.css";
import cx from "classnames";

interface Props {
  delivery: Pick<Delivery, "city" | "postCode" | "street"> | undefined;
  submit: (values: {
    address: GoogleGeocodeResult | null;
    coords: {
      lat: number;
      lng: number;
    };
  }) => Promise<void>;
}

type MapSettings = {
  zoom: number;
  center: { lat: number; lng: number };
};

export const DeliveryConfirmModal = ({ delivery, submit }: Props) => {
  const [inProgress, setInProgress] = useState(false);
  const [ownCoords, setOwnCoords] = useState<null | {
    lat: number;
    lng: number;
  }>(null);
  const [autoCoords, setAutoCoords] = useState<null | {
    lat: number;
    lng: number;
  }>(null);
  const [mapSettings, setMapSettings] = useState<MapSettings>({
    zoom: 6,
    center: POLAND_CENTER,
  });

  const map = useRef<any>(createRef());

  const addressToSearch = useMemo(() => {
    if (!delivery) return { address: "" };
    return {
      address: `${delivery.street} ${delivery.postCode} ${delivery.city}`,
    };
  }, [delivery]);

  const coordsToSearch = useMemo(
    () => (ownCoords ? { latLng: ownCoords } : { latLng: undefined }),
    [ownCoords],
  );

  const [addressByString] = useGeocodedAddress(addressToSearch, payload => {
    if (payload.status === "OK") {
      const location = payload.result.geometry.location;
      const coords = { lat: location.lat(), lng: location.lng() };
      setAutoCoords(coords);
      setMapSettings({ zoom: 14, center: coords });
    }
  });

  const [addressByCoords] = useGeocodedAddress(coordsToSearch);

  const coords = ownCoords || autoCoords;
  const address = addressByCoords || addressByString;

  function handleZoom() {
    if (!map.current.map) return;
    const zoomLevel = map.current.map.getZoom();
    setMapSettings(s => ({ ...s, zoom: zoomLevel }));
  }

  const handleSubmit = async () => {
    if (!coords) {
      return alert("Wybierz punkt!");
    }
    setInProgress(true);

    await submit({ address, coords });

    setInProgress(false);
  };

  const handleMapClick = useCallback((mapEvent: any) => {
    setOwnCoords({
      lat: mapEvent.latLng.lat(),
      lng: mapEvent.latLng.lng(),
    });
  }, []);

  const clearCoords = useCallback(() => {
    setOwnCoords(null);
  }, []);

  if (!delivery) {
    return <CommonError text="Nie ma takiego zamówienia!" status="front" />;
  }

  return (
    <div>
      {/* {error && <ErrorMessage type="text" text={getErrorText(error)} />} */}
      <div className="d-flex mb-4 align-items-center justify-content-between">
        <div>
          <div className="mr-4">
            <h5 className={styles.title}>
              Nie jesteśmy pewni, czy lokalizacja adresu jest poprawna. Pomóż nam w jego
              znalezieniu!
            </h5>
            <p className={styles.instruction}>
              Jeśli adres w zielonej ramce się zgadza <strong>Potwierdź adres</strong>. Jeśli nie,
              ustaw znacznik punktu we właściwym miejscu na mapie i wtedy{" "}
              <strong>Potwierdź adres</strong>.
            </p>
          </div>

          <div className="d-flex align-items-center">
            <div className="d-block">
              <div className="p-2">
                <div className={styles.label}>Adres z zamówienia</div>
                <div>
                  ul. {delivery.street} {delivery.postCode} {delivery.city}
                </div>
              </div>
              <div className={cx("p-2", styles.localizedAddress)}>
                <div className={styles.label}>Znaleziony adres</div>
                <div>{address ? address.formatted_address : "---"}</div>
              </div>
            </div>
            <Button
              kind="primary"
              className={cx("d-block ml-auto mb-2", styles.bgGreen)}
              disabled={inProgress}
              onClick={handleSubmit}
            >
              Potwierdź adres
            </Button>
          </div>
        </div>
      </div>
      <div className="flex-grow-1">
        <div className="position-relative">
          <GoogleMap
            ref={map.current}
            mapContainerStyle={{
              height: "calc(100vh - 415px)",
              width: "100%",
            }}
            onClick={handleMapClick}
            zoom={mapSettings.zoom}
            onZoomChanged={handleZoom}
            options={{
              mapTypeControl: false,
              streetViewControl: false,
              fullscreenControl: false,
              draggableCursor: "default",
            }}
            center={mapSettings.center}
          >
            {coords && (
              <Marker position={coords} onClick={clearCoords} icon={routeMarkerImg}></Marker>
            )}
          </GoogleMap>
        </div>
      </div>
    </div>
  );
};
