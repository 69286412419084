import { useProductionPlan } from "api/new-production-plans/hooks";
import { CommonError, MockupLoader } from "components/utils";
import { RouteComponentProps } from "react-router-dom";
import { Header } from "./subcomponents/header/Header";
import { LeftToolbar } from "./subcomponents/leftToolbar/LeftToolbar";
import styles from "./ProductionPlanDetails.module.css";
import { DateBar } from "./subcomponents/dateBar/DateBar";
import { EmployeeRow } from "./subcomponents/employeeRow/EmployeeRow";
import { Shelf } from "./shelf/Shelf";

interface Props {
  routeComponentsProps: RouteComponentProps<{ id: string }>;
}

export const ProductionPlanDetails = ({ routeComponentsProps: { match } }: Props) => {
  const { data: productionPlan, isLoading, error } = useProductionPlan(match.params.id);

  if (error) {
    <CommonError status={error._httpStatus_} />;
  }

  if (isLoading) {
    return <MockupLoader type="smallList" />;
  }

  if (!productionPlan) {
    return null;
  }

  return (
    <div className="position-relative">
      <Header productionPlan={productionPlan} />
      <div className={styles.contentWrapper}>
        <LeftToolbar />
        <div className={styles.scrollXWrapper}>
          <div className={styles.contentGroup}>
            <div className={styles.cols}>
              <DateBar days={productionPlan.days} />
              <div className={styles.scrollYWrapper}>
                {productionPlan.employees.map(employee => (
                  <EmployeeRow key={employee.id} days={productionPlan.days} employee={employee} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Shelf productionPlan={productionPlan} />
    </div>
  );
};
