import { useRef } from "react";
import * as React from "react";
import { queryString } from "utilities";
import { ApiMiddlewareResult } from "apiConnectors/fetchConnector";
import { Pagination } from "api/types";
import cuid from "cuid";
import { ApiFetcher } from "hooks/createApiQuery";

type Action<P> = { type: "success"; payload: P[] } | { type: "updateSearch"; payload: string };
type State<P> = {
  results: P[];
  search: string;
};
interface Props<FF, P> {
  fetchFrom: FF;
  children: any;
  transform?: (arg: P) => any;
  transformQuery?: (query: Record<string, string>) => Record<string, string>;
  debounce?: number;
}

export const context = React.createContext<{
  items: any[];
  updateSearch: (payload: any) => void;
  async: boolean;
}>({
  updateSearch: (payload: any) => {},
  items: [],
  async: false,
});

function noop<Arg>(arg: Arg) {
  return arg;
}

function createReducer<P>() {
  return function reducer(state: State<P>, action: Action<P>) {
    switch (action.type) {
      case "success": {
        return {
          ...state,
          results: action.payload,
        };
      }
      case "updateSearch": {
        return {
          ...state,
          search: action.payload,
        };
      }
      default:
        return state;
    }
  };
}

/**
 * usage:
 * @example
 * <AutocompleteAsyncHandler fetchFrom={getMarkets}>
 *     <Autocomplete
 *       onChange={values => void}
 *       transform={el => ({...el, name:el.something.stringToDisplay})} // optional
 *       initialItems={[{...}]} // optional
 *     />
 *   </AutocompleteAsyncHandler>
 */
export function AutocompleteAsyncHandler<
  P,
  FF extends (
    arg: string,
    abortToken?: string,
  ) => ApiFetcher<Pagination<P>> | ApiMiddlewareResult<Pagination<P>>
>({ children, fetchFrom, transform = noop, transformQuery = noop, debounce = 200 }: Props<FF, P>) {
  const transformQueryRef = useRef(transformQuery);
  React.useEffect(() => {
    transformQueryRef.current = transformQuery;
  });
  const [state, dispatch] = React.useReducer(createReducer<P>(), {
    results: [],
    search: "",
  });
  const timeout: any = React.useRef(0);
  const mounted = React.useRef(true);
  const initialMount = React.useRef(true);

  const success = (payload: any) => dispatch({ type: "success", payload });
  const updateSearch = (payload: any) => dispatch({ type: "updateSearch", payload });
  const transformRef = useRef(transform);
  transformRef.current = transform;
  const abortToken = useRef(cuid());

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
      return;
    }
    const fetchData = async () => {
      const response = await fetchFrom(
        queryString.stringify(transformQueryRef.current({ search: state.search })),
        abortToken.current,
      );

      const payload = Array.isArray(response) ? response[0] : await response.fetcher();

      if (!mounted.current) {
        return;
      }
      if (payload) {
        success(payload.results.map(transformRef.current));
      }
    };
    clearTimeout(timeout.current);
    timeout.current = window.setTimeout(() => {
      fetchData();
    }, debounce);
  }, [state.search, fetchFrom, debounce]);

  return (
    <context.Provider value={{ items: state.results, updateSearch, async: true }}>
      {children}
    </context.Provider>
  );
}

AutocompleteAsyncHandler.defaultProps = {
  // function used to transform each element to needed shape. Used
  // mostly for adding "name" or "id" field
  children: null,
  fetchFrom: () => {
    throw new Error(`"fetchFrom" prop is required to handle async autocomplete properly`);
  },
};
