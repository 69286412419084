import filtersIcon from "assets/images/38.svg";
import commentIcon from "assets/images/45g.svg";
import styles from "./RightTopButtons.module.css";
import cx from "classnames";
import { useRouteViewState } from "../routeCreatorState";

export const RightTopButtons = () => {
  const actions = useRouteViewState("slave", state => state.actions);

  return (
    <div className={styles.container}>
      <button
        className={cx(styles.toggleButton, styles.toggleButtonMargin)}
        onClick={() => actions.toggle("isOrderCommentsDrawerOpen")}
      >
        <img src={commentIcon} alt="" />
      </button>
      <button className={styles.toggleButton} onClick={() => actions.toggle("isFiltersDrawerOpen")}>
        <img src={filtersIcon} alt="" />
      </button>
    </div>
  );
};
