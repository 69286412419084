import { graphhopper, QUERY_LIMIT } from "api/graphhopper/graphhopper";
import { OrderPositionMeta, OrderPositionTypes, Route } from "api/routes/models";
import { StatusHandlerHelpers } from "components/utils";
import { useToastr, useSelector } from "hooks";
import { useRoutePutMutation } from "hooks/apiPrimitives";
import produce from "immer";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useRouteViewState } from "../routeCreatorState";
import { getFullRouteCoords } from "../utils";
import { useInfoWindow } from "./useInfoWindow";

export const useAssignItem = (route: Route) => {
  const toastr = useToastr();
  const updateMutation = useRoutePutMutation();
  const actions = useRouteViewState("slave", state => state.actions);
  const { closeInfoWindow } = useInfoWindow();
  const breakTime = useSelector(state => state.partials.configuration.breakTime);

  const assignOrder = async (
    item: {
      point: { lng: number; lat: number };
      id: number | string;
      type: "passpoint" | "order";
      address: OrderPositionMeta["delivery"]["address"];
      warehouseDeliveryDetails: OrderPositionTypes["order"]["warehouseDeliveryDetails"];
    },
    helpers?: StatusHandlerHelpers,
  ) => {
    if (route.orders.length >= QUERY_LIMIT) {
      actions.closeLoader();
      toastr.open({
        type: "failure",
        title:
          "Nie udało się przypiąć " +
          (item.type === "order" ? "zamówienia" : "punktu przelotowego"),
        text: "Nie można przypiąć więcej punktów",
      });
      return;
    }
    const currentOrderLngLat = [item.point.lng, item.point.lat];
    const startingPointLngLat = [route.startingPoint.point.lng, route.startingPoint.point.lat];

    const points = (() => {
      if (route.orders.length > 0) {
        const lastOrder = route.orders[route.orders.length - 1];
        const lastOrderLngLat = [lastOrder.delivery.point.lng, lastOrder.delivery.point.lat];
        return getFullRouteCoords(
          route,
          [currentOrderLngLat],
          lastOrderLngLat,
          startingPointLngLat,
        );
      } else {
        return getFullRouteCoords(route, [currentOrderLngLat], startingPointLngLat);
      }
    })();

    helpers?.startFetching();
    const payload = await graphhopper.route({
      points,
      vehicle: route.vehicleType,
      includeLastPointInOptimization: route.includeLastPointInOptimization,
    });

    if (payload) {
      closeInfoWindow();
      const itemDistance = payload.points[0].distance;
      const itemTime = payload.points[0].time;
      const returnToStartingPointDistance = String(payload.points[1].distance);
      const returnToStartingPointTime = String(payload.points[1].time);

      const itemMeta: OrderPositionMeta = {
        point: item.point,
        delivery: {
          distance: itemDistance,
          plannedDeliveryTime: null,
          stopoverTime: breakTime,
          time: itemTime,
          address: item.address,
        },
      };

      const newOrdersPositions = (() => {
        if (item.type === "passpoint") {
          return produce(route.ordersPositions, draft => {
            const currPasspoint = draft.find(el => el.id === item.id);
            assertIsDefined(currPasspoint);
            currPasspoint.meta = itemMeta;
          });
        }
        return [
          ...route.ordersPositions,
          {
            id: String(item.id),
            type: "order" as const,
            duration: null,
            meta: itemMeta,
            warehouseDeliveryDetails: item.type === "order" ? item.warehouseDeliveryDetails : null,
          },
        ];
      })();

      updateMutation.mutate(
        {
          data: {
            length:
              route.length -
              Number(route.returnDistance) +
              itemDistance +
              Number(returnToStartingPointDistance),
            operation:
              item.type === "order" ? { method: "assign", orders: [Number(item.id)] } : null,
            returnToStartingPointDistance,
            returnToStartingPointTime,
            ordersPositions: newOrdersPositions,
            shouldCalculateAverageSpeed: true,
          },
          route: route.id,
        },
        {
          onSettled: () => {
            helpers?.stopFetching();
          },
        },
      );
    } else {
      actions.closeLoader();
      helpers?.stopFetching();
      toastr.open({
        type: "failure",
        title:
          "Nie udało się przypiąć " +
          (item.type === "order" ? "zamówienia" : "punktu przelotowego"),
        text: "Błąd podczas przeliczenia trasy",
      });
    }
  };
  return { assignOrder };
};
