import { OrderForRoute } from "api/orders/models";
import { Route, RouteOrder } from "api/routes/models";
import { queryString } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";

function handleObjectFilter(filter: string | undefined) {
  if (filter) {
    try {
      return JSON.parse(decodeURIComponent(filter)).id;
    } catch (err) {
      return "";
    }
  }
  return "";
}

export type GetSearchProps = {
  query: {
    [x: string]: string;
  };
};

export function getSearch({ query }: GetSearchProps) {
  return queryString.stringify({
    ...query,
    selectWithProducts: handleObjectFilter(query.selectWithProducts),
    excludeWithProducts: handleObjectFilter(query.excludeWithProducts),
  });
}

export function transformOrderForRouteToMatchRouteOrder(
  order: OrderForRoute,
): Route["orders"][number] {
  const newOrder = { ...order, comments: order.comments.length };
  return newOrder;
}

export function getDepartureFullDate(
  departureDate: string | null,
  departureTime: string | null,
): Date | null {
  if (departureDate && departureTime) {
    const [hours, minutes] = departureTime.split(":").map(Number);
    const departure = new Date(departureDate);
    departure.setHours(hours, minutes, 0);
    return departure;
  }
  return null;
}

export function getOrderToStopoverDurationDict(
  ordersPositions: Route["ordersPositions"],
): Record<string, { stopoverDuration: number }> {
  const dict: Record<string, { stopoverDuration: number }> = {};

  let tempStopoverDuration = 0;

  ordersPositions.forEach(el => {
    if (el.type === "stopover") {
      tempStopoverDuration += el.meta.delivery.time || 0;
    } else {
      dict[el.id] = { stopoverDuration: tempStopoverDuration };
      tempStopoverDuration = 0;
    }
  });

  return dict;
}

export const sortOrdersBasedOnOrderPositions = (
  ordersPositions: Route["ordersPositions"],
  orders: Route["orders"],
): Route["orders"] => {
  const ordersPositionsWithoutStopover = [...ordersPositions].filter(el => el.type === "order");
  const sortedOrders: Route["orders"] = [];
  const orderDict: Record<string, RouteOrder> = orders.reduce((acc, el) => {
    Object.assign(acc, { [el.id]: el });
    return acc;
  }, {});

  ordersPositionsWithoutStopover.forEach(ordersPositionItem => {
    const currentOrder = orderDict[ordersPositionItem.id];
    assertIsDefined(currentOrder);
    sortedOrders.push(currentOrder);
  });

  return sortedOrders;
};

export const getFullRouteCoords = (
  route: Route,
  points: number[][],
  startingPoint: number[],
  endingPoint?: number[],
): number[][] => {
  if (route.includeLastPointInOptimization) {
    return [startingPoint, ...points, endingPoint || startingPoint];
  }

  if (!points.length) {
    return [startingPoint, endingPoint || startingPoint];
  }

  return [startingPoint, ...points];
};

export const getPoints = (ordersPositions: Route["ordersPositions"]) =>
  ordersPositions.filter(el => el.meta.point).map(({ meta }) => [meta.point!.lng, meta.point!.lat]);

export const getOrdersPositionsBasedOnGraphhopper = (
  ordersPositions: Route["ordersPositions"],
  points: { distance: number; time: number }[],
) => {
  const payloadPointsClone = [...points];
  return ordersPositions.map(el => {
    if (el.meta.point && el.type !== "stopover") {
      const currPoint = payloadPointsClone.shift();
      assertIsDefined(currPoint);
      return {
        ...el,
        meta: {
          ...el.meta,
          delivery: {
            ...el.meta.delivery,
            time: currPoint.time,
            distance: currPoint.distance,
          },
        },
      };
    }
    return el;
  });
};
