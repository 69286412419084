import styles from "../../../ProductionPlanDetails.module.css";
import shelfStyles from "../../Shelf.module.css";
import localStyles from "./FiltersBar.module.css";
import cx from "classnames";
import netScheduleIcon from "assets/images/netSchedule.svg";
import printPdfIcon from "assets/images/162.svg";
import { Button } from "components/common";
import { RadioLabels } from "components/utils";
import { Filters } from "../../Shelf";
import { SearchField } from "components/utils/searchField/SearchField";

interface Props {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
}

const groupByOptions: { id: Filters["groupBy"]; name: string }[] = [
  { id: "collection", name: "trasa/grupa" },
  { id: "departureDate", name: "data wyjazdu" },
  { id: "productCategories", name: "kategoria produktu" },
];

export const FiltersBar = ({ filters, setFilters }: Props) => {
  return (
    <div className={cx(shelfStyles.shelfHeader, "justify-content-between px-3 py-1")}>
      <div className="d-flex align-items-center">
        <SearchField
          value={filters.search}
          onUpdate={value => {
            setFilters(filters => {
              return { ...filters, search: value };
            });
          }}
        />
        <span className={cx(localStyles.filtersLabel, "pt-1 mr-2 ml-3")}>sortuj wg:</span>
        <RadioLabels
          label=""
          name="groupBy"
          allowUncheck={false}
          value={filters.groupBy}
          items={groupByOptions}
          onChange={({ value }) =>
            setFilters(filters => {
              return { ...filters, groupBy: (value as unknown) as Filters["groupBy"] };
            })
          }
        />
        <span className={shelfStyles.separator} />
      </div>
      <div className="d-flex align-items-center justify-content-end">
        <button className={cx(styles.button, styles.buttonBackgroundPrimary)}>
          <img alt="" src={netScheduleIcon} className={localStyles.X} />
          <span className="pt-1">Przypisz automatycznie do tapicerów</span>
        </button>
        <span className={shelfStyles.separator} />
        <Button kind="secondary" size="round-s">
          <img alt="Pobierz PDF" src={printPdfIcon} />
        </Button>
      </div>
    </div>
  );
};
