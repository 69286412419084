import { useMemo, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Assign } from "utility-types";
import { Props } from "./types";

type OwnProps = Assign<RouteComponentProps, { match: RouteComponentProps["match"] | null }>;

/**
 * assign custom property to match.
 * converts `/something/2/boo` => `/something/2`.
 * how to use: match.pop().pop();
 * how to use: match.pop(2);
 */
const pop = (match: OwnProps["match"], count = 1) => {
  if (!match) {
    return match;
  }
  const splittedUrl = match.url.split("/").filter(Boolean);
  const splittedPath = match.path.split("/").filter(Boolean);
  for (let i = count; i > 0; i--) {
    splittedUrl.pop();
    splittedPath.pop();
  }
  const res = {
    ...match,
    path: "/" + splittedPath.join("/"),
    url: "/" + splittedUrl.join("/"),
  };

  const enhancedRes = { ...res, pop: (count?: number) => pop(res, count) };
  return enhancedRes;
};

export const RouteEnhancer = ({
  component: Component,
  exact,
  path,
  render,
  match,
  title,
  history,
  ...otherProps
}: Props & OwnProps) => {
  /**
   * enhance native "match" with a "pop" method
   */
  const modifiedMatch = useMemo(
    () => (match ? { ...match, pop: (count?: number) => pop(match, count) } : null),
    [match],
  );

  /**
   * Update document title on route change
   */
  useEffect(() => {
    if (title && match) {
      document.title = title;
    }
  }, [title, match]);

  if (modifiedMatch === null) {
    return null;
  }
  if (Component) {
    return <Component {...otherProps} match={modifiedMatch} history={history} />;
  }
  if (render) {
    return render({ ...otherProps, match: modifiedMatch, history: history });
  }
  return null;
};
