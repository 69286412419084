import apiFetch, { tokenRefreshMiddleware } from "apiConnectors/fetchConnector";

import { SalesAccount } from "./models";
import { Pagination } from "../types";
import { queryFetch } from "apiConnectors/queryFetch";

const api = tokenRefreshMiddleware(apiFetch);

export const getSalesAccounts = (search: string = "") =>
  api<Pagination<SalesAccount>>({
    method: "GET",
    url: "/sales-accounts/items" + search,
  });

export const getSalesAccount = (id: string | number) =>
  api<SalesAccount>({
    method: "GET",
    url: "/sales-accounts/items/" + id,
  });

export const postSalesAccount = (data: Omit<SalesAccount, "id">) =>
  api<SalesAccount>({
    method: "POST",
    url: "/sales-accounts/items",
    data,
  });

export const patchSalesAccount = (id: number | string, data: Partial<SalesAccount>) =>
  api<SalesAccount>({
    method: "PATCH",
    url: "/sales-accounts/items/" + id,
    data,
  });

export const patchSalesAccountB2bQuery = (id: number | string, data: Partial<SalesAccount>) =>
  queryFetch<SalesAccount>({
    method: "PATCH",
    url: "/sales-accounts/b2b/items/" + id,
    data,
  });
export const patchSalesAccountQuery = (id: number | string, data: Partial<SalesAccount>) =>
  queryFetch<SalesAccount>({
    method: "PATCH",
    url: "/sales-accounts/items/" + id,
    data,
  });

export const deleteSalesAccount = (id: number) =>
  api<void>({
    method: "DELETE",
    url: "/sales-accounts/items/" + id,
  });

export const triggerDownloadOffersFromAllegro = (id: number) =>
  api<void>({
    method: "GET",
    url: `/allegro/${id}/add`,
  });

export const triggerDownloadOffersFromAmazon = () =>
  api<void>({
    method: "GET",
    url: "/amazon/get-offers",
  });

export const triggerDownloadOffersFromEbay = () =>
  api<void>({
    method: "GET",
    url: "/ebay/get-offers",
  });

export const getSalesAccountsB2b = (search: string = "") =>
  api<Pagination<SalesAccount>>({
    method: "GET",
    url: "/sales-accounts/b2b/items" + search,
  });

export const getSalesAccountB2b = (id: string | number) =>
  api<SalesAccount>({
    method: "GET",
    url: "/sales-accounts/b2b/items/" + id,
  });

export const postSalesAccountB2b = (data: Omit<SalesAccount, "id">) =>
  api<SalesAccount>({
    method: "POST",
    url: "/sales-accounts/b2b/items",
    data,
  });

export const patchSalesAccountB2b = (id: number | string, data: Partial<SalesAccount>) =>
  api<SalesAccount>({
    method: "PATCH",
    url: "/sales-accounts/b2b/items/" + id,
    data,
  });

export const deleteSalesAccountB2b = (id: number) =>
  api<void>({
    method: "DELETE",
    url: "/sales-accounts/b2b/items/" + id,
  });
