import { getProductsLight } from "api/products/calls";
import { ProductLight } from "api/products/models";
import closeImg from "assets/images/10.svg";
import cx from "classnames";
import { Button } from "components/common";
import { SearchMultipleFilter } from "components/common/filtersDrawer/SearchMultipleFilter";
import {
  Autocomplete,
  AutocompleteAsyncHandler,
  Checkbox,
  RadioGroup,
  RangeInput,
  Select,
} from "components/utils";
import { DatePicker } from "components/utils/datePicker";
import { TimePicker } from "components/utils/timePicker";
import { useQuery, useSelector } from "hooks";
import { useMemo, useState } from "react";
import { dateFns, tuplify } from "utilities";
import { useCheckboxFilter } from "../hooks/useCheckboxFilter";
import { useRouteViewState } from "../routeCreatorState";
import styles from "./FiltersDrawer.module.css";

const rangeInputDomain = tuplify(0, 5000);

export const FiltersDrawer = () => {
  const { updateQuery, query, setQuery } = useQuery();
  const { isChecked, updateCheckbox } = useCheckboxFilter("productsKind");
  const [inputValue, setInputValue] = useState("");

  const hasSmsService = useSelector(store => store.partials.configuration.hasSmsService);
  const salesAccounts = useSelector(store => store.partials.salesAccounts);
  const customers = useSelector(store => store.partials.customers);
  const manufacturers = useSelector(store => store.partials.manufacturers);
  const isFiltersDrawerOpen = useRouteViewState("slave", state => state.isFiltersDrawerOpen);
  const actions = useRouteViewState("slave", state => state.actions);

  const availableCategoriesList = useSelector(
    state => state.partials.configuration.productCategoriesToCount,
  );

  const filteredCategories = useMemo(
    () =>
      availableCategoriesList
        .filter(element => {
          return element.name.toLowerCase().includes(inputValue.toLowerCase());
        })
        .map(el => ({
          label: el.name,
          value: String(el.id),
        })),
    [inputValue, availableCategoriesList],
  );

  const updateWeightRange = (range: number[]) => {
    let [minWeight, maxWeight] = range;

    updateQuery({
      totalWeight_min: minWeight,
      totalWeight_max: maxWeight === 5000 ? "" : maxWeight,
    });
  };

  return (
    <div className={cx(styles["map-drawer"], { [styles["closed"]]: !isFiltersDrawerOpen })}>
      <div className="d-flex align-items-center justify-content-between">
        <Button
          kind="secondary-stroke"
          size="small"
          className={styles.clearButton}
          onClick={() => setQuery({})}
        >
          Wyczyść filtry
        </Button>
        <button
          className={styles.toggleButton}
          onClick={() => actions.toggle("isFiltersDrawerOpen")}
        >
          <img src={closeImg} alt="" />
        </button>
      </div>

      <div className={styles["drawer_inner-wrapper"]}>
        <div className={styles["drawer_content"]}>
          <div className={styles["drawer_sections"]}>
            <div className={styles["drawer_section"]}>
              <div className="w-100">
                <span className={styles.inputLabel}>Pokaż zamówienia utworzone przed</span>
                <div>
                  <DatePicker
                    className={cx("d-inline-flex mr-2", styles.input)}
                    value={query.beforeCreatedDate}
                    onChange={date => {
                      if (!date) {
                        updateQuery({ beforeCreatedDate: "" });
                      } else {
                        updateQuery({
                          beforeCreatedDate: dateFns.format(new Date(date), "yyyy-MM-dd"),
                        });
                      }
                    }}
                  />
                </div>
              </div>

              <div className="w-100">
                <span className={styles.inputLabel}>Pokaż zamówienia utworzone po</span>
                <div>
                  <DatePicker
                    className={cx("d-inline-flex mr-2", styles.input)}
                    value={query.afterCreatedDate}
                    onChange={date => {
                      if (!date) {
                        updateQuery({ afterCreatedDate: "" });
                      } else {
                        updateQuery({
                          afterCreatedDate: dateFns.format(new Date(date), "yyyy-MM-dd"),
                        });
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={styles["drawer_section"]}>
              <div className="w-100">
                <span className={styles.inputLabel}>Pokaż zamówienia utworzone pomiędzy</span>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-2">
                <div className={styles.timeInputBox}>
                  <span className={styles.inputLabel}>Data początkowa</span>
                  <div className="d-flex align-items-center">
                    <DatePicker
                      className={cx("d-inline-flex mr-2", styles.input)}
                      value={query.minCreatedDate}
                      overwrites={{ popup: { className: styles.leftDatePickerPopup } }}
                      onChange={date => {
                        if (!date) {
                          updateQuery({ minCreatedDate: "" });
                        } else {
                          updateQuery({
                            minCreatedDate: dateFns.format(new Date(date), "yyyy-MM-dd"),
                          });
                        }
                      }}
                    />
                  </div>
                </div>
                <div className={styles.timeInputBox}>
                  <span className={styles.inputLabel}>Data końcowa</span>
                  <div className="d-flex align-items-center">
                    <DatePicker
                      className={cx("d-inline-flex mr-2", styles.input)}
                      value={query.maxCreatedDate}
                      overwrites={{ popup: { className: styles.datePickerPopup } }}
                      onChange={date => {
                        if (!date) {
                          updateQuery({ maxCreatedDate: "" });
                        } else {
                          updateQuery({
                            maxCreatedDate: dateFns.format(new Date(date), "yyyy-MM-dd"),
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles["drawer_section"]}>
              <div className="d-flex align-items-center justify-content-between mb-2">
                <div className={styles.timeInputBox}>
                  <span className={styles.inputLabel}>Minimalna godzina</span>
                  <div className="d-flex align-items-center">
                    <TimePicker
                      overwrites={{
                        input: { className: styles.input },
                        container: { className: styles.inputContainer },
                      }}
                      value={query.minDeliveryTime}
                      onBlur={value => updateQuery({ minDeliveryTime: value })}
                    />
                  </div>
                </div>
                <div className={styles.timeInputBox}>
                  <span className={styles.inputLabel}>Minimalna data</span>
                  <div className="d-flex align-items-center">
                    <DatePicker
                      className={cx("d-inline-flex mr-2", styles.input)}
                      value={query.minDeliveryDate}
                      overwrites={{ popup: { className: styles.datePickerPopup } }}
                      onChange={date => {
                        if (!date) {
                          updateQuery({ minDeliveryDate: "" });
                        } else {
                          updateQuery({
                            minDeliveryDate: dateFns.format(new Date(date), "yyyy-MM-dd"),
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles["drawer_section"]}>
              <h6>Zawiera ten produkt</h6>
              <AutocompleteAsyncFilter type="selectWithProducts" />
            </div>
            <div className={styles["drawer_section"]}>
              <h6>Wyklucz z tym produktem</h6>
              <AutocompleteAsyncFilter type="excludeWithProducts" />
            </div>
            <div className={styles["drawer_section"]}>
              <h6>Kategorie produktów</h6>
              <input
                type="search"
                value={inputValue}
                className={cx(styles.searchInput, "mb-1")}
                onChange={e => setInputValue(e.target.value)}
                placeholder="Szukaj"
              />
              <SearchMultipleFilter
                query={query}
                filter={{
                  name: "productCategory",
                  label: "",
                  type: "searchMultiple",
                  options: filteredCategories,
                  default: "",
                }}
              />
            </div>
            <div className={styles["drawer_section"]}>
              <div className="mb-2">
                <h6>Typy mebli</h6>
                <Checkbox
                  name="all"
                  label="Wszystkie"
                  checked={!query.productsKind}
                  onChange={() => updateQuery({ productsKind: "" })}
                  className="mb-1"
                />
                <Checkbox
                  name=""
                  label="Tapicerowane"
                  checked={isChecked("upholstery")}
                  onChange={val => updateCheckbox(val, "upholstery")}
                  className="mb-1"
                />
                <Checkbox
                  name=""
                  label="Skrzyniowe"
                  checked={isChecked("box")}
                  onChange={val => updateCheckbox(val, "box")}
                  className="mb-1"
                />
                <Checkbox
                  name=""
                  label="Inne"
                  checked={isChecked("other")}
                  onChange={val => updateCheckbox(val, "other")}
                  className="mb-1"
                />
              </div>
            </div>
            <div className={styles["drawer_section"]}>
              <div className="mb-2">
                <h6>Kontrahent</h6>
                <Select
                  buttonClassName="w-100"
                  size="small"
                  onChange={item => updateQuery({ customer: item ? String(item.id) : "" })}
                  selectedItem={query.customer}
                  mode="common"
                  width="auto"
                  placeholder="Wybierz"
                  items={[{ id: "", name: "Wszyscy" }].concat(
                    customers.map(customer => ({ ...customer, id: String(customer.id) })),
                  )}
                />
              </div>
            </div>
            <div className={styles["drawer_section"]}>
              <div className="mb-2">
                <h6>Producent</h6>
                <Select
                  buttonClassName="w-100"
                  size="small"
                  onChange={item => updateQuery({ manufacturer: item ? String(item.id) : "" })}
                  selectedItem={query.manufacturer}
                  mode="common"
                  width="auto"
                  placeholder="Wybierz"
                  items={[{ id: "", name: "Wszyscy" }].concat(
                    manufacturers.map(manufacturer => ({
                      ...manufacturer,
                      id: String(manufacturer.id),
                    })),
                  )}
                />
              </div>
            </div>
            {hasSmsService && (
              <div className={styles["drawer_section"]}>
                <div className="mb-2">
                  <h6>Potwierdzenie odbioru</h6>
                  <Select
                    buttonClassName="w-100"
                    size="small"
                    onChange={item =>
                      updateQuery({
                        isDeliveryDateConfirmAfterSMSSent: item ? String(item.id) : "",
                      })
                    }
                    selectedItem={query.isDeliveryDateConfirmAfterSMSSent}
                    mode="common"
                    width="auto"
                    placeholder="Wybierz"
                    items={[
                      { id: "", name: "Wszystkie" },
                      { id: "true", name: "Tak" },
                      { id: "false", name: "Nie" },
                    ]}
                  />
                </div>
              </div>
            )}
            <div className={styles["drawer_section"]}>
              <div className="mb-2">
                <h6>Klient</h6>
                <Select
                  buttonClassName="w-100"
                  size="small"
                  onChange={item => updateQuery({ withoutCustomer: item ? String(item.id) : "" })}
                  selectedItem={query.withoutCustomer}
                  mode="common"
                  width="auto"
                  placeholder="Wybierz"
                  items={[
                    { id: "", name: "Wszyscy" },
                    { id: "true", name: "Indywidualny" },
                    { id: "false", name: "Kontrahent" },
                  ]}
                />
              </div>
            </div>
            <div className={styles["drawer_section"]}>
              <div className="mb-2">
                <h6>Czy wyprodukowano wszystkie pozycje zamówienia?</h6>
                <Select
                  buttonClassName="w-100"
                  size="small"
                  onChange={item => updateQuery({ hasProducedItems: item ? String(item.id) : "" })}
                  selectedItem={query.hasProducedItems}
                  mode="common"
                  width="auto"
                  placeholder="Wybierz"
                  items={[
                    { id: "", name: "Wszystkie" },
                    { id: "true", name: "Tak" },
                    { id: "false", name: "Nie" },
                  ]}
                />
              </div>
            </div>
            <div className={styles["drawer_section"]}>
              <div className="mb-2">
                <h6>Rezerwacje</h6>
                <Select
                  buttonClassName="w-100"
                  size="small"
                  onChange={item => updateQuery({ hasReservations: item ? String(item.id) : "" })}
                  selectedItem={query.hasReservations}
                  mode="common"
                  width="auto"
                  placeholder="Wybierz"
                  items={[
                    { id: "", name: "Wszystkie" },
                    { id: "true", name: "Wszystkie zarezerwowane" },
                    { id: "false", name: "Co najmniej jeden produkt niezarezerwowany" },
                  ]}
                />
              </div>
            </div>
            <div className={styles["drawer_section"]}>
              <div className="mb-2">
                <h6>Rezerwacja</h6>
                <Select
                  buttonClassName="w-100"
                  size="small"
                  onChange={item => updateQuery({ hasReservation: item ? String(item.id) : "" })}
                  selectedItem={query.hasReservation}
                  mode="common"
                  width="auto"
                  placeholder="Wybierz"
                  items={[
                    { id: "", name: "Wszystkie" },
                    { id: "true", name: "Co najmniej jeden produkt zarezerwowany" },
                    { id: "false", name: "Żaden produkt nie jest zarezerwowany" },
                  ]}
                />
              </div>
            </div>
            <div className={styles["drawer_section"]}>
              <div className="mb-2">
                <h6>Pochodzenie</h6>
                <Select
                  buttonClassName="w-100"
                  size="small"
                  onChange={item =>
                    updateQuery({ withoutManufacturer: item ? String(item.id) : "" })
                  }
                  selectedItem={query.withoutManufacturer}
                  mode="common"
                  width="auto"
                  placeholder="Wybierz"
                  items={[
                    { id: "", name: "Wszyscy" },
                    { id: "true", name: "Własne" },
                    { id: "false", name: "Producent" },
                  ]}
                />
              </div>
            </div>
            <div className={styles["drawer_section"]}>
              <div className="mb-2">
                <h6>Zamówienia którymi się opiekuję</h6>
                <RadioGroup
                  className="d-flex flex-column mb-3"
                  items={[
                    { id: "", name: "wszystkie" },
                    { id: "true", name: "tak" },
                    { id: "false", name: "nie" },
                  ]}
                  name="onlyForKeyAccountManager"
                  onChange={item =>
                    updateQuery({ onlyForKeyAccountManager: item ? String(item.id) : "" })
                  }
                  value={query.onlyForKeyAccountManager ?? ""}
                />
              </div>
            </div>
            <div className={styles["drawer_section"]}>
              <div className="mb-2">
                <h6>Konto</h6>
                <div>
                  {salesAccounts.map(salesAccount => (
                    <label className={styles.accountLabelBox} key={salesAccount.id}>
                      <input
                        type="checkbox"
                        checked={query.salesAccount === String(salesAccount.id)}
                        onChange={e =>
                          updateQuery({
                            salesAccount: e.target.checked ? String(salesAccount.id) : "",
                          })
                        }
                      />
                      <span
                        className={cx(styles.accountLabel)}
                        style={{ backgroundColor: salesAccount.color }}
                      >
                        <span
                          style={{
                            color: salesAccount.textColor,
                          }}
                        >
                          {salesAccount.name}
                        </span>
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
            <div className={styles["drawer_section"]}>
              <div className="mb-2">
                <h6>Waga</h6>
                <RangeInput
                  values={[
                    Number(query.totalWeight_min) || 0,
                    Number(query.totalWeight_max) || Infinity,
                  ]}
                  onChange={updateWeightRange}
                  domain={rangeInputDomain}
                  unit="kg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AutocompleteAsyncFilter = ({
  type,
}: {
  type: "excludeWithProducts" | "selectWithProducts";
}) => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });

  function getObjectFilter(filter: string | undefined) {
    if (filter === undefined) return null;
    try {
      return JSON.parse(decodeURIComponent(filter));
    } catch (err) {
      return null;
    }
  }

  const productsQuery = getObjectFilter(query[type]);
  const selectedItem = productsQuery ? [{ id: productsQuery.id, name: productsQuery.name }] : [];

  const handleOnChange = (product: ProductLight[]) => {
    if (product[0]) {
      updateQuery({
        [type]: encodeURIComponent(JSON.stringify({ id: product[0].id, name: product[0].name })),
      });
    } else {
      updateQuery({ [type]: "" });
    }
  };

  return (
    <AutocompleteAsyncHandler fetchFrom={getProductsLight}>
      <Autocomplete
        selectedItems={selectedItem}
        placeholder="Szukaj produktu"
        onChange={handleOnChange}
        multiple={false}
      />
    </AutocompleteAsyncHandler>
  );
};
