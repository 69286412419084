import { Pagination } from "../types";
import apiFetch, { tokenRefreshMiddleware } from "apiConnectors/fetchConnector";
import { Car } from "./models";

const api = tokenRefreshMiddleware(apiFetch);
export const getCars = (search: string = "") =>
  api<Pagination<any>>({
    method: "GET",
    url: "/cars/items" + search,
  });

export const getCar = (id: number | string) =>
  api<Car>({
    method: "GET",
    url: "/cars/items/" + id,
  });

export const patchCar = (id: number | string, data: Partial<Car>) =>
  api<Car>({
    method: "PATCH",
    url: "/cars/items/" + id,
    data,
  });

export const deleteCar = (id: number | string) =>
  api<void>({
    method: "DELETE",
    url: "/cars/items/" + id,
  });

export const postCar = (data: Omit<Car, "id">) =>
  api<Car>({
    method: "POST",
    url: "/cars/items",
    data,
  });
