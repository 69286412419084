import { graphhopper } from "api/graphhopper/graphhopper";
import { Route } from "api/routes/models";
import boxImg from "assets/images/23.svg";
import penImg from "assets/images/1.svg";
import carImg from "assets/images/24.svg";
import clockImg from "assets/images/46.svg";
import commentImg from "assets/images/6g.svg";
import tagImg from "assets/images/87g.svg";
import cx from "classnames";
import { Button } from "components/common";
import { AsyncInput, Icon, Modal, Select, Spinner, StatusHandler, Switch } from "components/utils";
import { DatePicker } from "components/utils/datePicker";
import { TimePicker } from "components/utils/timePicker";
import { UserAvatar } from "components/utils/userAvatar";
import { subSeconds } from "date-fns";
import { useSelector, useToastr, useToggle } from "hooks";
import { useRoutePatchMutation, useRoutePutMutation } from "hooks/apiPrimitives";
import { useState } from "react";
import * as React from "react";
import { dateFns } from "utilities";
import { useRouteViewState } from "../../routeCreatorState";
import styles from "./RouteDetails.module.css";
import { getFullRouteCoords, getOrdersPositionsBasedOnGraphhopper, getPoints } from "../../utils";
import { AdvancedSelect } from "components/common/advancedSelect/AdvancedSelect";

export const RouteDetails = ({ route }: { route: Route }) => {
  const drivers = useSelector(state => state.partials.drivers);
  const toastr = useToastr();
  const actions = useRouteViewState("slave", state => state.actions);
  const isLoading = useRouteViewState("slave", state => state.isLoading);
  const cars = useSelector(state => state.partials.cars);

  const putMutation = useRoutePutMutation();
  const patchMutation = useRoutePatchMutation();

  const carsForSelect = cars.map(car => ({
    id: car.id,
    name: `${car.brand}, ${car.registrationNumber}`,
    filters: {
      brand: car.brand,
      registrationNumber: car.registrationNumber,
    },
  }));

  const updateIncludeLastPointInOptimization = async (includeLastPointInOptimization: boolean) => {
    actions.openLoader("Trwa przeliczanie trasy");
    const startingPointLngLat = [route.startingPoint.point.lng, route.startingPoint.point.lat];
    const newPoints = getPoints(route.ordersPositions);

    const points = getFullRouteCoords(
      { ...route, includeLastPointInOptimization },
      newPoints,
      startingPointLngLat,
    );

    const payload = await graphhopper.route({
      points,
      vehicle: route.vehicleType,
      includeLastPointInOptimization,
    });

    if (payload) {
      const returnToStartingPointDistance = String(payload.points[newPoints.length].distance);
      const returnToStartingPointTime = String(payload.points[newPoints.length].time);

      const ordersPositions = getOrdersPositionsBasedOnGraphhopper(
        route.ordersPositions,
        payload.points,
      );

      putMutation.mutate({
        data: {
          length: payload.distance,
          operation: null,
          ordersPositions,
          includeLastPointInOptimization,
          returnToStartingPointDistance,
          returnToStartingPointTime,
          shouldCalculateAverageSpeed: true,
        },
        route: route.id,
      });
    } else {
      actions.closeLoader();
      toastr.open({
        type: "failure",
        title: "Nie udało się przeliczyć trasy",
        text: "",
      });
    }
  };

  const updateDeliveryMutation = async (
    departureTime: string | null,
    departureDate: string | null,
  ) => {
    actions.openLoader("Trwa zmiana czasu wyjazdu");
    const startingPointLngLat = [route.startingPoint.point.lng, route.startingPoint.point.lat];
    const newPoints = getPoints(route.ordersPositions);

    const points = getFullRouteCoords(route, newPoints, startingPointLngLat);

    const payload = await graphhopper.route({
      points,
      vehicle: route.vehicleType,
      includeLastPointInOptimization: route.includeLastPointInOptimization,
    });

    if (payload) {
      const returnToStartingPointDistance = String(payload.points[newPoints.length].distance);
      const returnToStartingPointTime = String(payload.points[newPoints.length].time);

      const ordersPositions = getOrdersPositionsBasedOnGraphhopper(
        route.ordersPositions,
        payload.points,
      );

      putMutation.mutate({
        data: {
          length: payload.distance,
          operation: null,
          ordersPositions,
          departureDate,
          departureTime,
          firstArrivalTime: departureTime !== route.departureTime ? null : undefined,
          returnToStartingPointDistance,
          returnToStartingPointTime,
          shouldCalculateAverageSpeed: true,
        },
        route: route.id,
      });
    } else {
      actions.closeLoader();
      toastr.open({
        type: "failure",
        title: "Nie udało się zmienić czasu wyjazdu",
        text: "",
      });
    }
  };

  return (
    <div className={cx(styles.wrapper, "p-3")}>
      <div className="d-flex align-items-start">
        <img src={tagImg} alt="" className="mr-2" />
        <div className="w-100">
          <div className={styles.groupLabel}>
            <span>nazwa trasy</span>
          </div>
          <StatusHandler>
            {helpers => (
              <AsyncInput
                look="common"
                disabled={helpers.isFetching}
                onChange={value => {
                  helpers.startFetching();
                  patchMutation.mutate(
                    {
                      id: route.id,
                      toUpdate: { name: value },
                    },
                    {
                      onSettled: () => {
                        helpers.stopFetching();
                      },
                    },
                  );
                }}
                onBlur={e => {
                  helpers.startFetching();
                  patchMutation.mutate(
                    {
                      id: route.id,
                      toUpdate: { name: e.target.value },
                    },
                    {
                      onSettled: () => {
                        helpers.stopFetching();
                      },
                    },
                  );
                }}
                value={route.name}
                inProgress={helpers.isFetching}
                error={helpers.errors.message}
                placeholder="Wpisz nazwę"
                overwrites={{
                  input: { className: styles.input },
                }}
              />
            )}
          </StatusHandler>
        </div>
      </div>
      <div className="d-flex align-items-start mt-3">
        <img alt="Samochód" className="mr-2" src={carImg} />
        <div className="w-100">
          <div className={styles.groupLabel}>
            <span>samochód</span>
          </div>
          <div>
            <AdvancedSelect
              inputPlaceholder="Szukaj..."
              onChange={value => {
                const car = value && cars.find(car => car.id === value.id);
                patchMutation.mutate({ id: route.id, toUpdate: { car } });
              }}
              selectedItem={route.car?.id ?? null}
              itemToDisplaySelected={item => (
                <div className="d-flex align-items-center pt-1 pb-1">
                  <Icon img={carImg} type="round" className={cx("mr-2", styles.carImg)} />
                  <span
                    className={cx("text-nowrap text-truncate overflow-hidden", {
                      "text-color-grey": !item,
                    })}
                  >
                    {item ? item.name : "Przypisz"}
                  </span>
                </div>
              )}
              items={carsForSelect}
              width={380}
              itemsWidth={380}
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-start mt-3">
        <img src={boxImg} alt="" className="mr-2" />
        <div className="w-100">
          <div className={styles.groupLabel}>
            <span>załadunek</span>
          </div>
          <div className={cx("d-flex align-items-center ")}>
            <div className={styles.timeInputBox}>
              <div className="d-flex align-items-center">
                <TimePicker
                  overwrites={{
                    input: { className: styles.input },
                    container: { className: styles.inputContainer },
                  }}
                  value={route.loadingTime || ""}
                  onBlur={value =>
                    patchMutation.mutate({
                      id: route.id,
                      toUpdate: { loadingTime: value || null },
                    })
                  }
                />
              </div>
            </div>
            <div className={styles.dateInputBox}>
              <div className="d-flex align-items-center">
                <DatePicker
                  className={cx("d-inline-flex mr-2", styles.input)}
                  overwrites={{ popup: { className: styles.datePickerPopup } }}
                  value={route.loadingDate || ""}
                  onChange={date => {
                    if (!date) {
                      patchMutation.mutate({
                        id: route.id,
                        toUpdate: { loadingDate: null },
                      });
                    } else {
                      patchMutation.mutate({
                        id: route.id,
                        toUpdate: { loadingDate: dateFns.format(new Date(date), "yyyy-MM-dd") },
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-start mt-3">
        <img src={carImg} alt="" className="mr-2" />
        <div className="w-100">
          <div className={styles.groupLabel}>
            <span>wyjazd</span>
          </div>
          <div className="d-flex align-items-center ">
            <div className={styles.timeInputBox}>
              <div className="d-flex align-items-center">
                <TimePicker
                  disabled={isLoading}
                  overwrites={{
                    input: { className: styles.input },
                    container: { className: styles.inputContainer },
                  }}
                  value={route.departureTime || ""}
                  onBlur={value => {
                    if (route.departureDate) {
                      updateDeliveryMutation(value || null, route.departureDate);
                    } else {
                      patchMutation.mutate({
                        id: route.id,
                        toUpdate: { departureTime: value || null },
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div className={styles.dateInputBox}>
              <div className="d-flex align-items-center">
                <DatePicker
                  className={cx("d-inline-flex mr-2", styles.input)}
                  disabled={isLoading}
                  overwrites={{ popup: { className: styles.datePickerPopup } }}
                  value={route.departureDate || ""}
                  onChange={date => {
                    if (!date) {
                      if (route.departureTime) {
                        updateDeliveryMutation(route.departureTime, null);
                      } else {
                        patchMutation.mutate({
                          id: route.id,
                          toUpdate: { departureDate: null },
                        });
                      }
                    } else {
                      if (route.departureTime) {
                        updateDeliveryMutation(
                          route.departureTime,
                          dateFns.format(new Date(date), "yyyy-MM-dd"),
                        );
                      } else {
                        patchMutation.mutate({
                          id: route.id,
                          toUpdate: { departureDate: dateFns.format(new Date(date), "yyyy-MM-dd") },
                        });
                      }
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <FirstOrderDeliveryTimeBtn route={route} />
        </div>
      </div>
      <StopoverForEveryOrder route={route} />
      <div className="mt-3 d-flex align-items-start">
        <img src={clockImg} alt="" className="mr-2" />
        <div>
          <div className={styles.groupLabel}>
            <span>Kierowca</span>
          </div>
          <div className="select select--icon-round">
            <Select
              itemToDisplaySelected={item => (
                <>
                  <UserAvatar avatarSrc={item?.avatar} className="mr-1" />
                  <span className="position-relative" style={{ top: -4 }}>
                    {item ? item.name : "Przypisz"}
                  </span>
                </>
              )}
              onChange={item => patchMutation.mutate({ id: route.id, toUpdate: { driver: item! } })}
              buttonClassName={styles.button}
              selectedItem={route.driver ? route.driver.id : ""}
              items={drivers.map(driver => ({
                ...driver,
                name: `${driver.firstName} ${driver.lastName}`,
              }))}
            />
          </div>
        </div>
      </div>
      <NoteForRoute route={route} />
      <div className="mt-3 d-flex align-items-start">
        <img src={carImg} alt="" className="mr-2" />
        <div className="w-100">
          <div className={styles.groupLabel}>
            <span>Ustawienia trasy</span>
          </div>

          <Switch
            onChange={includeLastPointInOptimization =>
              updateIncludeLastPointInOptimization(includeLastPointInOptimization)
            }
            name=""
            checked={route.includeLastPointInOptimization}
            label="Uwzględniaj powrót"
            color="blue"
            overrides={{ label: { className: "fs-12" } }}
          />
        </div>
      </div>
    </div>
  );
};

const NoteForRoute = ({ route }: { route: Route }) => {
  const updatePatchMutation = useRoutePatchMutation();
  const [value, setValue] = useState(route.note);

  return (
    <div className="mt-3 d-flex align-items-start">
      <img src={commentImg} alt="" className="mr-2" />
      <div className="w-100">
        <div className={styles.groupLabel}>
          <span>Ogólne uwagi do trasy</span>
        </div>
        <div className="w-100">
          <textarea
            className={cx(styles.input, styles.textarea)}
            placeholder="Dodaj uwagi"
            value={value}
            onChange={e => setValue(e.target.value)}
            onBlur={el => {
              updatePatchMutation.mutate({ id: route.id, toUpdate: { note: el.target.value } });
            }}
          />
        </div>
      </div>
    </div>
  );
};

const StopoverForEveryOrder = ({ route }: { route: Route }) => {
  const [selectValue, setSelectValue] = useState(String(route.defaultStopoverTime / 60));
  const spinnerStyle: React.CSSProperties = { position: "absolute", right: -19, top: -19 };
  const updateMutation = useRoutePutMutation();
  const actions = useRouteViewState("slave", state => state.actions);
  const isLoading = useRouteViewState("slave", state => state.isLoading);

  const updateStopoverForEveryOrder = (stopoverTime: number) => {
    actions.openLoader("Trwa zmiana czasów postoju");

    const ordersPositions = route.ordersPositions.map(el => {
      if (el.type === "order") {
        return { ...el, meta: { ...el.meta, delivery: { ...el.meta.delivery, stopoverTime } } };
      }
      return el;
    });

    updateMutation.mutate({
      data: {
        length: route.length,
        defaultStopoverTime: stopoverTime,
        operation: null,
        ordersPositions,
        returnToStartingPointDistance: route.returnDistance,
        returnToStartingPointTime: String(route.returnTime || 0),
        shouldCalculateAverageSpeed: false,
      },
      route: route.id,
    });
  };

  return (
    <div className="d-flex align-items-start mt-3">
      <img src={clockImg} alt="" className="mr-2" />

      <div>
        <div className={styles.groupLabel}>
          <span>czas postoju między punktami</span>
        </div>
        <div className="d-flex align-items-center">
          <select
            className={styles.durationSelect}
            onChange={e => {
              setSelectValue(e.target.value);
            }}
            disabled={updateMutation.isLoading}
            value={selectValue}
          >
            <option value="0">0 min</option>
            <option value="5">5 min</option>
            <option value="10">10 min</option>
            <option value="15">15 min</option>
            <option value="20">20 min</option>
            <option value="25">25 min</option>
            <option value="30">30 min</option>
            <option value="35">35 min</option>
            <option value="40">40 min</option>
            <option value="45">45 min</option>
            <option value="50">50 min</option>
            <option value="55">55 min</option>
            <option value="60">60 min</option>
            <option value="90">1,5 godz.</option>
            <option value="120">2 godz.</option>
            <option value="180">3 godz.</option>
          </select>
          <Button
            kind="secondary-stroke"
            size="small"
            className="ml-2 "
            disabled={updateMutation.isLoading || isLoading}
            onClick={() => {
              updateStopoverForEveryOrder(Number(selectValue) * 60);
            }}
          >
            Ustaw dla wszystkich punktów
            <Spinner on={updateMutation.isLoading} color="white" style={spinnerStyle} />
          </Button>
        </div>
      </div>
    </div>
  );
};

function FirstOrderDeliveryTimeBtn({ route }: { route: Route }) {
  const updatePutMutation = useRoutePutMutation();
  const modal = useToggle();
  const actions = useRouteViewState("slave", state => state.actions);
  const isLoading = useRouteViewState("slave", state => state.isLoading);

  const [time, setTime] = useState(route.firstArrivalTime || "");
  const [date, setDate] = useState(route.departureDate);

  const handleUpdate = async (firstArrivalTime: string, departureDate: string) => {
    actions.openLoader("Trwa zmiana czasu dojazdu pierwszego zamówienia");
    const departureTimeBasedOnFirstArrivalTime = (() => {
      const [hours, minutes] = firstArrivalTime.split(":").map(Number);
      const departure = new Date(departureDate);
      departure.setHours(hours, minutes, 0);
      const newDate = subSeconds(departure, route.orders[0].delivery.time!);

      return dateFns.format(newDate, "HH:mm");
    })();

    updatePutMutation.mutate(
      {
        data: {
          length: route.length,
          operation: null,
          ordersPositions: route.ordersPositions,
          departureDate,
          departureTime: departureTimeBasedOnFirstArrivalTime,
          firstArrivalTime,
          returnToStartingPointDistance: route.returnDistance,
          returnToStartingPointTime: String(route.returnTime || 0),
          shouldCalculateAverageSpeed: false,
        },
        route: route.id,
      },
      {
        onSettled: () => {
          actions.closeLoader();
          modal.close();
        },
      },
    );
  };

  return (
    <>
      {route.firstArrivalTime ? (
        <span className="fs-12 text-color-grey">
          Pierwsze zamówienie zostanie doręczone o godzinie:{" "}
          <span className={styles.validationInfo}>{route.firstArrivalTime.slice(0, 5)}</span>
          <Button kind="secondary" size="round-s" onClick={modal.open}>
            <img src={penImg} alt="" />
          </Button>
        </span>
      ) : (
        <div>
          <Button
            kind="secondary-stroke"
            size="small"
            className="mt-2 fs-12"
            onClick={modal.open}
            disabled={isLoading}
          >
            Ustaw czas doręczenia pierwszego zamówienia
          </Button>
        </div>
      )}

      {route.orders.length < 1 && (
        <div className={styles.validationInfo}>Dodaj przynajmniej jedno zamówienie</div>
      )}

      <Modal isOpen={modal.isOpen} close={modal.close}>
        <div>
          <div>
            <h5 className={styles.modalHeader}>
              Wskaż godzinę i datę doręczenia pierwszego zamówienia
            </h5>
          </div>
          <div className="d-flex align-items-start">
            <div className="w-100">
              <div className="d-flex align-items-center ">
                <div className={styles.timeInputBox}>
                  <div className="d-flex align-items-center">
                    <TimePicker
                      overwrites={{
                        input: { className: styles.input },
                        container: { className: styles.inputContainer },
                      }}
                      value={time || ""}
                      onBlur={value => setTime(value)}
                    />
                  </div>
                </div>
                <div className={styles.dateInputBox}>
                  <div className="d-flex align-items-center">
                    <DatePicker
                      className={cx("d-inline-flex mr-2", styles.input)}
                      overwrites={{ popup: { className: styles.datePickerPopup } }}
                      value={date || ""}
                      onChange={date => {
                        if (!date) {
                          setDate(null);
                        } else {
                          setDate(dateFns.format(new Date(date), "yyyy-MM-dd"));
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center mt-3">
            <Button
              kind="primary"
              className="mr-3"
              disabled={!time || !date}
              onClick={() => {
                handleUpdate(time, date!);
              }}
            >
              Zapisz
            </Button>
            <Button kind="secondary-stroke" onClick={modal.close}>
              Anuluj
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
