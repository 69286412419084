import apiFetch, { tokenRefreshMiddleware } from "apiConnectors/fetchConnector";
import {
  SalesAccountKeyAccountManager,
  PostProfile,
  Profile,
  CustomerKeyAccountManager,
} from "./models";
import { Pagination } from "../types";
import { queryFetch } from "apiConnectors/queryFetch";
import { Assign } from "utility-types";

const api = tokenRefreshMiddleware(apiFetch);

export const getProfile = (id: string | number) =>
  api<Profile>({
    method: "GET",
    url: "/profiles/items/" + id,
  });

export const getProfiles = (search = "") =>
  api<Pagination<Profile>>({
    method: "GET",
    url: "/profiles/items" + search,
  });

export const postProfile = (data: PostProfile) =>
  api<Profile>({
    method: "POST",
    url: "/profiles/items",
    data,
  });

export const patchProfile = (id: number | string, data: Partial<Profile>) =>
  api<Profile>({
    method: "PATCH",
    url: "/profiles/items/" + id,
    data,
  });

export const postUserAvatar = (data: FormData) =>
  api<{ avatar: string }>({
    method: "POST",
    url: "/profiles/avatar",
    data,
  });

export const addSalesAccountKeyAccountManager = (data: SalesAccountKeyAccountManager) =>
  queryFetch<Assign<SalesAccountKeyAccountManager, { id: number }>>({
    method: "POST",
    url: "/profiles/sales-account-key-account-manager",
    data,
  });

export const removeSalesAccountKeyAccountManager = (id: number) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/profiles/sales-account-key-account-manager/" + id,
  });

export const addCustomerKeyAccountManager = (data: CustomerKeyAccountManager) =>
  queryFetch<Assign<CustomerKeyAccountManager, { id: number }>>({
    method: "POST",
    url: "/profiles/customer-key-account-manager",
    data,
  });

export const removeCustomerKeyAccountManager = (id: number) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/profiles/customer-key-account-manager/" + id,
  });
