import {
  getSalesAccounts,
  getSalesAccount,
  patchSalesAccount,
  getSalesAccountsB2b,
  getSalesAccountB2b,
  patchSalesAccountB2b,
} from "api/sales-accounts/calls";
import { FLAVOR } from "CONSTANTS";
import { createPrimitivePaginatedHook, createPrimitiveHook } from "hooks/createPrimitiveHook";
import produce from "immer";
import { createAsyncUpdateConnector } from "utilities";
import { SalesAccount } from "./models";

export const useSalesAccounts = createPrimitivePaginatedHook(getSalesAccounts);
export const useSalesAccountsB2b = createPrimitivePaginatedHook(getSalesAccountsB2b);
export const useSalesAccountB2b = createPrimitiveHook(getSalesAccountB2b);
export const useSalesAccount = createPrimitiveHook(getSalesAccount);

export const useUpdateSalesAccount =
  FLAVOR === "main"
    ? createAsyncUpdateConnector(patchSalesAccount)
    : createAsyncUpdateConnector(patchSalesAccountB2b);

type State = SalesAccount[];
export type Action = { type: "UPDATE"; payload: Partial<SalesAccount> };
function reducer(state: State, action: Action) {
  switch (action.type) {
    case "UPDATE":
      return produce(state, draft => {
        const itemToUpdate = draft.find(el => el.id === action.payload.id);
        if (itemToUpdate) {
          Object.assign(itemToUpdate, action.payload);
        }
      });
    default:
      return state;
  }
}
export const useFlavorSalesAccounts = (search: string) => {
  const salesAccountData = useSalesAccounts(search, {
    reducer,
    skip: FLAVOR === "b2b",
  });
  const salesAccountB2bData = useSalesAccountsB2b(search, { reducer, skip: FLAVOR === "main" });

  return FLAVOR === "main" ? salesAccountData : salesAccountB2bData;
};

type DetailsActions = {
  type: "UPDATE";
  payload: Partial<SalesAccount>;
};
function detailsReducer(state: SalesAccount, action: DetailsActions) {
  switch (action.type) {
    case "UPDATE":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const useFlavorSalesAccount = (id: string, isDeleted: boolean) => {
  const salesAccount = useSalesAccount<DetailsActions>(id, {
    reducer: detailsReducer,
    skip: FLAVOR === "b2b" || !id || isDeleted,
  });

  const salesAccountB2b = useSalesAccountB2b<DetailsActions>(id, {
    reducer: detailsReducer,
    skip: FLAVOR === "main" || !id || isDeleted,
  });
  return FLAVOR === "main" ? salesAccount : salesAccountB2b;
};
