import { Button } from "components/common";
import cx from "classnames";
import WhiteChairIcon from "assets/images/chair.svg";
import DayColumnIcon from "assets/images/day_column.svg";
import FramePersonIcon from "assets/images/frame_person.svg";
import localStyles from "./LeftToolbar.module.css";
import styles from "../../ProductionPlanDetails.module.css";

export const LeftToolbar = () => {
  return (
    <div className={localStyles.leftToolbar}>
      <Button
        kind="secondary"
        size="small"
        onClick={() => {}}
        className={cx(styles.button, localStyles.buttonSquare)}
      >
        <img alt="" src={FramePersonIcon} />
      </Button>
      <Button
        kind="secondary"
        size="small"
        onClick={() => {}}
        className={cx(styles.button, localStyles.buttonSquare)}
      >
        <img alt="" src={DayColumnIcon} />
      </Button>
      <Button
        kind="secondary"
        size="small"
        onClick={() => {}}
        className={cx(styles.button, localStyles.buttonSquare)}
      >
        <img alt="" src={WhiteChairIcon} />
      </Button>
    </div>
  );
};
