import { useHelpdeskIssueMutation } from "api/helpdesk/hooks";
import {
  HelpdeskAppTypeEnum,
  HelpdeskIssueModuleEnum,
  HelpdeskIssueSubjectEnum,
} from "api/helpdesk/models";
import arrowIcon from "assets/images/193.svg";
import cx from "classnames";
import { Button } from "components/common";
import { ErrorMessage, FormSelect, FormTextarea, RadioLabels } from "components/utils";
import { Formik, FormikHelpers } from "formik";
import { useSelector } from "hooks";
import { useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { yup } from "utilities";
import { Assign } from "utility-types";
import { InferType as YupInferType } from "yup";
import styles from "./NewIssue.module.css";

const validationSchema = yup.object().shape({
  app: yup.string().required("To pole jest wymagane"),
  subject: yup.string().required("To pole jest wymagane"),
  refersTo: yup.string().required("To pole jest wymagane"),
  description: yup.string().required("To pole jest wymagane"),
});

type Values = Assign<
  YupInferType<typeof validationSchema>,
  { refersTo: HelpdeskIssueModuleEnum | ""; app: HelpdeskAppTypeEnum }
>;

export const HelpdeskNewIssue = () => {
  const params = useParams<{ sourcePath: string }>();
  const sourcePath = decodeURIComponent(params.sourcePath);
  const me = useSelector(state => state.auth.user)!;
  const [isSubmitted, setSubmitted] = useState(false);

  const mutation = useHelpdeskIssueMutation();

  const handleSubmit = async (values: Values, actions: FormikHelpers<Values>) => {
    await mutation.mutateAsync(
      [
        {
          ...values,
          reportedBy: me!.id,
          link: window.origin + sourcePath,
          refersTo: values.refersTo,
        },
      ],
      {
        onSuccess: () => {
          setSubmitted(true);
        },
        onError: (error: Record<string, string>) => {
          actions.setErrors(error);
        },
      },
    );
  };

  const subjects: Record<HelpdeskIssueSubjectEnum, string> = {
    "I need a new feature": "Potrzebuję nowej funkcjonalności",
    "I need help": "Potrzebuję pomocy",
    "I have remarks about application": "Mam uwagi do aplikacji",
    "Bug in application": "Błąd w aplikacji",
  };

  const features: Record<HelpdeskIssueModuleEnum, string> = {
    ACCOUNTANCY: "Księgowość",
    PEOPLE: "Ludzie",
    PRODUCTION: "Produkcja",
    PRODUCTS: "Produkty",
    WAREHOUSE: "Magazyn",
    REPORT: "Raporty",
    TRANSPORT: "Transport",
    ORDERS: "Zamówienia",
    COMPLAINTS: "Reklamacje",
  };

  const initialValues: Values = (() => {
    const feature = sourcePath
      .split("/")
      .filter(Boolean)[0]
      .toUpperCase() as Values["refersTo"];
    const defaultFeature = Object.keys(features).find(id => id === feature) ?? "";

    return {
      app: HelpdeskAppTypeEnum.milo,
      subject: "",
      refersTo: defaultFeature as Values["refersTo"],
      description: "",
    };
  })();

  return (
    <div>
      <div className={cx(styles.jumbotron, "mb-5 p-3")}>
        <Link className={cx(" text-color-white fs-14 ", styles.backBtn)} to={sourcePath}>
          <img src={arrowIcon} alt="Wróć do milo" style={{ width: 12 }} className="mr-1" />
          Wróć do milo
        </Link>
        <div className="text-center">
          <span className={"text-color-violets-are-blue fs-36 pt-5 pb-3 d-block"}>
            Jak możemy pomóc?
          </span>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <span className="d-block text-color-silver-chalice pb-5">
                  Masz trudności z użytkowaniem aplikacji? Chcesz o coś zapytać? Daj nam znać co
                  możemy dla Ciebie zrobić.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            {isSubmitted ? (
              <>
                <div className="fs-36">Twoje zgłoszenie zostało wysłane.</div>
              </>
            ) : (
              <div className="card">
                <div className="card-body">
                  <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                  >
                    {({ handleSubmit, isValid, isSubmitting, setFieldValue, values }) => (
                      <form
                        onSubmit={handleSubmit}
                        className={cx({ "was-validated": !isValid }, styles.sections)}
                      >
                        <div className="mb-3">
                          <RadioLabels
                            label="Konto sprzedażowe"
                            name=""
                            allowUncheck={false}
                            items={[
                              { id: "milo", name: "Milo web" },
                              { id: "warehouse", name: "Magazyn mobile" },
                            ]}
                            onChange={({ value }) => setFieldValue("app", value)}
                            value={values.app || ""}
                          />
                          <ErrorMessage name="app" />
                        </div>
                        <div className="mb-3">
                          <FormSelect
                            name="subject"
                            overwrites={{
                              wrapper: { className: "mb-3 " },
                            }}
                            label="Temat zgłoszenia"
                            itemToSelection={item => (item ? item.id : null)}
                            items={[{ name: "Wybierz temat", id: "" }].concat(
                              Object.entries(subjects).map(([id, name]) => ({ id, name })),
                            )}
                            width="full"
                          />
                        </div>
                        <div className="mb-3">
                          <FormSelect
                            name="refersTo"
                            label="Jakiej funkcjonalności dotyczy?"
                            width="full"
                            itemToSelection={item => (item ? item.id : null)}
                            items={[{ name: "Wybierz temat", id: "" }]
                              .concat(Object.entries(features).map(([id, name]) => ({ id, name })))
                              .concat([{ id: "other", name: "Inne" }])}
                          />
                        </div>
                        <div className="mb-3">
                          <FormTextarea
                            name="description"
                            label="Treść zgłoszenia"
                            className="w-100"
                            overrides={{ textarea: { className: styles.textarea } }}
                          />
                        </div>
                        <div className="d-flex justify-content-end">
                          <Button kind="primary" type="submit" disabled={isSubmitting}>
                            Wyślij
                          </Button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
