import { useShelfItemDetails } from "api/new-production-plans/hooks";
import { ProductionPlan } from "api/new-production-plans/models";
import { queryString } from "utilities";
import cx from "classnames";
import localStyles from "./ShelfItemDetails.module.css";
import { ManufacturingItem } from "pages/productionPlans/productionPlanDetails/subcomponents/employeeRow/subcomponents/employeeWorkingDay/subcomponents/manufacturingItem/ManufacturingItem";
import { CommonError, Pagination, Spinner } from "components/utils";

interface Props {
  filterValue: number | null;
  header: string;
  origin: string;
  productionPlan: ProductionPlan;
  search: string;
}

export const ShelfItemDetails = ({
  filterValue,
  header,
  origin,
  productionPlan,
  search,
}: Props) => {
  const parsedSearch = queryString.parse(search);
  const detailedSearch = queryString.stringify({
    groupByFilter: header !== "Pozostałe" ? origin : parsedSearch.groupBy,
    groupByValue: filterValue ? filterValue : "",
  });
  const mergedFilters = queryString.merge([search, detailedSearch]);
  const hackSearch = `${productionPlan.id}$${mergedFilters}`;
  const { data: shelfItemDetails, error, isLoading, pagination } = useShelfItemDetails(hackSearch);

  if (error) {
    <CommonError status={error._httpStatus_} />;
  }

  return (
    <div
      className={cx(localStyles.shelfManufacturingItems, {
        "mt-1": shelfItemDetails.length > 0,
      })}
    >
      {isLoading && (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner color="blue" size="big" on={isLoading} text="trwa wczytywanie" />
        </div>
      )}
      {!isLoading && shelfItemDetails.length === 0 && (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <span style={{ color: "#808080" }}>Wszystkie produkty zostały przypisane</span>
        </div>
      )}
      {shelfItemDetails.length > 0 && (
        <>
          {shelfItemDetails.map(shelfManufacturingItem => (
            <ManufacturingItem
              key={shelfManufacturingItem.id}
              manufacturingItem={shelfManufacturingItem}
            />
          ))}
          <Pagination pagination={pagination} />
        </>
      )}
    </div>
  );
};
