import { createContext, useContext, useMemo, useState, useEffect } from "react";

const context = createContext<{
  id: Symbol | null;
  setId: (id: Symbol | null) => void;
  open: (id: Symbol, resolve: any, reject: any) => void;
  close: () => void;
  resolve: any;
  reject: any;
}>({ id: null, setId: () => {}, open: () => {}, close: () => {}, resolve: null, reject: null });

/**
 * Hook to control async modal; use only outside of a modal!
 */
export function useAsyncModalController(id: Symbol) {
  const modal = useContext(context);

  useEffect(() => {
    return modal.close;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    close: modal.close,
    open: () =>
      new Promise<[string, string]>((resolve, reject) => {
        modal.open(id, resolve, reject);
      }),
  };
}

/**
 * Hook to handle async modal; use only inside a modal!
 */
export function useAsyncModal() {
  const modal = useContext(context);
  return {
    opened: modal.id,
    close: modal.close,
    resolve: modal.resolve,
    reject: modal.reject,
  };
}

export const AsyncModalController = ({ children }: any) => {
  const [modalId, setModalId] = useState<Symbol | null>(null);
  const [promise, setPromise] = useState<{
    resolve: ((arg: any) => void) | null;
    reject: (() => void) | null;
  }>({ resolve: null, reject: null });

  const value = useMemo(
    () => ({
      id: modalId,
      setId: setModalId,
      open: (id: Symbol, resolve: any, reject: any) => {
        setModalId(id);
        setPromise({ resolve, reject });
      },
      close: () => {
        setModalId(null);
        if (promise.reject) {
          promise.reject();
        }
      },
      resolve: (res: any) => {
        if (promise.resolve) {
          promise.resolve(res);
        }
        setModalId(null);
      },
      reject: promise.reject,
    }),
    [modalId, promise],
  );
  return <context.Provider value={value}>{children}</context.Provider>;
};
