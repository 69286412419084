import { configMerger } from "./utils";

export const defaultConfigEntity = {
  deliveryTypes: [
    {
      name: "transport własny",
      id: "self-shipped",
    },
    {
      name: "transport zewnętrzny",
      id: "external-shipment",
    },
    {
      name: "odbiór osobisty",
      id: "personal-collection",
    },
  ],
  confirmCollectionDate: {
    showPlannedCollectionHours: true,
    showOfficeHours: false,
  },
  packages: {
    internalId: false,
  },
  orderPanel: {
    name: true,
    nameEditable: true,
    protocolPdf: true,
    salesAccountChannel: true,
    created: true,
    assignedUser: true,
    leftDays: true,
    leftDaysEditable: true,
    complaintBtn: true,
    isCanceled: true,
    offerSection: {
      _show: true,
    },
    receiptSection: {
      /**
       * If set to true it shows:
       *  - hasReceiptFilter on order list filter drawer,
       *  - receiptSection on order list right panel
       *  - two buttons in toolbar order list:
       *      1. download multiple receipts based on selected orders
       *      2. edit order receipt based on selected orders
       */
      _show: false,
    },

    filters: {
      ordering: true,
      status: true,
      paymentStatus: true,
      deliveryMethod: true,
      createdDate: true,
      drawerFilters: {
        hasCustomer: true,
        hasItemsBeenSettled: true,
        hasTransportBeenSettled: true,
        hasItemsBeenInvoiced: true,
        hasTransportBeenInvoiced: true,
        customer: true,
        productCategory: true,
        hasValues: true,
        channel: true,
        paymentProvider: true,
        paymentType: true,
        manufacturer: true,
        hasProductionOrderForManufacturer: true,
        hasProductionOrderForSelf: true,
        onlyForKeyAccountManager: true,
        isConfirmed: true,
        isInvoiceRequired: true,
        collectionDate: true,
        hasProducedItems: true,
        productKindContained: true,
        productKindPure: true,
        excludeWithProducts: true,
        selectWithProducts: true,
        isConfirmedInExternalService: false,
        hasReservations: true,
        hasReservation: true,
      },
    },
    manufacturerOrderSection: {
      _show: true,
    },
    productionOrderSection: {
      _show: true,
    },
  },
};

const defaultMainConfigEntity = configMerger(defaultConfigEntity);

const defaultB2bAndTransportConfigEntity = configMerger(defaultConfigEntity, {
  deliveryTypes: [
    {
      name: "transport producenta",
      id: "self-shipped",
    },
    {
      name: "transport zewnętrzny",
      id: "external-shipment",
    },
    {
      name: "odbiór osobisty",
      id: "personal-collection",
    },
  ],
  orderPanel: {
    protocolPdf: false,
    salesAccountChannel: false,
    leftDaysEditable: true,
    nameEditable: true,
    complaintBtn: false,
    filters: {
      deliveryMethod: false,
      drawerFilters: {
        manufacturer: false,
        customer: false,
        hasCustomer: false,
        productCategory: false,
        hasValues: false,
        channel: false,
      },
    },
    offerSection: {
      _show: false,
    },
    receiptSection: {
      _show: false,
    },
    manufacturerOrderSection: {
      _show: false,
    },
    productionOrderSection: {
      _show: false,
    },
  },
});

const defaultB2bConfigEntity = configMerger(defaultConfigEntity, {
  ...defaultB2bAndTransportConfigEntity,
});
const defaultTransportConfigEntity = configMerger(defaultConfigEntity, {
  ...defaultB2bAndTransportConfigEntity,
});

/**
 * Every owner can have his own config by creating new file with it's name.
 * By default every owner has default config.
 * Config is used to manage some content, eg. display something or hide.
 */
export const config = {
  main: defaultMainConfigEntity,
  manufacturing: defaultMainConfigEntity,
  b2b: defaultB2bConfigEntity,
  transport: defaultTransportConfigEntity,
};
