import { WorkingDay } from "api/new-production-plans/models";
import localStyles from "./DateBar.module.css";
import styles from "../../ProductionPlanDetails.module.css";
import cx from "classnames";
import { dateFns } from "utilities";
import calendarIcon from "assets/images/4.svg";
import deleteIcon from "assets/images/delete.svg";
import { Button } from "components/common";

interface Props {
  days: WorkingDay[];
}

const daysOfTheWeek = {
  MONDAY: "Poniedziałek",
  TUESDAY: "Wtorek",
  WEDNESDAY: "Środa",
  THURSDAY: "Czwartek",
  FRIDAY: "Piątek",
  SATURDAY: "Sobota",
  SUNDAY: "Niedziela",
};

export const DateBar = ({ days }: Props) => {
  return (
    <div className={localStyles.dateBar}>
      {days.map((day, index) => (
        <div
          key={day.id}
          className={cx(
            localStyles.dateColumn,
            "d-flex align-items-center justify-content-between",
            {
              [localStyles.dateColumnSaturday]: day.dayOfTheWeek === "SATURDAY",
              [localStyles.dateColumnSunday]: day.dayOfTheWeek === "SUNDAY",
              [localStyles.dateColumnHoliday]: day.isHoliday,
            },
          )}
        >
          <div className={cx("d-flex align-items-center", styles.gap2)}>
            <strong>Dzień {index + 1}</strong>
            <span>{daysOfTheWeek[day.dayOfTheWeek]}</span>
            <span className={localStyles.separator} />
            <strong className={localStyles.date}>
              {dateFns.format(new Date(day.date), "dd/MM/yyyy")}
            </strong>
            <img alt="" src={calendarIcon} className="ml-1 pb-1" />
          </div>
          <Button
            kind="secondary"
            size="round-s"
            onClick={() => {}}
            className={cx(
              styles.button,
              styles.buttonRound,
              styles.buttonBackgroundTransparentWhite,
              "mr-2",
            )}
          >
            <img alt="" src={deleteIcon} />
          </Button>
        </div>
      ))}
    </div>
  );
};
