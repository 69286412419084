import {
  getAvailablePermissions,
  getPermissionGroup,
  getPermissionGroups,
  getUser,
  getUsers,
  patchUser,
} from "api/users/calls";
import { createPrimitivePaginatedHook, createPrimitiveHook } from "hooks/createPrimitiveHook";
import { createAsyncUpdateConnector } from "utilities";

export const useUser = createPrimitiveHook(getUser);
export const useUsers = createPrimitivePaginatedHook(getUsers);
export const useUpdateUser = createAsyncUpdateConnector(patchUser);

export const usePermissionGroup = createPrimitiveHook(getPermissionGroup);
export const usePermissionGroups = createPrimitivePaginatedHook(getPermissionGroups);

export const useAvailablePermissions = createPrimitiveHook(getAvailablePermissions);
