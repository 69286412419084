import { graphhopper, QUERY_LIMIT } from "api/graphhopper/graphhopper";
import { OrderPoint } from "api/orders/models";
import { OrderPositionTypes } from "api/routes/models";
import cx from "classnames";
import { Button } from "components/common";
import { CommonError, MockupLoader } from "components/utils";
import { MAX_NUMBER_OF_ORDERS_TO_MULTIPLE_ADD } from "CONSTANTS";
import { useToastr } from "hooks";
import {
  useAssignManyOrdersMutation,
  usePointsForRoute,
  useRoute,
  useSettledRoute,
} from "hooks/apiPrimitives";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { getAnyErrorKey } from "utilities";
import styles from "./Creator.module.css";
import { FiltersDrawer } from "./filtersDrawer";
import { ProductFilterBtn } from "./filtersDrawer/ProductFilterBtn";
import { useRouteSearch } from "./hooks/useRouteSearch";
import { LeftPanel } from "./leftPanel";
import { passpointClickOutsideIgnoreClass } from "./leftPanel/pointsList/passpoint/Passpoint";
import { Loader } from "./Loader";
import { LocationFiltersBar } from "./locationFiltersBar";
import { Map } from "./map";
import { Counters } from "./map/counters";
import { useDragPolygonController } from "./map/DragPolygon";
import { RightBarBtns } from "./map/rightBarBtns/RightBarBtns";
import { OrderCommentsDrawer } from "./orderCommentsDrawer";
import { RightTopButtons } from "./rightTopButtons";
import { useRouteViewState } from "./routeCreatorState";
import { RouteList } from "./routeList";
import { RoutePointsCategories } from "./routePointsCategories";
import { getFullRouteCoords } from "./utils";

export type MapMode = "map" | "polygon";

function useMode() {
  const [state, setState] = useState<MapMode>("map");
  const toggle = useCallback(() => setState(s => (s === "map" ? "polygon" : "map")), [setState]);
  const initialMount = useRef(true);

  const onChangeCallback = useRef<() => void>(() => {});

  useEffect(() => {
    if (initialMount.current === false) {
      onChangeCallback.current();
    }
  }, [state]);

  useEffect(() => {
    initialMount.current = false;
  }, []);

  return [state, toggle] as const;
}

export const Creator = ({
  routeComponentsProps: { match },
  archive,
}: {
  routeComponentsProps: RouteComponentProps<{ routeId: string }>;
  archive?: boolean;
}) => {
  const actions = useRouteViewState("master", state => state.actions);
  const toastr = useToastr();
  const search = useRouteSearch();
  const assignManyOrdersMutation = useAssignManyOrdersMutation();
  const history = useHistory();
  const polygonController = useDragPolygonController();
  const [mode, toggleMode] = useMode();

  const useHook = (() => {
    if (archive) {
      return useSettledRoute;
    }
    return useRoute;
  })();

  const { data: route, isLoading: routeLoading, error: routeError } = useHook(
    match.params.routeId,
    {
      refetchOnWindowFocus: true,
    },
  );

  const {
    data: routePoints,
    isLoading: routePointsLoading,
    error: routePointsError,
  } = usePointsForRoute(search, {
    refetchOnWindowFocus: true,
    keepPreviousData: true,
  });

  if (routeError || routePointsError) {
    return (
      <div className="mt-5">
        <CommonError
          text={
            <div className="d-flex justify-content-center align-items-center flex-column ">
              {routeError && <div>{getAnyErrorKey(routeError)}</div>}
              {routePointsError && <div>{getAnyErrorKey(routePointsError)}</div>}
              <Button kind="primary" className="mt-2" onClick={() => history.replace("/routes")}>
                Wróć do listy tras
              </Button>
            </div>
          }
        />
      </div>
    );
  }

  if (routeLoading || routePointsLoading) {
    return <MockupLoader type="map" />;
  }

  if (!route) {
    return null;
  }

  if (!routePoints) {
    return null;
  }

  const submitPolygon = async () => {
    actions.openLoader("Trwa przypinanie punktów");
    const getOrdersInPolyline = (path: { lat: number; lng: number }[]) => {
      const polygon = new window.google.maps.Polygon({ paths: path });

      return routePoints.results.reduce((acc: OrderPoint[], location) => {
        const isInside = window.google.maps.geometry.poly.containsLocation(
          new window.google.maps.LatLng(location.point.lat, location.point.lng),
          polygon,
        );
        if (isInside) {
          acc.push(location);
        }
        return acc;
      }, []);
    };

    const ordersToSubmit = getOrdersInPolyline(polygonController.path);

    if (ordersToSubmit.length > MAX_NUMBER_OF_ORDERS_TO_MULTIPLE_ADD) {
      actions.closeLoader();
      toastr.open({
        type: "neutral",
        title: "Nie udało się przypiąć zamówień",
        text: "Oznacz mniej niż 50 punktów",
      });
      return;
    }

    if (route.orders.length + ordersToSubmit.length >= QUERY_LIMIT) {
      actions.closeLoader();
      toastr.open({
        type: "neutral",
        text: "Nie udało się przypiąć zamówień",
        title: "Oznaczyłeś zbyt wiele punktów",
      });
      return;
    }

    const startingPointLngLat = [route.startingPoint.point.lng, route.startingPoint.point.lat];

    const points = (() => {
      if (route.orders.length > 0) {
        const lastOrder = route.orders[route.orders.length - 1];
        const lastOrderLngLat = [lastOrder.delivery.point.lng, lastOrder.delivery.point.lat];
        return getFullRouteCoords(
          route,
          ordersToSubmit.map(el => [el.point.lng, el.point.lat]),
          lastOrderLngLat,
        );
      } else {
        return getFullRouteCoords(
          route,
          ordersToSubmit.map(el => [el.point.lng, el.point.lat]),
          startingPointLngLat,
        );
      }
    })();

    const payload = await graphhopper.route({
      points,
      vehicle: route.vehicleType,
      includeLastPointInOptimization: route.includeLastPointInOptimization,
    });

    if (payload) {
      try {
        const payloadData = payload.points.slice(0, -1);
        const newOrdersPositions = [
          ...route.ordersPositions,
          ...ordersToSubmit.map((el, index) => {
            const warehouseDeliveryDetails: OrderPositionTypes["order"]["warehouseDeliveryDetails"] = {
              date: el.warehouseDeliveryDetails.date,
              daysLeftToDelivery: el.warehouseDeliveryDetails.daysLeftToDelivery,
              isInWarehouse: el.warehouseDeliveryDetails.isInWarehouse,
            };
            const meta: OrderPositionTypes["order"]["meta"] = {
              point: el.point,
              delivery: {
                distance: payloadData[index].distance,
                plannedDeliveryTime: null,
                stopoverTime: 0,
                time: payloadData[index].time,
                address: null,
              },
            };

            return {
              id: String(el.id),
              type: "order" as const,
              duration: null,
              meta,
              warehouseDeliveryDetails,
            };
          }),
        ];

        const returnToStartingPointDistance = String(
          payload.points[payload.points.length - 1].distance || 0,
        );

        const returnToStartingPointTime = String(
          payload.points[payload.points.length - 1].time || 0,
        );

        assignManyOrdersMutation.mutate(
          {
            data: {
              length: route.length - Number(route.returnDistance) + payload.distance,
              operation: { method: "assign", orders: ordersToSubmit.map(el => el.id) },
              returnToStartingPointDistance,
              returnToStartingPointTime,
              ordersPositions: newOrdersPositions,
              shouldCalculateAverageSpeed: true,
            },
            route: route.id,
          },
          {
            onSettled: () => {
              polygonController.reset();
            },
          },
        );
      } catch (error) {
        toastr.open({
          type: "failure",
          title: "Nie udało się przypiąć zamówień",
          text: getAnyErrorKey(error),
        });
      }
    } else {
      actions.closeLoader();
      toastr.open({
        type: "failure",
        title: "Nie udało się przypiąć zamówień",
        text: "",
      });
    }
    toggleMode();
  };

  return (
    <div className="d-flex">
      <LeftPanel route={route} />
      <div className={cx("position-relative overflow-hidden", styles.wrapper)}>
        <RightTopButtons />
        <Counters route={route} />
        <Loader />
        <div className={passpointClickOutsideIgnoreClass}>
          <Map
            polygonController={polygonController}
            mapMode={mode}
            route={route}
            routePoints={routePoints.results}
          />
        </div>
        <RoutePointsCategories routePoints={routePoints} />
        <RouteList />
        <div className={styles.rightBar}>
          <RightBarBtns
            mode={mode}
            toggleMode={toggleMode}
            submitPolygon={submitPolygon}
            resetPolygon={polygonController.reset}
            submitDisabled={polygonController.path.length < 3}
          />
          <div className="mb-2" />
          <ProductFilterBtn />
          <div className="mb-2" />
          <LocationFiltersBar />
        </div>
        <FiltersDrawer />
        <OrderCommentsDrawer route={route} />
      </div>
    </div>
  );
};
