import { useMemo, useRef } from "react";
import { QueryKey, QueryObserverOptions, useQuery, UseQueryResult } from "react-query";
import { InferPaginationItem } from "typeUtilities";
import { getPagination } from "utilities";
import { Assign } from "utility-types";
import { ApiFetcher } from "./createApiQuery";

export type ErrorType = { [key: string]: any };
export type SearchParams = { [key: string]: any };
type Pagination = ReturnType<typeof getPagination>;

export const createPaginatedApiQuery = <TRes extends unknown>(
  fetcherFn: (args: any) => ApiFetcher<TRes>,
) => (
  search: string,
  options?: QueryObserverOptions,
): Assign<
  UseQueryResult,
  {
    data: InferPaginationItem<TRes>[];
    error: ErrorType | null;
    pagination: Pagination;
    raw: TRes;
    key: Exclude<QueryKey, string>;
  }
> => {
  const paginatedResponse = useRef<any>({
    next: null,
    prev: null,
    count: null,
    limit: null,
    page: null,
    pageSize: null,
    indexes: [],
  });
  const error = useRef<Error | null>(null);
  const raw = useRef<any>(null);
  const { key, fetcher, globalOptions } = useMemo(() => fetcherFn(search), [search]);
  const res = useQuery<any, any>(key, fetcher, {
    keepPreviousData: true,
    select: data => {
      if (data) {
        paginatedResponse.current = data;
        return data.results;
      }
    },
    onError: err => {
      error.current = err.response.data;
    },
    ...globalOptions,
    ...options,
  });

  if (res.isError) {
    return {
      ...res,
      error: error.current,
      pagination: paginatedResponse.current,
      data: [],
      raw: raw.current,
      key,
    };
  } else {
    const pagination = getPagination(paginatedResponse.current);
    return {
      ...res,
      error: null,
      data: res.data ? res.data : [],
      pagination,
      raw: paginatedResponse.current,
      key,
    };
  }
};
