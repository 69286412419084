import { graphhopper } from "api/graphhopper/graphhopper";
import { Route } from "api/routes/models";
import { StatusHandlerHelpers } from "components/utils";
import { useToastr } from "hooks";
import { useRoutePutMutation } from "hooks/apiPrimitives";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useRouteViewState } from "../routeCreatorState";
import { getFullRouteCoords, getOrdersPositionsBasedOnGraphhopper, getPoints } from "../utils";
import { useInfoWindow } from "./useInfoWindow";

export const useUnassignOrder = (route: Route) => {
  const toastr = useToastr();
  const updateMutation = useRoutePutMutation();
  const { closeInfoWindow } = useInfoWindow();
  const actions = useRouteViewState("slave", state => state.actions);

  const unassignOrder = async (
    itemId: number | string,
    type: "passpoint" | "order",
    helpers?: StatusHandlerHelpers,
  ) => {
    assertIsDefined(route.startingPoint);

    helpers?.startFetching();

    const newOrdersPositions = route.ordersPositions.filter(el => el.id !== String(itemId));

    const startingPointLngLat = [route.startingPoint.point.lng, route.startingPoint.point.lat] as [
      number,
      number,
    ];

    const newPoints = getPoints(newOrdersPositions);

    const points = getFullRouteCoords(route, newPoints, startingPointLngLat);

    const payload = await graphhopper.route({
      points,
      vehicle: route.vehicleType,
      includeLastPointInOptimization: route.includeLastPointInOptimization,
    });

    if (payload) {
      closeInfoWindow();
      const returnToStartingPointMeta = {
        distance: payload.points[newPoints.length].distance,
        time: payload.points[newPoints.length].time,
      };

      const ordersPositions = getOrdersPositionsBasedOnGraphhopper(
        newOrdersPositions,
        payload.points,
      );

      updateMutation.mutate(
        {
          data: {
            length: payload.distance,
            operation: type === "order" ? { method: "unassign", orders: [Number(itemId)] } : null,
            returnToStartingPointDistance: String(returnToStartingPointMeta.distance),
            returnToStartingPointTime: String(returnToStartingPointMeta.time),
            ordersPositions,
            shouldCalculateAverageSpeed: true,
          },
          route: route.id,
        },
        {
          onSettled: () => {
            helpers?.stopFetching();
          },
        },
      );
    } else {
      actions.closeLoader();
      helpers?.stopFetching();
      toastr.open({
        type: "failure",
        title:
          "Nie udało się przypiąć " + (type === "order" ? "zamówienia" : "punktu przelotowego"),
        text: "",
      });
    }
  };
  return { unassignOrder };
};
