import { settingsKeys } from "api/milo-settings/keys";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { DeepPartial } from "utility-types";
import {
  ConfirmationDateOptions,
  DeliveryHoursTestPayload,
  MiloSettings,
  PostConfirmationDateOptions,
  Tab,
} from "./models";

export const getMiloSettings = <T extends keyof typeof Tab>(
  tab: T,
): ApiFetcher<MiloSettings[T]> => ({
  key: settingsKeys[tab],
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/milo-settings/${tab}`,
    }),
});

export const patchTransport = (data: DeepPartial<MiloSettings["transport"]>) =>
  queryFetch<MiloSettings["transport"]>({
    method: "PATCH",
    url: `/milo-settings/transport`,
    data,
  });

export const postConfirmationDateOptions = (data: PostConfirmationDateOptions) =>
  queryFetch<ConfirmationDateOptions>({
    method: "POST",
    url: `/milo-settings/confirmation-deadline-options/`,
    data,
  });

export const removeConfirmationDateOption = (id: string) =>
  queryFetch<void>({
    method: "DELETE",
    url: `/milo-settings/confirmation-deadline-options/` + id,
  });

export const patchSales = (data: DeepPartial<MiloSettings["sales"]>) =>
  queryFetch<MiloSettings["sales"]>({
    method: "PATCH",
    url: `/milo-settings/sales`,
    data,
  });

export const patchWarehouse = (data: DeepPartial<MiloSettings["warehouse"]>) =>
  queryFetch<MiloSettings["warehouse"]>({
    method: "PATCH",
    url: `/milo-settings/warehouse`,
    data,
  });

export const patchProduction = (data: DeepPartial<MiloSettings["production"]>) =>
  queryFetch<MiloSettings["production"]>({
    method: "PATCH",
    url: `/milo-settings/production`,
    data,
  });

export const patchLabels = (data: DeepPartial<MiloSettings["labels"]>) =>
  queryFetch<MiloSettings["labels"]>({
    method: "PATCH",
    url: `/milo-settings/labels`,
    data,
  });

export const patchCommunication = (data: DeepPartial<MiloSettings["communication"]>) =>
  queryFetch<MiloSettings["communication"]>({
    method: "PATCH",
    url: `/milo-settings/communication`,
    data,
  });

export const getAllMiloSettings = () =>
  queryFetch<MiloSettings>({
    method: "GET",
    url: "/milo-settings",
  });

export const getMiloSettingsRevision = () =>
  queryFetch<{ revision: number }>({
    method: "GET",
    url: "/milo-settings/revision",
  });

export const postDeliveryHoursTest = (data: DeliveryHoursTestPayload) =>
  queryFetch<{
    minimumDeliveryHour: string;
    maximumDeliveryHour: string;
  }>({
    method: "POST",
    url: "/milo-settings/test/delivery-hours",
    data,
  });

export const postTestEmailMessage = (data: { email: string }) =>
  queryFetch<"ok">({
    method: "POST",
    url: "/messages/send-test-email-message",
    data,
  });
